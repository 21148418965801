import {FC, Fragment} from 'react';

interface IProps {
    dangerouslySetInnerHTML: {
        __html: string;
    };
}

const TranslationWrapper: FC<IProps> = ({
    dangerouslySetInnerHTML,
    ...props
}) => {
    if(dangerouslySetInnerHTML){
        return <span dangerouslySetInnerHTML={dangerouslySetInnerHTML} {...props}/>;
    }
    return <Fragment {...props}/>;
};

export default TranslationWrapper;

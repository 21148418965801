import {useEffect, useCallback} from 'react';
import styled from 'styled-components/macro';
import dayjs from 'dayjs';
import {Container} from 'library/styled-bs-grid';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {objFilterNotTrue2Array} from 'utils/format';

// Components
import {CardTable as Table} from 'components/atoms/Table';
import PageHeader from 'components/organisms/PageHeader';
import ListFeatureGroup from 'components/organisms/ListFeatureGroup';

// Stores
import {actions as storeActions} from 'store/store';


/**
 * 廣告/最新消息/列表
 */
const List = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const tableForm = useForm<{checkedId: {
            [key: string]: boolean
        }}>();
    const baseRoutePath = '/store';

    const {paginateData, paginateMeta, isFetching} = useSelector(state => ({
        paginateData: state.store.paginateData,
        paginateMeta: state.store.paginateMeta,
        isFetching: state.store.isFetching,
    }));



    useEffect(() => {
        handleFetchPaginate(1, 50);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     * 查詢分頁
     */
    const handleFetchPaginate = useCallback((currentPage?: number, pageLimit?: number) => {

        dispatch(storeActions.fetchPaginate({
            currentPage,
            pageLimit,
        }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     * 刪除多筆資料
     */
    const handleDeleteRows = () => {
        // 取得 Table中的 CheckId
        // const {checkedId} = tableForm.getValues({nest: true});
        const {checkedId} = tableForm.getValues();
        const ids = objFilterNotTrue2Array(checkedId);

        if(ids.length === 0){
            alert('請選擇項目');
        }else{
            dispatch(storeActions.deleteData({ids}));
        }
    };


    /**
     * 刪除一筆資料
     */
    const handleDeleteRow = (id: number) => {
        dispatch(storeActions.deleteData({ids: [id]}));
    };

    return (
        <PageRoot>

            <PageHeader
                breadcrumbData={[
                    {text: 'Store Manage'},
                ]}
                tabsData={[
                    {text: 'List', href: baseRoutePath},
                ]}
            />

            <ListFeatureGroup data={[
                {text: 'Add', onClick: () => history.push(`${baseRoutePath}/create`), color: 'primary'},
                {text: 'Delete', onClick: handleDeleteRows, color: 'danger'},
            ]}/>


            {/* 內容 */}
            <Container fluid>

                {/* 表格資料 */}
                <Table
                    isFetching={isFetching}
                    hookForm={tableForm}
                    title={[
                        {text: 'Name',        field: 'name', width: 200},
                        {text: 'Phone',        field: 'phone', width: 200},
                        {text: 'Address',        field: 'address', col: true},
                        {text: 'Admin',        field: 'adminUserName', width: 100},
                        {text: 'Created',    field: 'createdAt'    , width: 100},
                        {text: 'Updated',    field: 'updatedAt'    , width: 100},

                    ]}
                    data={paginateData.map(row => {
                        return {
                            id: row.id,
                            name: row.name,
                            adminUserName: row.adminUser?.name,
                            address: `${row.addressState}${row.addressDetail}`,
                            phone: row.phone,
                            createdAt: <div style={{fontSize: 12}}>
                                {dayjs(row.createdAt).format('YYYY-MM-DD')}<br/>
                                {dayjs(row.createdAt).format('HH:mm:ss')}
                            </div>,
                            updatedAt: <div style={{fontSize: 12}}>
                                {dayjs(row.updatedAt).format('YYYY-MM-DD')}<br/>
                                {dayjs(row.updatedAt).format('HH:mm:ss')}
                            </div>,
                        };
                    })}
                    onEditRow={(id, isOpen) => {
                        if(isOpen){
                            window.open(`${baseRoutePath}/${id}`);
                        }else{
                            history.push(`${baseRoutePath}/${id}`);
                        }
                    }}
                    onDeleteRow={id => handleDeleteRow(id)}
                    onChangePage={(page, limit) => handleFetchPaginate(page, limit)}
                    meta={paginateMeta}
                />
            </Container>


        </PageRoot>
    );
};

export default List;


const PageRoot = styled.div`
    padding: 0 20px;
`;

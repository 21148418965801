import {useEffect, useMemo} from 'react';
import {Row, Col} from 'library/styled-bs-grid';
import {Controller, SubmitHandler, UseFormMethods} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

// Components
import SearchForm from 'components/forms/SearchForm';
import Card from 'components/atoms/Card';
import Button from 'components/atoms/Button';
import Select2 from 'components/forms/Select2';
import Select from 'components/forms/Select';
import DateField from 'components/forms/DateField';

// Stores
import {IFormData} from './types';
import {currencies} from 'config/common';


interface IProps {
    hookForm: UseFormMethods<IFormData>,
    handleSubmitHandler: SubmitHandler<IFormData>;
}

const SearchCard = ({
    hookForm,
    handleSubmitHandler,
}: IProps) => {
    const dispatch = useDispatch();


    const {optionsData} = useSelector(state => ({
        optionsData: state.job.settingData,
    }));



    const handleReset = () => {
        hookForm.reset({
            memberUserId: '',
            storeId: '',
            soldCurrency: '',
            startDate: '',
            endDate: '',
            isCompleted: '',
            isLodged: '',
        });
    };


    /**
     * MEMO 商店
     */
    const memoStores = useMemo(() => {
        const data = optionsData?.stores?.map(row => {
            return {text: `#${row.id} ${row.name}`, value: String(row.id)};
        }) || [];

        return [{text: 'Stores', value: ''}, ...data];

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionsData?.stores]);




    /**
     * MEMO 會員
     */
    const memoMembers = useMemo(() => {
        const data = optionsData?.memberUsers?.map(row => {
            return {text: `#${row.id} ${row.name}`, value: String(row.id)};
        }) || [];

        return [{text: 'Customer', value: ''}, ...data];

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionsData?.memberUsers]);



    /**
     * MEMO 貨幣
     */
    const memoCurrencies = useMemo(() => {

        return [{text: 'Currency', value: ''}, ...currencies];

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);







    return (<Card>
        <SearchForm onSubmit={hookForm.handleSubmit(handleSubmitHandler)}>
            <Row className="align-items-center">

                <Col col="auto" className="mb-2">
                    <Controller
                        control={hookForm.control}
                        name="startDate"
                        render={({onChange, value}) => {
                            return (<DateField
                                onChange={onChange}
                                value={value}
                                placeholder="Start Date"
                            />);
                        }}
                    />
                </Col>
                <Col col="auto" className="mb-2">
                    <Controller
                        control={hookForm.control}
                        name="endDate"
                        render={({onChange, value}) => {
                            return (<DateField
                                onChange={onChange}
                                value={value}
                                placeholder="End Date"

                            />);
                        }}
                    />
                </Col>

                <Col col="auto" className="mb-2">
                    <Controller
                        control={hookForm.control}
                        name="storeId"
                        render={({onChange, value}) => {
                            return (<Select2
                                onChange={onChange}
                                value={value}
                                placeholder="Store"
                                options={memoStores}/>);
                        }}
                    />
                </Col>

                <Col col="auto" className="mb-2">
                    <Controller
                        control={hookForm.control}
                        name="isLodged"
                        render={({onChange, value}) => {
                            return (<Select
                                onChange={onChange}
                                value={value}
                                options={[
                                    {value: '', text: 'Lodged'},
                                    {value: 'false', text: 'No'},
                                    {value: 'true', text: 'Yes'},
                                ]}
                            />);
                        }}
                    />
                </Col>


                <Col col="auto" className="mb-2">
                    <Controller
                        control={hookForm.control}
                        name="isCompleted"
                        render={({onChange, value}) => {
                            return (<Select
                                onChange={onChange}
                                value={value}
                                options={[
                                    {value: '', text: 'Completed'},
                                    {value: 'false', text: 'No'},
                                    {value: 'true', text: 'Yes'},
                                ]}
                            />);
                        }}
                    />
                </Col>


                <Col col="auto" className="mb-2">
                    <Controller
                        control={hookForm.control}
                        name="soldCurrency"
                        render={({onChange, value}) => {
                            return (<Select2
                                onChange={onChange}
                                value={value}
                                placeholder="currency"
                                options={memoCurrencies}
                            />);
                        }}
                    />
                </Col>


                <Col col="auto" className="mb-2">
                    <Controller
                        control={hookForm.control}
                        name="memberUserId"
                        render={({onChange, value}) => {
                            return (<Select2
                                onChange={onChange}
                                value={value}
                                placeholder="Customer"
                                options={memoMembers}
                            />);
                        }}
                    />
                </Col>




                <Col col="auto" className="mb-2">
                    <Button type="submit" color="primary">Search</Button>
                </Col>

                <Col col="auto" className="mb-2">
                    <Button color="gray" onClick={handleReset}>Reset</Button>
                </Col>
            </Row>



        </SearchForm>

    </Card>);
};

export default SearchCard;


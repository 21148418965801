import api from 'library/apisauce';

import {TGetPaginateRes, TGetDetailRes, TCreateDataRes, TUpdateDataRes, TDeleteDataRes, TGetSettingsRes} from './types';
import {obj2formData} from '../../utils/format';

/**
 * 會員/資料管理
 */
export default {
    /**
     * 查詢 列表資料
     */
    getPaginate: (currentPage: number, pageLimit: number,
        memberUserId?: number,
    ): Promise<TGetPaginateRes> => {
        return api.get('/store', {
            currentPage, pageLimit,
            memberUserId,
        }, {
            headers: {isAuth: true},
        });
    },
    /**
     * 查詢 設定資料
     */
    getSettings: (storeId: number): Promise<TGetSettingsRes> => {
        return api.get('/store/settings', {storeId}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 查詢 明細資料
     */
    getDetail: (id: number): Promise<TGetDetailRes> => {
        return api.get(`/store/${id}`, {}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 新增 資料
     */
    createData: (data: {
        name: string,

        addressDetail: string,
        addressState: string,
        phone: string,

        adminUserId: number,
    }): Promise<TCreateDataRes> => {
        return api.post('/store', data, {
            headers: {isAuth: true},
        });
    },
    /**
     * 更新 資料
     */
    updateData: (id: number, data: {
        name: string,

        addressDetail: string,
        addressState: string,
        phone: string,

        adminUserId: number,
        // avatar?: File | null,
    }): Promise<TUpdateDataRes> => {

        return api.put(`/store/${id}`, data, {
            headers: {
                isAuth: true,
            },
            timeout: 10 * 60 * 1000
        });
    },

    /**
     * 刪除
     */
    deleteData: (ids: number[]): Promise<TDeleteDataRes> => {
        return api.delete('/store', {ids}, {
            headers: {isAuth: true},
        });
    },

};

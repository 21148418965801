// https://www.backpackers.com.tw/guide/index.php/%E4%B8%96%E7%95%8C%E5%90%84%E5%9C%8B%E8%B2%A8%E5%B9%A3
const currencies = [
    {value: 'AUD', text: 'AUD'},
    {value: 'CNY', text: 'CNY'},
    {value: 'HKD', text: 'HKD'},
    {value: 'TWD', text: 'TWD'},
    {value: 'MOP', text: 'MOP'},
    {value: 'JPY', text: 'JPY'},
    {value: 'KRW', text: 'KRW'},
    {value: 'MNT', text: 'MNT'},
    {value: 'VND', text: 'VND'},
    {value: 'KHR', text: 'KHR'},
    {value: 'LAK', text: 'LAK'},
    {value: 'MMK', text: 'MMK'},
    {value: 'THB', text: 'THB'},
    {value: 'MYR', text: 'MYR'},
    {value: 'SGD', text: 'SGD'},
    {value: 'BND', text: 'BND'},
    {value: 'SGD', text: 'SGD'},
    {value: 'IDR', text: 'IDR'},
    {value: 'USD', text: 'USD'},
    {value: 'PHP', text: 'PHP'},
    {value: 'INR', text: 'INR'},
    {value: 'NPR', text: 'NPR'},
    {value: 'NPR', text: 'NPR'},
    {value: 'BTN', text: 'BTN'},
    {value: 'INR', text: 'INR'},
    {value: 'BDT', text: 'BDT'},
    {value: 'LKR', text: 'LKR'},
    {value: 'PKR', text: 'PKR'},
    {value: 'MVR', text: 'MVR'},
    {value: 'GBP', text: 'GBP'},
    {value: 'USD', text: 'USD'},
    {value: 'AFN', text: 'AFN'},
    {value: 'KZT', text: 'KZT'},
    {value: 'UZS', text: 'UZS'},
    {value: 'TMM', text: 'TMM'},
    {value: 'KGS', text: 'KGS'},
    {value: 'TJS', text: 'TJS'},
    {value: 'GEL', text: 'GEL'},
    {value: 'RUB', text: 'RUB'},
    {value: 'AMD', text: 'AMD'},
    {value: 'AZN', text: 'AZN'},
    {value: 'IRR', text: 'IRR'},
    {value: 'IQD', text: 'IQD'},
    {value: 'KWD', text: 'KWD'},
    {value: 'SAR', text: 'SAR'},
    {value: 'BHD', text: 'BHD'},
    {value: 'QAR', text: 'QAR'},
    {value: 'AED', text: 'AED'},
    {value: 'OMR', text: 'OMR'},
    {value: 'YER', text: 'YER'},
    {value: 'ILS', text: 'ILS'},
    {value: 'SYP', text: 'SYP'},
    {value: 'LBP', text: 'LBP'},
    {value: 'JOD', text: 'JOD'},
    {value: 'EUR', text: 'EUR'},
    {value: 'NZD', text: 'NZD'},
    {value: 'CAD', text: 'CAD'},
];

const transferOptions = [
    {id: 1, name: 'Balance'},
    {id: 2, name: 'Cash'},
    {id: 3, name: 'E-Transfer'},
];


export {
    currencies,
    transferOptions
};

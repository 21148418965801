import React, {useCallback, useEffect, useMemo} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components/macro';
import {Col, Container, Row} from 'library/styled-bs-grid';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {isEmpty, isNotEmpty} from 'utils/equal';

// Components
import Card from 'components/atoms/Card';
import Button from 'components/atoms/Button';
import FormHorizontalGroup from 'components/forms/FormHorizontalGroup';
import PageHeader from 'components/organisms/PageHeader';
import Select2 from 'components/forms/Select2';
import DateField from 'components/forms/DateField';
import Select from 'components/forms/Select';

// Stores
import {actions as reportActions} from 'store/report';

import {IFormData} from './types';

import ReactECharts from 'echarts-for-react';
import {selector as authSelector} from 'store/auth';


/**
 * 報表
 * https://echarts.apache.org/zh/option.html#series-bar
 */
const Report = () => {
    const history = useHistory();
    const params = useParams<{ id: string }>();
    const baseRoutePath = '/report';

    const dispatch = useDispatch();

    const {handleSubmit, control, reset} = useForm<IFormData>();

    const storeId = useSelector(authSelector.storeId);
    const paginateData = useSelector(state => state.report.paginateData);

    const settingData = useSelector(state =>state.report.settingData);


    const option1 = {
        aria: {
            // 下面几行可以不写，因为 label.enabled 默认 true
            // label: {
            //     enabled: true
            // },
            enabled: true
        },

        series: [
            {
                name: 'Currency',
                type: 'pie',
                data: paginateData?.groupCurrency.map(row => {
                    return {
                        value: row.total,
                        name: row.currency
                    };
                })
            }
        ],
        backgroundColor: 'transparent'
    };


    const option2 = {
        yAxis: {
            type: 'category',
            data: paginateData?.groupTransferOption.map(row => {
                return row.name;
            })
        },
        xAxis: {
            type: 'value'
        },
        series: [{
            data: paginateData?.groupTransferOption.map(row => {
                return row.total;
            }),
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)'
            },
        }],
        backgroundColor: 'transparent'
    };

    useEffect(() => {

        reset();

        dispatch(reportActions.fetchSetting());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    /**
     * MEMO 店
     */
    const memoStores = useMemo(() => {
        const data = settingData?.stores?.map(row => {
            return {text: row.name, value: String(row.id)};
        }) || [];

        return [{text: 'All', value: ''}, ...data];

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingData?.stores]);

    /**
     * 送出表單
     * @param formData
     */
    const handleSubmitHandler: SubmitHandler<IFormData> = formData => {
        dispatch(reportActions.fetchPaginate(formData));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    return (
        <PageRoot>
            <PageHeader
                breadcrumbData={[
                    {text: 'Report Manage', href: baseRoutePath},
                    {text: 'Report'},
                ]}
                tabsData={[
                    {text: 'Search', href: `${baseRoutePath}`},
                ]}
            />


            {/* 內容 */}
            <Container fluid>
                <Card direction="column">
                    <form onSubmit={handleSubmit(handleSubmitHandler)}>


                        <FormHorizontalGroup label="Date Range">
                            <Controller
                                control={control}
                                name="startDate"
                                defaultValue=""
                                render={({onChange, value}) => {
                                    return (<DateField
                                        className="mr-2"
                                        onChange={onChange}
                                        value={value}
                                    />);
                                }}
                            />

                            <Controller
                                control={control}
                                name="endDate"
                                defaultValue=""
                                render={({onChange, value}) => {
                                    return (<DateField
                                        onChange={onChange}
                                        value={value}
                                    />);
                                }}
                            />
                        </FormHorizontalGroup>

                        <FormHorizontalGroup label="Currency">
                            <Controller
                                control={control}
                                name="type"
                                defaultValue="buy"
                                render={({onChange, value}) => {
                                    return (<Select
                                        onChange={onChange}
                                        value={value}
                                        options={[
                                            {text: 'We bought', value: 'buy'},
                                            {text: 'We sold', value: 'sold'},
                                        ]}
                                    />);
                                }}
                            />
                        </FormHorizontalGroup>

                        {isEmpty(storeId) && (
                            <FormHorizontalGroup label="Store">
                                <Controller
                                    control={control}
                                    name="storeId"
                                    defaultValue=""
                                    render={({onChange, value}) => {
                                        return (<Select2
                                            onChange={onChange}
                                            value={value}
                                            options={memoStores}
                                        />);
                                    }}
                                />
                            </FormHorizontalGroup>
                        )}


                        <FormHorizontalGroup>
                            <Button type="submit" color="primary" className="mr-2">Search</Button>
                        </FormHorizontalGroup>


                        <Row className="mt-5">
                            {paginateData?.groupCurrency && (
                                <Col col={12}>
                                    <ReactECharts
                                        theme="dark"
                                        option={option1}
                                    />
                                </Col>
                            )}
                            {paginateData?.groupCurrency && (
                                <Col col={12}>
                                    <ReactECharts
                                        theme="dark"
                                        option={option2}
                                    />
                                </Col>
                            )}
                        </Row>


                    </form>



                </Card>
            </Container>

        </PageRoot>
    );
};

export default Report;


const PageRoot = styled.div`
    padding: 0 20px;
`;

import api from 'library/apisauce';

import {
    TGetPaginateRes,
    TGetDetailRes,
    TCreateDataRes,
    TUpdateDataRes,
    TDeleteDataRes,
    TUpdatePasswordRes,
    TGetSettingsRes,
} from './types';
import {obj2formData} from '../../utils/format';

/**
 * 管理者/資料管理
 */
export default {
    /**
     * 查詢 列表資料
     */
    getPaginate: (currentPage: number, pageLimit: number, account = ''): Promise<TGetPaginateRes> => {
        return api.get('/root/user', {currentPage, pageLimit, account}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 查詢 設定資料
     */
    getSettings: (): Promise<TGetSettingsRes> => {
        return api.get('/root/user/setting', {}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 查詢 明細資料
     */
    getDetail: (id: number): Promise<TGetDetailRes> => {
        return api.get(`/root/user/${id}`, {}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 新增 資料
     */
    createData: (data: {
        name: string,
        account: string,
        roleId: number,
        isEnable: boolean,

        avatar: File | undefined,
    }): Promise<TCreateDataRes> => {
        const formData = obj2formData(data);

        return api.post('/root/user', formData, {
            headers: {isAuth: true},
        });
    },
    /**
     * 更新 資料
     */
    updateData: (id: number, data: {
        name: string,
        account: string,
        roleId: number,
        isEnable: boolean,

        avatar: File | undefined,
    }): Promise<TUpdateDataRes> => {
        const formData = obj2formData(data);

        return api.put(`/root/user/${id}`, formData, {
            headers: {isAuth: true},
        });
    },


    /**
     * 更新 密碼
     */
    updatePassword: (id: number, data: {
        password: string,
        confirmPassword: string,
    }): Promise<TUpdatePasswordRes> => {
        return api.put(`/root/user/${id}/password`, data, {
            headers: {isAuth: true},
        });
    },

    /**
     * 刪除
     */
    deleteData: (ids: number[]): Promise<TDeleteDataRes> => {
        return api.delete('/root/user', {ids}, {
            headers: {isAuth: true},
        });
    },


};

import {useCallback, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components/macro';
import {useForm, Controller, SubmitHandler} from 'react-hook-form';

// Components
import Icon from 'components/atoms/Icon';
import TextField from 'components/forms/TextField';
import Button from 'components/atoms/Button';
import Card from 'components/atoms/Card';
import PasswordField from 'components/forms/PasswordField';
import FormVerticalGroup from 'components/forms/FormVerticalGroup/FormVerticalGroup';

// Configs & Data
import {asset} from 'config/app';

// Stores
import {actions as authActions, selector as authSelector} from 'store/auth';

import {IFormData} from './types';




/**
 * 登入畫面
 */
const Login = () => {
    const history = useHistory();

    const {handleSubmit, control, reset} = useForm<IFormData>();
    const dispatch = useDispatch();

    const {isAuth} = useSelector(state => ({
        isAuth: authSelector.isAuth(state),
    }));

    useEffect(() => {

        // 初次進入 才判斷登入狀態跳轉, 避免處理登入過程直接跳開
        // 正常情況由Saga進行登入完成後跳轉
        if(isAuth){
            history.replace('/dashboard');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        reset({account: '', password: ''});

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * 登入
     * @param data
     */
    const handleSubmitHandler: SubmitHandler<IFormData> = useCallback(formData => {
        dispatch(authActions.login(formData));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <SignLayoutRoot>

            <SiteGroup>
                {/*test */}
                {/*<img width="698" src="http://kr8851.com/uploads/product/item/2020/11/13/2990206/0.jpg?update=201204162915757832"/>*/}
                {/*<img width="698" src="https://kr8851.com/uploads/product/item/2020/11/20/2998393/1.jpg"/>*/}
                <Logo src={asset('/images/logo.png')}/>
                <SiteName>City Exchange</SiteName>
            </SiteGroup>


            <SignInDesc>Sign in to continue to City Exchange.</SignInDesc>

            <Card direction="column" style={{
                padding: '0 10px 10px 10px',
                marginBottom: '50px',
            }}>


                <form onSubmit={handleSubmit(handleSubmitHandler)}>
                    <FormVerticalGroup label="Account">
                        <Controller
                            control={control}
                            name="account"
                            defaultValue=""
                            render={({onChange, value}) => {
                                return (<TextField
                                    onChange={onChange}
                                    value={value}
                                    beforeIconCode="user-circle"
                                    placeholder="Account"
                                />);
                            }}

                        />
                    </FormVerticalGroup>

                    <FormVerticalGroup label="Password">
                        <Controller
                            control={control}
                            name="password"
                            defaultValue=""
                            render={({onChange, value}) => {
                                return (<PasswordField
                                    onChange={onChange}
                                    value={value}
                                    placeholder="Password"
                                    beforeIconCode="locked"
                                />);
                            }}

                        />
                    </FormVerticalGroup>


                    <Button color="primary" type="submit" isBlock>
                        Sign In
                    </Button>
                </form>
            </Card>

            <CopyRight>
                © 2021 City Exchange. Created <Icon code="heart" size={18} color="#E92C5D"/> by CACDESIGN
            </CopyRight>

        </SignLayoutRoot>
    );

};

export default Login;


const CopyRight = styled.p`

`;


const SiteName = styled.div`
    color: #cccccc;
    font-size: 30px;
    font-weight: 900;
`;


const Logo = styled.img`
    display: block;
    width: 38px;
    margin-right: 8px;
`;

const SiteGroup =styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;



const SignInDesc = styled.p`
     color: #72849a;
     text-align: center;
     margin-bottom: 30px;
     font-size: 16px;
`;





const SignLayoutRoot = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 90%;
    max-width: 400px;

    background: #272c31;
    color: #7d8490;
    margin: 0 auto;
`;

import {useEffect} from 'react';
import styled, {css} from 'styled-components/macro';
import dayjs from 'dayjs';
import {Container} from 'library/styled-bs-grid';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {SubmitHandler, useForm} from 'react-hook-form';
import {objFilterNotTrue2Array, toBoolean, toNumber} from 'utils/format';

// Components
import {CardTable as Table} from 'components/atoms/Table';
import PageHeader from 'components/organisms/PageHeader';
import ListFeatureGroup from 'components/organisms/ListFeatureGroup';
import SearchCard from './SearchCard';

// Stores
import {actions as memberUserActions} from 'store/memberUser';

import {IFormData} from './types';



/**
 * 會員管理/列表
 */
const List = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const baseRoutePath = '/member/user';

    const tableForm = useForm<{checkedId: {
            [key: string]: boolean
        }}>();
    const searchForm = useForm<IFormData>();


    const {paginateData, paginateMeta, isFetching} = useSelector(state => ({
        paginateData: state.memberUser.paginateData,
        paginateMeta: state.memberUser.paginateMeta,
        isFetching: state.memberUser.isFetching,
    }));


    useEffect(() => {
        handleFetchPaginate(1, 50);
        dispatch(memberUserActions.fetchSetting());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     * 刪除多筆資料
     */
    const handleDeleteRows = () => {
        // 取得 Table中的 CheckId
        // const {checkedId} = tableForm.getValues({nest: true});
        const {checkedId} = tableForm.getValues();
        const ids = objFilterNotTrue2Array(checkedId);

        if(ids.length === 0){
            alert('請選擇項目');
        }
        dispatch(memberUserActions.deleteData({ids}));
    };


    /**
     * 刪除一筆資料
     */
    const handleDeleteRow = (id: number) => {
        dispatch(memberUserActions.deleteData({ids: [id]}));
    };


    /**
     * 送出8查詢表單
     */
    const handleSubmitHandler: SubmitHandler<IFormData> = formData => {
        handleFetchPaginate(1);
    };


    /**
     * 查詢分頁
     */
    const handleFetchPaginate = (currentPage?: number, pageLimit?: number) => {

        // 取得查詢項目
        const searchData = searchForm.getValues();

        // console.log('searchData', searchData);

        dispatch(memberUserActions.fetchPaginate({
            currentPage,
            pageLimit,
            // sortField: sortField,
            // sortBy: sortBy,

            ...searchData,
            memberUserId: toNumber(searchData.memberUserId),

        }));
    };



    return (
        <PageRoot>

            <PageHeader
                breadcrumbData={[
                    {text: 'Customer Manage'},
                ]}
                tabsData={[
                    {text: 'List', href: '/member/user'},
                ]}
            />

            <ListFeatureGroup data={[
                {text: 'Add', onClick: () => history.push(`${baseRoutePath}/create`), color: 'primary'},
                {text: 'Delete', onClick: handleDeleteRows, color: 'danger'},
            ]}/>

            {/* 內容 */}
            <Container fluid>

                {/* 搜尋列 */}
                <SearchCard
                    hookForm={searchForm}
                    handleSubmitHandler={handleSubmitHandler}
                />

                {/* 表格資料 */}
                <Table
                    isFetching={isFetching}
                    hookForm={tableForm}
                    title={[
                        {text: 'Id',     field: 'id'       , width: 100},
                        {text: 'Name',     field: 'name'       , width: 200},
                        {text: 'Phone',        field: 'phone', width: 200},
                        // {text: 'Address',        field: 'address', col: true},
                        {text: 'Store',        field: 'store', width: 200},
                        {text: 'Created',   field: 'createdAt',width: 110},
                        {text: 'Updated',    field: 'updatedAt'    , width: 100},
                    ]}
                    data={paginateData.map(row => {
                        return {
                            id: row.id,
                            name: row.fullName,
                            address: row.address,
                            phone: row.phone,
                            store: row.store?.name || '',
                            createdAt: <div style={{fontSize: 12}}>
                                {dayjs(row.createdAt).format('YYYY-MM-DD')}<br/>
                                {dayjs(row.createdAt).format('HH:mm:ss')}
                            </div>,
                            updatedAt: <div style={{fontSize: 12}}>
                                {dayjs(row.updatedAt).format('YYYY-MM-DD')}<br/>
                                {dayjs(row.updatedAt).format('HH:mm:ss')}
                            </div>,
                        };
                    })}
                    onEditRow={id => history.push(`/member/user/${id}`)}
                    onDeleteRow={id => handleDeleteRow(id)}
                    onChangePage={(page, limit) => handleFetchPaginate(page, limit)}
                    meta={paginateMeta}
                />
            </Container>

        </PageRoot>
    );
};

export default List;


const UnpaidAmount = styled.div<{
    isNotZero: boolean;
}>`
    ${props => props.isNotZero && css`
        color: #a71d5d;
    `}
`;

const PageRoot = styled.div`
    padding: 0 20px;
`;

import {useEffect, useRef, useCallback} from 'react';
import styled from 'styled-components/macro';
import {disableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock';
import throttle from 'lodash/throttle';
import {HalfCircleSpinner} from 'react-epic-spinners';
import {FCChildrenProps} from 'types';

interface IProps extends FCChildrenProps{
    onScrollEnd?: Function;
    page: number;
    pageTotal: number;
    isEnableScrollEnd?: boolean;
    isDidMountScrollToBottom?: boolean;
}

/**
 * PageScrollView
 */
const PageScrollView = ({
    className,
    style,
    children,
    onScrollEnd = () => {},
    page,
    pageTotal,
    isEnableScrollEnd = false,
    isDidMountScrollToBottom = false,
}: IProps) => {

    const listRef = useRef<HTMLDivElement>();

    /**
     * ComponentDidMount
     */
    useEffect(() => {
        // 鎖背景
        if(listRef.current){
            disableBodyScroll(listRef.current);
        }

        // 偵測捲動到底部
        if (isEnableScrollEnd) {
            if(listRef.current) {
                listRef.current.addEventListener('scroll', $handleScrollMovie);
            }
        }

        return () => {
            clearAllBodyScrollLocks();
            if(listRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                listRef.current.removeEventListener('scroll', $handleScrollMovie);
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * 監聽當條件完成, 移除事件
     */
    useEffect(() => {
        if (isEnableScrollEnd && pageTotal === page) {
            if(listRef.current){
                listRef.current.removeEventListener('scroll', $handleScrollMovie);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    /**
     * 當資料有變動時, 自動捲到底部
     */
    useEffect(() => {
        if (isDidMountScrollToBottom && listRef.current) {
            listRef.current.scrollTop = listRef.current.scrollHeight;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const $handleScrollMovie = useCallback(throttle(() => {
        onScrollMovie();
    }, 1000, {leading: false})

    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);

    const onScrollMovie = () => {
        let clientHeight = 0;
        let scrollBottom = 0;
        let scrollHeight = 0;
        let scrollTop = 0;
        if(listRef.current){
            // 容器高(clientHeight)
            clientHeight = listRef.current.clientHeight;
            scrollTop = listRef.current.scrollTop;
            scrollHeight = listRef.current.scrollHeight;
            scrollBottom = scrollHeight - scrollTop;
        }


        if ((scrollBottom) === clientHeight) {
            // 已到底部
            // console.log('已經到底部了');
            onScrollEnd();
        }
    };

    const loadingView = page === pageTotal ? <IsUpToDate>已經是最新了</IsUpToDate> : <PullLoading size={20} color="#bdbdbd"/>;

    return (
        <ScrollViewRoot ref={listRef} className={className} style={style}>
            {/* 內容 */}
            {children}

            {/* 底部讀取與提示 */}
            {isEnableScrollEnd && loadingView}
        </ScrollViewRoot>
    );
};

export default PageScrollView;

const IsUpToDate = styled.div`
    margin: 10px auto 20px auto;
    text-align: center;
    color: #bdbdbd;
`;

const PullLoading = styled(HalfCircleSpinner)`
    margin: 10px auto 20px auto;
`;

const ScrollViewRoot = styled.div<any>`
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    flex: 1 1 0;
    height: 100%;
`;

import api from 'library/apisauce';

import {TLoginRes, TLogoutRes} from './types';

export default {
    /**
     * 登入
     * @param account 帳號
     * @param password 密碼
     */
    login: (account: string, password: string): Promise<TLoginRes> => {
        return api.post('/root/auth/signIn', {account, password});
    },
    /**
     * 登出
     */
    logout: (): Promise<TLogoutRes> => {
        return api.post('/auth/base/logout');
    },
};

import api from 'library/apisauce';

import {TGetPaginateRes, TGetDetailRes, TCreateDataRes, TUpdateDataRes, TDeleteDataRes, TGetSettingsRes} from './types';
import {obj2formData} from '../../utils/format';

/**
 * 會員/資料管理
 */
export default {
    /**
     * 查詢 列表資料
     */
    getPaginate: (currentPage: number, pageLimit: number,
        memberUserId?: number,
    ): Promise<TGetPaginateRes> => {
        return api.get('/member/user', {
            currentPage, pageLimit,
            memberUserId,
        }, {
            headers: {isAuth: true},
        });
    },
    /**
     * 查詢 設定資料
     */
    getSettings: (): Promise<TGetSettingsRes> => {
        return api.get('/member/user/settings', {}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 查詢 明細資料
     */
    getDetail: (id: number): Promise<TGetDetailRes> => {
        return api.get(`/member/user/${id}`, {}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 新增 資料
     */
    createData: (data: {
        fullName: string,
        // firstName: string,
        // lastName: string,
        sex: 'F'|'M',
        address: string,
        phone: string,

        storeId: number,

        idCard1: File | undefined,
        idCard2: File | undefined,
        proofOfAddress: File | undefined,
        avatar: File | undefined,
    }): Promise<TCreateDataRes> => {
        const formData = obj2formData(data);

        return api.post('/member/user', formData, {
            headers: {isAuth: true},
        });
    },
    /**
     * 更新 資料
     */
    updateData: (id: number, data: {
        fullName: string,
        // firstName: string,
        // lastName: string,
        sex: 'F'|'M',
        address: string,
        phone: string,

        idCard1: File | undefined,
        idCard2: File | undefined,
        proofOfAddress: File | undefined,
        avatar: File | undefined,
    }): Promise<TUpdateDataRes> => {
        const formData = obj2formData(data);

        return api.put(`/member/user/${id}`, formData, {
            headers: {
                isAuth: true,
            },
            timeout: 10 * 60 * 1000
        });
    },

    /**
     * 刪除
     */
    deleteData: (ids: number[]): Promise<TDeleteDataRes> => {
        return api.delete('/member/user', {ids}, {
            headers: {isAuth: true},
        });
    },

    /**
     * 刪除圖片
     */
    deleteImage: (id: number, imageName: string): Promise<TDeleteDataRes> => {
        return api.delete(`/member/user/${id}/${imageName}`, {}, {
            headers: {isAuth: true},
        });
    },

};

import {useCallback, useMemo} from 'react';
import styled, {css} from 'styled-components/macro';
import {isEmpty, isNumber} from 'utils/equal';
import {formatCurrency} from 'utils/number';
import media from 'library/styled-bs-grid/media';

// Components
import Icon from 'components/atoms/Icon';
import Select from 'components/forms/Select';

interface IProps {
    meta?: {
        currentPage: number,
        pageLimit: number,
        totalItems: number,
        totalPages: number,
    }
    onChangePage: (targetPage: number, limitPage: number) => void;
}


/**
 * Footer
 */
const TableFooter = ({
    meta = {
        currentPage: 1,
        pageLimit: 50,
        totalItems: 0,
        totalPages: 1,
    },
    onChangePage,
}: IProps) => {

    const paginateInfo = useMemo(() => {

        const end = meta.currentPage * meta.pageLimit;
        return {
            start: ((meta.currentPage-1) * meta?.pageLimit) + 1,
            end: end > meta.totalItems ? meta.totalItems : end,
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meta]);


    const handleConfirmPage = useCallback(() => {
        const ans = window.prompt(`您想跳去第幾頁 (可輸入範圍 1-${meta?.totalPages ?? 1}`, String(meta?.currentPage ?? 1));
        if(!isEmpty(ans)){
            if(isNumber(ans)){
                const numberAns = Number(ans);
                if(numberAns > 0 && numberAns <= meta?.totalPages){
                    onChangePage(Number(ans), meta?.pageLimit ?? 8);
                }else{
                    window.alert('請輸入正確的頁數範圍');
                }
            }else{
                window.alert('請輸入數字');
            }
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meta]);


    /**
     * 產生頁面資訊
     */
    const renderPaginateInfo = useMemo(() => {

        const buttonPageDom = [];

        const currentPage = meta?.currentPage ?? 1;
        const pageLimit = meta?.pageLimit ?? 8;
        const totalPages = meta?.totalPages ?? 1;
        const pageGroup = 5;

        const startPage = ((Math.ceil(currentPage / pageGroup)-1) * pageGroup) + 1;
        const tmpEndPage = (Math.ceil(currentPage / pageGroup) * pageGroup);
        const endPage = tmpEndPage > totalPages ? totalPages : tmpEndPage;

        for(let i = startPage; i <= endPage; i+=1){
            const isActive = i===meta?.currentPage;
            buttonPageDom.push(<PaginateLi
                key={`table-page-button-${i}`}
                type="button"
                isActive={isActive}
                onClick={() => {
                    onChangePage(i, meta?.pageLimit);
                }}
                disabled={isActive}
            >
                {i}
            </PaginateLi>);
        }



        return <>
            <PaginateNav
                type="button"
                disabled={currentPage <= 1}
                onClick={() => onChangePage(currentPage - 1, pageLimit)}
            >
                Prev
            </PaginateNav>

            {buttonPageDom}


            <PaginateNav
                type="button"
                disabled={currentPage >= totalPages}
                onClick={() => onChangePage(currentPage + 1, pageLimit)}
            >
                Next
            </PaginateNav>

            <PaginateNav
                type="button"
                disabled={totalPages <= 1}
                onClick={handleConfirmPage}
            >
                <Icon code="align-center" size={20}/>
            </PaginateNav>
        </>;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meta]);


    return (
        <InnerFooter>
            <PaginateInfo>
                Show {formatCurrency(paginateInfo.start)} - {formatCurrency(paginateInfo.end)}, Total {formatCurrency(meta.totalItems)}, {formatCurrency(meta?.totalPages)} Page
            </PaginateInfo>

            <PageLimit>
                <Select
                    onChange={value => onChangePage(1, Number(value))}
                    value={String(meta?.pageLimit) ?? '50'}
                    options={[
                        {text: '8/Page', value: '8'},
                        {text: '20/Page', value: '20'},
                        {text: '50/Page', value: '50'},
                        {text: '72/Page', value: '72'},
                        {text: '150/Page', value: '150'},
                    ]}
                />
            </PageLimit>

            <PaginateUl>
                {renderPaginateInfo}
            </PaginateUl>

        </InnerFooter>
    );
};

export default TableFooter;

const PageLimit = styled.div`
    flex: 0 0 100%;
    width: 100%;

    ${media.md`
        flex: 0 0 100px;
        width: 100px;
    `}
`;



const PaginateLi = styled.button<{
    isActive?: boolean;
}>`
    flex: 0 0 35px;
    padding: 0 10px;
    font-size: 14px;

    height: 35px;
    width: 35px;
    display: none;
    justify-content: center;
    align-items: center;
    color: #7d8490;
    background-color: #495057;
    border: 1px solid #495057;
    border-right-color: transparent;


    &:hover{
        background-color: #d0d2d5;
        border-color: #b8bcbf;

        > i{
            color: #7d8490;
        }
    }

    ${media.md`
        display: flex;
    `}

    ${props => props.isActive && css`
         color: #fff;
         background-color: #007bff;
         border-color: #007bff;

         &:hover{
            color: #fff;
            background-color: #007bff;
            border-color: #007bff;
        }
    `}
}
`;

const PaginateNav = styled(PaginateLi)`
    padding: 0 10px;
    width: auto;
    position: relative;

    :first-child{
        border-radius: 4px 0 0 4px;
    }
    :last-child{
        border-radius: 0 4px 4px 0;
    }
`;

const PaginateUl = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 1;

  ${media.md`
      flex: 1 1 auto;
  `}
`;

const PaginateInfo = styled.div`
  color: #9aa0ac;
  font-size: 14px;
  margin-right: 20px;
  flex: 1 1 100%;
  display: none;


  ${media.md`
      display: block;
      flex: 0 0 auto;
  `}
`;

const InnerFooter = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  height: 60px;
  flex: 0 0 60px;
`;

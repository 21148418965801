import {createSlice} from '@reduxjs/toolkit';
import {IState, ISelector, IActionCreators} from './types';
import {TReducers} from 'library/redux';


/** -----------------------------------------
                Store Key
 /** ---------------------------------------*/
export const name = 'startUp';


/** -----------------------------------------
             Initial State
 /** ---------------------------------------*/
export const initialState: IState = {
    isReady: false,
    isChecking: false,
    message: '',
};


/** -----------------------------------------
            Selectors
 /** --------------------------------------*/
export const selector: ISelector = {};



/** -----------------------------------------
                Reducers
 /** ---------------------------------------*/
const reducers: TReducers<IActionCreators, IState> = {
    checkIn: (state) => {
        return state;
    },
    checkInBegin: (state) => {
        return {...state, isChecking: true, message: ''};
    },
    checkInSuccess: (state, {payload}) => {
        return {...state, isChecking: false};
    },
    checkInFail: (state, {payload}) => {
        return {...state, isChecking: false, message: payload.message};
    },
};


export const {reducer, actions} = createSlice({name, initialState, reducers});

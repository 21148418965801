import {call, put, take, select} from 'redux-saga/effects';
import get from 'lodash/get';
import setWith from 'lodash/setWith';
import {persistWhileList, persistKey} from 'config/app';
import {isNotEmpty} from 'utils/equal';

// Reducers & Types
import {actions as uiBlockActions} from 'store/uiBlock';
import {actions as authActions, selector as authSelector} from 'store/auth';
import {actions} from './reducer';


/**
 * 啟動檢查
 */
function* checkIn()  {
    yield put(actions.checkInBegin());
    yield put(uiBlockActions.visible());

    try{
        // 取得系統資訊
        // yield put(systemActions.fetchSetting());
        // yield take(SystemActionTypes.FETCH_SETTING_SUCCESS);


        // 判斷TOKEN存在, 檢查是否過期
        if(isNotEmpty((yield select(authSelector.accessToken)) && !(yield select(authSelector.isAuth)), {isFalse: true})){
            // @TODO: 可呼叫確認TOKEN有效API
            yield put(authActions.kickOut());
        }

        // 取得其他只需首次資訊
        // yield all(
        //     yield put(CustomerActions.fetchPaginate()),
        // );

        yield put(actions.checkInSuccess());
    } catch(err){

        yield put(actions.checkInFail({message: err.message}));

    } finally {
        yield put(uiBlockActions.hidden());
    }

}


/**
 * Store Sync
 */
function* watchStateSyncCookie() {

    while (true) {
        yield take('*');

        // 更新狀態
        let isNeedUpdate = false;

        // 目前 LocalStorage State Object
        let storage = JSON.parse(window.localStorage.getItem(persistKey) || '{}');

        // 目前 Redux State Object
        const reduxState = yield select();

        // 檢查白名單內的項目
        for(let i=0; i < persistWhileList.length; i++){
            const statePath = persistWhileList[i];

            const selectState = get(reduxState, statePath);
            const selectPreloadState = get(storage, statePath);

            if (JSON.stringify(selectState) !== JSON.stringify(selectPreloadState)) {
                isNeedUpdate = true;
                storage = setWith(storage, statePath, selectState, Object);
            }
        }

        // 將新的更新同步到Cookie
        if (isNeedUpdate) {
            window.localStorage.setItem(persistKey, JSON.stringify(storage));
        }

    }
}



export default [
    call(watchStateSyncCookie),
    call(checkIn),
];

import {ApiResponse} from 'apisauce';
import {Dispatch} from 'redux';
import {replace} from 'connected-react-router';
import {i18n} from 'library/intl';

import {apiService} from './index';
import {IResponseBody} from './types';
import HttpException from './httpException';

// Stores
import {IRootState} from 'store/rootReducer';
import {selector as LanguageSelectors} from 'store/language';
import {actions as authActions, selector as authSelectors} from 'store/auth';


/**
 * 建立API中介層
 * @param selector redux selector
 * @param dispatch redux dispatch
 */
export default function createApiMiddleware(selector: () => IRootState, dispatch: Dispatch){
    /**
     * Request After Middleware
     */
    apiService.addRequestTransform(request => {
        const rootState = selector();

        // 語系設定
        request.headers['Accept-Language'] = LanguageSelectors.selectLanguage(rootState);

        if(request.headers['isAuth']){
            // 登入狀態設定
            const token = authSelectors.accessToken(rootState);
            if (token) {
                request.headers['Authorization'] = `Bearer ${token}`;
            }
        }

        return request;
    });

    /**
     * Response After Middleware
     */
    apiService.addResponseTransform((response: ApiResponse<IResponseBody>) => {
        const {
            ok,
            status,
            problem,
            originalError,
            config,
            data: body,
            // headers,
        } = response;

        /** 如果是OK的情況, 但後端回傳的 statusType = error, 則改為 ok = false, 會再自動執行 addResponseTransform 並且 ok=false */
        if(ok && body?.statusType === 'error'){
            throw new HttpException(body.message, body.statusCode, body.formMessage);
        }

        /** 請求失敗, 額外處理區塊 */
        if(!ok){
            if (status) {
                // Http Error
                switch (status) {
                case 400:
                    throw new HttpException(body?.message, 'SERVICE_HTTP_400');

                case 401:
                    dispatch(authActions.kickOut());
                    throw new HttpException(i18n({id: 'errorHttp.401', defaultMessage: '請求的API沒有權限'}), 'SERVICE_HTTP_401');

                case 404:
                    throw new HttpException(i18n({id: 'errorHttp.404', defaultMessage: '請求找不到路徑/路由'}), 'SERVICE_HTTP_404');

                case 413:
                    throw new HttpException(i18n({id: 'errorHttp.413', defaultMessage: '發出的請求/檔案 超過伺服器限制大小'}), 'SERVICE_HTTP_413');

                case 500:
                    throw new HttpException(body?.message, 'SERVICE_HTTP_500');

                case 503:
                    throw new HttpException(i18n({id: 'errorHttp.503', defaultMessage: '系统维护中'}), 'SERVICE_HTTP_503');

                case 511:
                    dispatch(replace('/no-access'));
                    throw new HttpException(i18n({id: 'errorHttp.511', defaultMessage: '地區無法提供服務'}), 'SERVICE_HTTP_511');
                }
            } else if(problem === 'TIMEOUT_ERROR') {
                const timeoutSec = config?.timeout ? config.timeout / 1000 : 0;
                throw new HttpException(i18n({id: `errorHttp.${problem}`, defaultMessage: `伺服器逾时超出${timeoutSec}秒无回应，请确认您的网路连线状态或与客服联系`},{sec: timeoutSec}), 'SERVICE_TIMEOUT_ERROR');

            } else if (problem && ['SERVER_ERROR', 'CONNECTION_ERROR', 'NETWORK_ERROR', 'CANCEL_ERROR'].includes(problem)) {
                throw new HttpException(i18n({id: `errorHttp.${problem}`}), `SERVICE_${problem}`);
            }

            if(originalError instanceof HttpException){
                throw new HttpException(originalError.message, originalError.code, originalError.formMessage);
            }

        }

        /** 請求成功, 額外處理區塊 */
        // if (headers.Authentication) {
        //     // 設定認證
        //     dispatch(authAction.setToken(body.data.token));
        // }
    });

};


import {createSlice} from '@reduxjs/toolkit';
import {IState, ISelector, IActionCreators} from './types';
import {TReducers} from 'library/redux';


/** -----------------------------------------
                Store Key
 /** ---------------------------------------*/
export const name = 'store';


/** -----------------------------------------
             Initial State
 /** ---------------------------------------*/
export const initialState: IState = {
    isFetching: false,
    message: '',

    categoryData: [],
    paginateMeta: {
        totalItems: 0,
        totalPages: 1,
        currentPage: 1,
        pageLimit: 50,
    },
    paginateData: [],

    settingData: {
        adminUsers: [],
    },
    currentData: undefined,

};


/** -----------------------------------------
            Selectors
 /** --------------------------------------*/
export const Selector: ISelector = {};



/** -----------------------------------------
                Reducers
 /** ---------------------------------------*/
const reducers: TReducers<IActionCreators, IState> = {
    /**
     * 取得列表清單
     */
    fetchPaginate: (state, action) => {},
    fetchPaginateBegin: (state) => {
        return {...state, isFetching: true, message: ''};
    },
    fetchPaginateSuccess: (state, {payload}) => {
        return {...state, isFetching: false, paginateData: payload.rows, paginateMeta: payload.meta};
    },
    fetchPaginateFail: (state, {payload}) => {
        return {...state, isFetching: false, message: payload.message};
    },
    /**
     * 取得設定資料
     */
    fetchSetting: (state, action) => {},
    fetchSettingBegin: (state) => {
        return {...state, isFetching: true, message: ''};
    },
    fetchSettingSuccess: (state, {payload}) => {
        return {...state, isFetching: false, settingData: payload.data};
    },
    fetchSettingFail: (state, {payload}) => {
        return {...state, isFetching: false, message: payload.message};
    },

    /**
     * 取得明細資料
     */
    fetchCurrent: (state, action) => {},
    fetchCurrentBegin: (state) => {
        return {...state, isFetching: true, currentData: undefined, message: ''};
    },
    fetchCurrentSuccess: (state, {payload}) => {
        return {...state, isFetching: false, currentData: payload.data};
    },
    fetchCurrentFail: (state, {payload}) => {
        return {...state, isFetching: false, message: payload.message};
    },

    /**
     * 清空明細資料
     */
    resetCurrent: (state, action) => {
        return {...state, currentData: undefined};
    },
    /**
     * 新增資料
     */
    createData: (state, action) => {},
    createDataBegin: (state) => {
        return {...state, isFetching: false, message: ''};
    },
    createDataSuccess: (state, {payload}) => {
        return {...state, isFetching: false};
    },
    createDataFail: (state, {payload}) => {
        return {...state, isFetching: false, message: payload.message};
    },
    /**
     * 更新資料
     */
    updateData: (state, action) => {},
    updateDataBegin: (state) => {
        return {...state, isFetching: true, message: ''};
    },
    updateDataSuccess: (state, {payload}) => {
        return {...state, isFetching: false};
    },
    updateDataFail: (state, {payload}) => {
        return {...state, isFetching: false, message: payload.message};
    },
    /**
     * 刪除資料
     */
    deleteData: (state, action) => {},
    deleteDataBegin: (state) => {
        return {...state, isFetching: true, message: ''};
    },
    deleteDataSuccess: (state, {payload}) => {
        return {...state, isFetching: false, message: ''};
    },
    deleteDataFail: (state, {payload}) => {
        return {...state, isFetching: false, message: payload.message};
    },

};


export const {reducer, actions} = createSlice({name, initialState, reducers});

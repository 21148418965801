import {createSlice} from '@reduxjs/toolkit';
import get from 'lodash/get';
import dayjs from 'dayjs';
import {ECode, IActionCreators, ISelector, IState} from './types';
import {TReducers} from 'library/redux';
import {jwtDecode} from './utils';
import {isEmpty} from 'utils/equal';


/** -----------------------------------------
 Store Key
 /** ---------------------------------------*/
export const name = 'auth';


/** -----------------------------------------
 Initial State
 /** ---------------------------------------*/
export const initialState: IState = {
    isSubmitting: false,
    message: '',
    accessToken: '',

    name: '',
    avatarUrl: '',
};


/** -----------------------------------------
 Selectors
 /** --------------------------------------*/
export const selector: ISelector = {
    accessToken: (state) => state[name].accessToken,
    permissions: (state) => {
        return jwtDecode(state[name].accessToken)?.permissions ?? [];
    },
    payload: (state) => jwtDecode(state[name].accessToken),
    isAuth: (state) => {
        const payload = jwtDecode(state[name].accessToken);
        if(!isEmpty(payload)){
            const expiredTime = get(payload, 'exp', 0);

            // 比對現在時間是否超出過期時間
            return dayjs().isBefore(dayjs.unix(expiredTime));
        }
        return false;
    },
    storeId: (state) => {
        const payload = jwtDecode(state[name].accessToken);
        if(!isEmpty(payload)){
            return payload?.storeId;
        }
        return undefined;
    },
    roleCode: (state) => {
        const payload = jwtDecode(state[name].accessToken);
        if(!isEmpty(payload)){
            return payload?.roleCode || ECode.storeStaff;
        }
        return ECode.storeStaff;
    },
};



/** -----------------------------------------
 Reducers
 /** ---------------------------------------*/
const reducers: TReducers<IActionCreators, IState> = {
    /**
     * 登入系統
     */
    login: (state) => {
        return state;
    },
    loginBegin: (state) => {
        return {...state, isSubmitting: true, message: ''};
    },
    loginSuccess: (state, {payload}) => {
        return {...state, isSubmitting: false, accessToken: payload.accessToken, avatarUrl: payload.avatarUrl};
    },
    loginFail: (state, {payload}) => {
        return {...state, isSubmitting: false, message: payload.message};
    },
    /**
     * 登出系統
     */
    logout: (state) => {
        return state;
    },
    logoutBegin: (state) => {
        return {...state, isSubmitting: true, message: ''};
    },
    logoutSuccess: (state, action) => {
        return {...state, isSubmitting: false, accessToken: ''};
    },
    logoutFail: (state, {payload}) => {
        return {...state, isSubmitting: false, message: payload.message};
    },
    /**
     * 踢出系統
     */
    kickOut: (state) => {
        return {...state, accessToken: ''};
    },
};


export const {reducer, actions} = createSlice({name, initialState, reducers});

import {useEffect, useCallback} from 'react';
import styled from 'styled-components/macro';
import dayjs from 'dayjs';
import {Container} from 'library/styled-bs-grid';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {objFilterNotTrue2Array} from 'utils/format';

// Components
import {CardTable as Table} from 'components/atoms/Table';
import PageHeader from 'components/organisms/PageHeader';
import ListFeatureGroup from 'components/organisms/ListFeatureGroup';

// Stores
import {actions as rootNoticeActions} from 'store/rootNotice';
import Label from '../../../../../components/atoms/Label';


/**
 * 廣告/最新消息/列表
 *
 */
const List = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const tableForm = useForm<{checkedId: {
            [key: string]: boolean
        }}>();
    const baseRoutePath = '/root/notice';

    const {paginateData, paginateMeta, isFetching} = useSelector(state => ({
        paginateData: state.rootNotice.paginateData,
        paginateMeta: state.rootNotice.paginateMeta,
        isFetching: state.rootNotice.isFetching,
    }));


    useEffect(() => {
        handleFetchPaginate(1, 50);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     * 查詢分頁
     */
    const handleFetchPaginate = useCallback((currentPage?: number, pageLimit?: number) => {

        dispatch(rootNoticeActions.fetchPaginate({
            currentPage,
            pageLimit,
        }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     * 刪除多筆資料
     */
    const handleDeleteRows = () => {
        // 取得 Table中的 CheckId
        // const {checkedId} = tableForm.getValues({nest: true});
        const {checkedId} = tableForm.getValues();
        const ids = objFilterNotTrue2Array(checkedId);

        if(ids.length === 0){
            alert('請選擇項目');
        }else{
            dispatch(rootNoticeActions.deleteData({ids}));
        }
    };


    /**
     * 刪除一筆資料
     */
    const handleDeleteRow = (id: number) => {
        dispatch(rootNoticeActions.deleteData({ids: [id]}));
    };

    return (
        <PageRoot>

            <PageHeader
                breadcrumbData={[
                    {text: '系統通知'},
                ]}
                tabsData={[
                    {text: '列表', href: baseRoutePath},
                ]}
            />

            <ListFeatureGroup data={[
                {text: 'Delete', onClick: handleDeleteRows, color: 'danger'},
            ]}/>


            {/* 內容 */}
            <Container fluid>

                {/* 表格資料 */}
                <Table
                    isFetching={isFetching}
                    hookForm={tableForm}
                    title={[
                        {text: '標題',        field: 'title', col: true},
                        {text: '是否未讀',        field: 'isRead', width: 90},
                        {text: '建立時間',    field: 'createdAt'    , width: 100},

                    ]}
                    data={paginateData.map(row => {
                        return {
                            id: row.id,
                            title: row.title,
                            isRead: <Label status={row.isRead ? 'success':'gray'}>{row.isRead ? '已讀':'未讀'}</Label>,
                            createdAt: <div style={{fontSize: 12}}>
                                {dayjs(row.createdAt).format('YYYY-MM-DD')}<br/>
                                {dayjs(row.createdAt).format('HH:mm:ss')}
                            </div>,
                        };
                    })}
                    onEditRow={(id, isOpen) => {
                        if(isOpen){
                            window.open(`${baseRoutePath}/${id}`);
                        }else{
                            history.push(`${baseRoutePath}/${id}`);
                        }
                    }}
                    onDeleteRow={id => handleDeleteRow(id)}
                    onChangePage={(page, limit) => handleFetchPaginate(page, limit)}
                    meta={paginateMeta}
                />
            </Container>


        </PageRoot>
    );
};

export default List;


const PageRoot = styled.div`
    padding: 0 20px;
`;

import {createSlice} from '@reduxjs/toolkit';
import {IState, ISelector, IActionCreators} from './types';
import {TReducers} from 'library/redux';


/** -----------------------------------------
 Store Key
 /** ---------------------------------------*/
export const name = 'settingSite';


/** -----------------------------------------
 Initial State
 /** ---------------------------------------*/
export const initialState: IState = {
    isFetching: false,
    message: '',

    paginateMeta: {
        totalItems: 0,
        totalPages: 1,
        currentPage: 1,
        pageLimit: 50,
    },
    paginateData: [],

    currentData: undefined,
    // settingData: {
    //   memberUsers: undefined,
    // },

};


/** -----------------------------------------
 Selectors
 /** --------------------------------------*/
export const selector: ISelector = {};



/** -----------------------------------------
 Reducers
 /** ---------------------------------------*/
const reducers: TReducers<IActionCreators, IState> = {
    /**
     * 取得列表清單
     */
    fetchPaginate: (state, action) => {},
    fetchPaginateBegin: (state) => {
        return {...state, isFetching: true, message: ''};
    },
    fetchPaginateSuccess: (state, {payload}) => {
        return {...state, isFetching: false, paginateData: payload.rows, paginateMeta: payload.meta};
    },
    fetchPaginateFail: (state, {payload}) => {
        return {...state, isFetching: false, message: payload.message};
    },

    /**
     * 取得明細資料
     */
    fetchCurrent: (state, action) => {},
    fetchCurrentBegin: (state) => {
        return {...state, isFetching: true, currentData: undefined, message: ''};
    },
    fetchCurrentSuccess: (state, {payload}) => {
        return {...state, isFetching: false, currentData: payload.data};
    },
    fetchCurrentFail: (state, {payload}) => {
        return {...state, isFetching: false, message: payload.message};
    },
    /**
     * 清空明細資料
     */
    resetCurrent: (state, action) => {
        return {...state, currentData: undefined};
    },

    /**
     * 更新資料
     */
    updateData: (state, action) => {},
    updateDataBegin: (state) => {
        return {...state, isFetching: true, message: ''};
    },
    updateDataSuccess: (state, {payload}) => {
        return {...state, isFetching: false};
    },
    updateDataFail: (state, {payload}) => {
        return {...state, isFetching: false, message: payload.message};
    },


};


export const {reducer, actions} = createSlice({name, initialState, reducers});

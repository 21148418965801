import {useEffect} from 'react';
import styled from 'styled-components/macro';
import dayjs from 'dayjs';
import {Container, Row, Col} from 'library/styled-bs-grid';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

// Components
import Card from 'components/atoms/Card';
import Label from 'components/atoms/Label';
import Avatar from 'components/atoms/Avatar';
import Table from 'components/atoms/Table';
import Checkbox from 'components/forms/Checkbox';

// Stores
import {actions as rootUserActions} from 'store/rootUser';


/**
 * 管理者管理/編輯
 */
const View = () => {
    const params = useParams<{id: string}>();
    const currentId = Number(params.id);

    const dispatch = useDispatch();



    const {currentData} = useSelector(state => ({
        currentData: state.rootUser.currentData,
        // messageHistory: state.rootUser.messageHistory,
    }));


    useEffect(() => {
        dispatch(rootUserActions.fetchCurrent({id: currentId}));
        // dispatch(rootUserActions.fetchMessageHistory({id: currentId}));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Container fluid>
            <Row>
                <Col col={8}>

                    <Card>
                        <Avatar
                            size={70}
                            image={currentData?.avatarUrl}
                            onEdit={() => alert('edit modal')}
                        />

                        <ProfileInfo>
                            <UserNameGroup>
                                <UserName>{currentData?.name}</UserName>
                                <Label status={currentData?.isEnable ? 'success':'gray'}>{currentData?.isEnable ? 'Enable':'Disable'}</Label>
                            </UserNameGroup>
                            <Address>
                                {currentData ? `Created at ${dayjs(currentData.createdAt).format('YYYY-MM-DD')} ` :''}
                            </Address>

                            {/*/!* 錢包資訊 *!/*/}
                            {/*<AboutCounter>*/}

                            {/*    <Counter>*/}
                            {/*        <CountNumber>{formatCurrency(currentData?.walletAmount ?? 0)}</CountNumber>*/}
                            {/*        <CountTitle>Wallet</CountTitle>*/}
                            {/*    </Counter>*/}

                            {/*    <Counter>*/}
                            {/*        <CountNumber>{formatCurrency(currentData?.unpaidAmount ?? 0)}</CountNumber>*/}
                            {/*        <CountTitle>Unpaid</CountTitle>*/}
                            {/*    </Counter>*/}

                            {/*</AboutCounter>*/}
                        </ProfileInfo>
                    </Card>

                    <Card
                        title="About Me"
                        direction="column"
                    >
                        <Desc>
                        Hello I am Celena Anderson a Clerk in Xyz College USA. I love to work with all my college staff and seniour professors.
                        </Desc>

                        <ListInfo>
                            <ListInfoItem>
                                <ListInfoLabel>Role</ListInfoLabel>
                                <ListInfoValue>{currentData?.role?.name}</ListInfoValue>
                            </ListInfoItem>
                            <ListInfoItem>
                                <ListInfoLabel>Account</ListInfoLabel>
                                <ListInfoValue>{currentData?.account}</ListInfoValue>
                            </ListInfoItem>
                            <ListInfoItem>
                                <ListInfoLabel>Email</ListInfoLabel>
                                <ListInfoValue>{currentData?.email}</ListInfoValue>
                            </ListInfoItem>

                        </ListInfo>

                    </Card>

                </Col>


                <Col col={16}>
                    <Card
                        direction="column"
                        title="ROLE PERMISSIONS"
                    >


                        <Table
                            title={[
                                {text: '#',        field: 'id'         , width: 50},
                                {text: 'Feature',     field: 'name'       , col: true},
                                {text: 'Allow',     field: 'allow'  , width: 80},
                            ]}
                            data={currentData?.role?.permissions ? currentData?.role?.permissions.map(row => {
                                return {
                                    id: row.id,
                                    name: row.name,
                                    allow: <Checkbox checked={true} disabled/> ,
                                };
                            }) : []}
                            isEnableChecked={false}
                        />


                    </Card>
                </Col>

            </Row>
        </Container>
    );
};

export default View;


const UserNameGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;


const ListInfoValue = styled.div`
    font-size: 14px;
`;

const ListInfoLabel = styled.b`
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 2px;
`;

const ListInfoItem = styled.li`
    margin-bottom: -1px;
    background: #2b3035;
    height: 68px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
    border: 1px solid rgba(0,0,0,.125);
    border-color: #343a40;
    :first-child {
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
    }
`;
const ListInfo = styled.ul`
`;

const Desc = styled.p`
  font-size: 14px;
  margin-bottom: 20px;

`;


const Address = styled.p`
    font-size: 14px;
    color: #6c757d;
    margin-bottom: 10px;
`;

const UserName = styled.h5`
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 5px;
    flex: 1
`;

const ProfileInfo = styled.div`
  flex: 1;
  margin-left: 10px;
`;



import {FC} from 'react';
import {Route, Redirect, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';

// Stores
import {selector as authSelector} from 'store/auth';


interface IProps {
    exact?: boolean,
    path: string;
}


/**
 * 登入狀態保護路由
 * @param Component
 * @param path 路由路徑
 * @param exact 路由路徑是否需完整比對
 *              ex: sign => sign/login (false)
 *                  sign => sign       (true)
 */
const PrivateRoute: FC<IProps> = ({
    children,
    path,
    exact = false,
}) => {
    const location = useLocation();

    const isAuth = useSelector(authSelector.isAuth);

    return (
        <Route
            path={path}
            exact={exact}
            children={!isAuth ? (<Redirect to={{pathname: '/sign/login', state: {from: location.pathname}}}/>): children}
        />
    );
};

export default PrivateRoute;

import {Switch, Route, Redirect} from 'react-router-dom';
import PermissionRoute from 'library/react-router/PermissionRoute';

// Views
import NotFound from 'views/NotFound';


import RootUserList from 'views/HomeRoot/Root/User/List';
import RootUserDetail from 'views/HomeRoot/Root/User/Detail';

import ProfileDetail from 'views/HomeRoot/Root/User/Profile/Detail';


import RootRoleList from 'views/HomeRoot/Root/Role/List';
import RootRoleEdit from 'views/HomeRoot/Root/Role/Edit';


import RootNoticeList from 'views/HomeRoot/Root/Notice/List';
import RootNoticeEdit from 'views/HomeRoot/Root/Notice/Edit';



import MessageList from 'views/HomeRoot/Message/List';
import MessageEdit from 'views/HomeRoot/Message/Edit';



import MemberUserList from 'views/HomeRoot/Member/User/List';
import MemberUserDetail from 'views/HomeRoot/Member/User/Detail';


import SettingSiteList from 'views/HomeRoot/Setting/Site/List';
import SettingSiteEdit from 'views/HomeRoot/Setting/Site/Edit';

import StoreList from 'views/HomeRoot/Store/List';
import StoreEdit from 'views/HomeRoot/Store/Edit';


import JobList from 'views/HomeRoot/Job/List';
import JobEdit from 'views/HomeRoot/Job/Edit';


import Report from 'views/HomeRoot/Report';

import Dashboard from './Dashboard';


const Router = () => {


    return (
        <Switch>

            {/* 管理端/使用者管理 */}
            <PermissionRoute path="/root/user/:id" children={<RootUserDetail/>}/>
            <PermissionRoute path="/root/user" exact children={<RootUserList/>}/>

            <PermissionRoute path="/profile" children={<ProfileDetail/>}/>

            {/* 管理端/權限角色管理 */}
            <PermissionRoute path="/root/role/:id" children={<RootRoleEdit/>}/>
            <PermissionRoute path="/root/role" exact children={<RootRoleList/>}/>

            {/* 管理端/訊息管理 */}
            <PermissionRoute path="/root/notice/:id" children={<RootNoticeEdit/>}/>
            <PermissionRoute path="/root/notice" exact children={<RootNoticeList/>}/>

            {/* 聊天功能 */}
            <PermissionRoute path="/message/:memberId/:categoryId" children={<MessageEdit/>}/>
            <PermissionRoute path="/message" exact children={<MessageList/>}/>



            {/* 會員管理/會員 */}
            <PermissionRoute path="/member/user/:id" children={<MemberUserDetail/>}/>
            <PermissionRoute path="/member/user" exact children={<MemberUserList/>}/>


            {/* 店管理 */}
            <PermissionRoute path="/store/:id" children={<StoreEdit/>}/>
            <PermissionRoute path="/store" exact children={<StoreList/>}/>

            {/* 工作單管理 */}
            <PermissionRoute path="/job/:id" children={<JobEdit/>}/>
            <PermissionRoute path="/job" exact children={<JobList/>}/>


            {/* 報表 */}
            <PermissionRoute path="/report" exact children={<Report/>}/>






            {/* 網站設定/參數設定 */}
            <PermissionRoute path="/setting/site/:id" exact children={<SettingSiteEdit/>}/>
            <PermissionRoute path="/setting/site" exact children={<SettingSiteList/>}/>

            {/* 控制面板 */}
            <Route path="/dashboard" exact children={<Dashboard/>}/>

            {/* Root Default */}
            <Redirect path="/" exact to="/dashboard"/>

            <Route path="*" children={<NotFound/>}/>
        </Switch>
    );
};

export default Router;

import api from 'library/apisauce';

import {TGetPaginateRes, TGetDetailRes, TCreateDataRes, TGetCategoryOutline} from './types';

/**
 * 會員/資料管理
 */
export default {
    /**
     * 查詢 列表資料
     */
    getPaginate: (currentPage: number, pageLimit: number): Promise<TGetPaginateRes> => {
        return api.get('/message/detail', {currentPage, pageLimit}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 查詢 明細資料
     */
    getDetail: (currentPage: number, memberId: number, categoryId: number): Promise<TGetDetailRes> => {
        return api.get(`/message/detail/${memberId}/${categoryId}`, {currentPage, pageLimit: 50}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 查詢 類別Outline資料
     */
    getCategoryOutline: (memberId: number): Promise<TGetCategoryOutline> => {
        return api.get(`/message/category/outline/${memberId}`, {},{
            headers: {isAuth: true},
        });
    },

    /**
     * 新增 資料
     */
    createData: (
        memberId: number,
        categoryId: number,
        data: {
            content: string,
    }): Promise<TCreateDataRes> => {
        return api.post(`/message/detail/${memberId}/${categoryId}`, data, {
            headers: {isAuth: true},
        });
    },


};

import React from 'react';
import {useSelector} from 'react-redux';

// Components
import Block from 'components/atoms/Block';


const GlobalBlock: React.FC = () => {
    const {isVisible, message} = useSelector(state => ({
        isVisible: state.uiBlock.isVisible,
        message: state.uiBlock.message,
    }));

    return (
        <Block isVisible={isVisible} message={message}/>
    );
};

export default GlobalBlock;


import {createSlice} from '@reduxjs/toolkit';
import {IState, ISelector, IActionCreators} from './types';
import {TReducers} from 'library/redux';


/** -----------------------------------------
                Store Key
 /** ---------------------------------------*/
export const name = 'uiImage';


/** -----------------------------------------
             Initial State
 /** ---------------------------------------*/
export const initialState: IState = {
    isVisible: false,
    imageUrl: '',
};


/** -----------------------------------------
            Selectors
 /** --------------------------------------*/
export const Selector: ISelector = {};



/** -----------------------------------------
                Reducers
 /** ---------------------------------------*/
const reducers: TReducers<IActionCreators, IState> = {
    /**
     * 登入系統
     */
    visible: (state, {payload}) => {
        return {...state, isVisible: true, imageUrl: payload?.imageUrl};
    },
    hidden: (state) => {
        return {...state, isVisible: false};
    },
};


export const {reducer, actions} = createSlice({name, initialState, reducers});

import React, {useCallback, useEffect, useMemo} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components/macro';
import {Container} from 'library/styled-bs-grid';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import dayjs from 'dayjs';
import {isEmpty, isNotEmpty} from 'utils/equal';
import {toBoolean, toNumber} from 'utils/format';
import {currencies, transferOptions} from 'config/common';

// Components
import Card from 'components/atoms/Card';
import Button from 'components/atoms/Button';
import FormHorizontalGroup from 'components/forms/FormHorizontalGroup';
import PageHeader from 'components/organisms/PageHeader';
import TextField from 'components/forms/TextField';
import Select2 from 'components/forms/Select2';
import DateField from 'components/forms/DateField';
import Select from 'components/forms/Select';
import SwitchControl from 'components/forms/SwitchControl';
import ListFeatureGroup from 'components/organisms/ListFeatureGroup';

// Stores
import {actions as jobActions} from 'store/job';
import {actions as memberUserActions} from 'store/memberUser';
import {actions as memberBalanceActions} from 'store/memberBalance';

import {IFormData} from './types';
import {formatCurrency, toDecimal2} from 'utils/number';

/**
 * 廣告/最新消息/編輯
 */
const Edit = () => {
    const history = useHistory();
    const params = useParams<{id: string}>();
    const currentId = params.id;
    const isCreate = params.id === 'create';
    const baseRoutePath = '/job';

    const dispatch = useDispatch();

    const {handleSubmit, control, reset, watch, setValue} = useForm<IFormData>();

    const {currentData, settingData} = useSelector(state => ({
        currentData: state.job.currentData,
        settingData: state.job.settingData,
    }));

    const {memberInfoData} = useSelector(state => ({
        memberInfoData: state.memberUser.currentData,
    }));
    const {memberBalanceList} = useSelector(state => ({
        memberBalanceList: state.memberBalance.paginateData,
    }));


    useEffect(() => {
        if(isCreate){
            dispatch(jobActions.resetCurrent());
        }else{
            dispatch(jobActions.fetchCurrent({id: Number(currentId)}));
        }

        dispatch(jobActions.fetchSetting());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {memberUserId} = watch(['memberUserId']);
    const {soldCurrency} = watch(['soldCurrency']);
    const {outboundOption: transferOptionId} = watch(['outboundOption']);
    const {buyAmount, rate} = watch(['buyAmount', 'rate']);


    // console.log('transferOptionId', transferOptionId);

    useEffect(() => {
        if(memberUserId){
            dispatch(memberUserActions.fetchCurrent({id: memberUserId}));
            dispatch(memberBalanceActions.fetchPaginate({memberUserId: memberUserId}));
        }

    }, [memberUserId]);

    /**
     * 設定預設值
     */
    useEffect(() => {
        if(isCreate){
            reset();
        }else{
            reset({
                date: currentData?.date || dayjs().format('YYYY-MM-DD'),
                buyCurrency: currentData?.buyCurrency || '',
                buyAmount: currentData?.buyAmount,
                rate: currentData?.rate,
                soldCurrency: currentData?.soldCurrency || '',
                soldAmount: currentData?.soldAmount ,
                paidToMember: currentData?.paidToMember ,
                isHandleFee: toBoolean(currentData?.isHandleFee),
                isLodged: toBoolean(currentData?.isLodged),
                isCompleted: toBoolean(currentData?.isCompleted),
                transferOption: currentData?.transferOption || 1,
                outboundOption: currentData?.outboundOption || '',

                payeeFullName: currentData?.payeeFullName,
                // payeeFirstName: currentData?.payeeFirstName,
                // payeeLastName: currentData?.payeeLastName,
                bsb: currentData?.bsb,
                account: currentData?.account,
                bankName: currentData?.bankName,
                bankAddress: currentData?.bankAddress,
                beneficiaryAddress: currentData?.beneficiaryAddress,
                // postCode: currentData?.postCode,
                balanceAmount: currentData?.balanceAmount,

                memberUserId: currentData?.memberUser?.id,


            });
        }

        // eslint-disable-next-line
    }, [currentData]);

    const selectBalanceInfo = memberBalanceList.find(row => row.balanceCurrency === soldCurrency);

    /**
     * MEMO 貨幣
     */
    const memoCurrencies = useMemo(() => {

        return [{text: 'Select Currency', value: ''}, ...currencies];

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    /**
     * MEMO 會員貨幣
     */
    const memoMemberCurrencies = useMemo(() => {

        const data = memberBalanceList.map(row => {
            return {
                text: `${row.balanceCurrency} ($${formatCurrency(row.balanceAmount)})`,
                value: String(row.id),
            };

        });

        return [{text: 'Select Currency', value: ''}, ...data];

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memberBalanceList.map(row => row.id).join('_')]);



    /**
     * MEMO 管理員
     */
    const memoAdminUsers = useMemo(() => {

        const data = settingData?.memberUsers?.map(row => {
            return {text: row.name, value: String(row.id)};
        }) || [];

        return [{text: 'Select Root', value: ''}, ...data];

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingData?.memberUsers?.length]);


    /**
     * MEMO 顧客
     */
    const memoMemberUsers = useMemo(() => {

        const data = settingData?.memberUsers?.map(row => {
            return {text: `#${row.id} ${row.name}`, value: String(row.id)};
        }) || [];

        return [{text: 'Select Customer', value: ''}, ...data];

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingData?.memberUsers?.length]);


    /**
     * 匯率金額計算
     * @param amount
     * @param rate
     */
    const getRateSoldAmount = (amount: number, rate: number): number => {
        return toDecimal2(amount * rate) ?? 0;
    };


    /**
     * 下載PDF
     */
    const handleDownloadPdf = () => {

        dispatch(jobActions.download({id: Number(currentId)}));
    };


    /**
     * 設定會員同樣資料到 Job
     */
    const handleSetSameMemberData = () => {

        setValue('payeeFullName', selectBalanceInfo?.accountFullName);
        // setValue('payeeFirstName', selectBalanceInfo?.accountFirstName);
        // setValue('payeeLastName', selectBalanceInfo?.accountLastName);
        setValue('account', selectBalanceInfo?.account);
        setValue('bsb', selectBalanceInfo?.bsb);
        setValue('bankName', selectBalanceInfo?.bankName);
        setValue('bankAddress', selectBalanceInfo?.bankAddress);
        setValue('beneficiaryAddress', memberInfoData?.address);
        setValue('balanceAmount', selectBalanceInfo?.balanceAmount);
    };


    /**
     * 送出表單
     * @param formData
     */
    const handleSubmitHandler: SubmitHandler<IFormData> = formData => {
        if(isCreate){
            // 新增
            dispatch(jobActions.createData({data: {
                ...formData,
                isHandleFee: toBoolean(formData?.isHandleFee) ?? false,
                soldAmount: getRateSoldAmount(buyAmount, rate),
            },
            }));

        }else{
            // 編輯
            dispatch(jobActions.updateData({id: Number(currentId), data: {
                ...formData,
                isHandleFee: toBoolean(formData?.isHandleFee) ?? false,
                soldAmount: getRateSoldAmount(buyAmount, rate),

            }}));

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    return (
        <PageRoot>
            <PageHeader
                breadcrumbData={[
                    {text: 'Job Manage', href: baseRoutePath},
                    {text: 'Profile'},
                ]}
                tabsData={[
                    {text: isCreate ? 'Add':'Edit', href: `${baseRoutePath}/${currentId}`},
                ]}
            />


            <ListFeatureGroup data={[
                {text: 'Download', onClick: handleDownloadPdf, color: 'primary', isVisible: !isCreate},
            ]}/>

            {/* 內容 */}
            <Container fluid>
                <Card direction="column">
                    <form onSubmit={handleSubmit(handleSubmitHandler)}>


                        <FormHorizontalGroup label="Date">
                            <Controller
                                control={control}
                                name="date"
                                defaultValue=""
                                render={({onChange, value}) => {
                                    return (<DateField
                                        onChange={onChange}
                                        value={value}
                                    />);
                                }}
                            />
                        </FormHorizontalGroup>




                        <FormHorizontalGroup label="Customer Paid">
                            <Controller
                                control={control}
                                name="buyCurrency"
                                defaultValue=""
                                render={({onChange, value}) => {
                                    return (<Select2
                                        className="mr-2"
                                        onChange={onChange}
                                        value={value}
                                        options={memoCurrencies}
                                    />);
                                }}
                            />

                            <Controller
                                control={control}
                                name="buyAmount"
                                defaultValue=""
                                render={({onChange, value}) => {
                                    return (<TextField
                                        onChange={onChange}
                                        value={value}
                                        maxLength={11}
                                    />);
                                }}
                            />
                        </FormHorizontalGroup>


                        <FormHorizontalGroup label="Rate">
                            <Controller
                                control={control}
                                name="rate"
                                defaultValue=""
                                render={({onChange, value}) => {
                                    return (<TextField
                                        onChange={onChange}
                                        value={value}
                                    />);
                                }}
                            />
                        </FormHorizontalGroup>



                        <FormHorizontalGroup label="Customer Received">
                            <Controller
                                control={control}
                                name="soldCurrency"
                                defaultValue=""
                                render={({onChange, value}) => {
                                    return (<Select2
                                        className="mr-2"
                                        onChange={onChange}
                                        value={value}
                                        options={memoCurrencies}
                                    />);
                                }}
                            />

                            <Controller
                                control={control}
                                name="soldAmount"
                                defaultValue=""
                                render={({onChange, value}) => {
                                    return (<TextField
                                        onChange={onChange}
                                        value={String(getRateSoldAmount(buyAmount, rate))}
                                        maxLength={11}
                                        disabled
                                    />);
                                }}
                            />
                        </FormHorizontalGroup>



                        <FormHorizontalGroup label="Customer">
                            <Controller
                                control={control}
                                name="memberUserId"
                                defaultValue=""
                                render={({onChange, value}) => {
                                    return (<Select2
                                        onChange={onChange}
                                        value={value}
                                        options={memoMemberUsers}
                                    />);
                                }}

                            />
                        </FormHorizontalGroup>

                        {memberUserId && (<>

                            <FormHorizontalGroup label="Customer Profile">
                                <div className="d-flex flex-column align-items-start" style={{paddingTop: '10px'}}>
                                    <div className="mb-1">Phone: {memberInfoData?.phone}</div>
                                    <div className="mb-1">Address: {memberInfoData?.address}</div>
                                    <div className="mb-1">Email: {memberInfoData?.email}</div>
                                </div>
                            </FormHorizontalGroup>

                            <FormHorizontalGroup label="Customer Bank Data">
                                <div className="d-flex flex-column align-items-start" style={{paddingTop: '10px'}}>

                                    {selectBalanceInfo ? (<>
                                        <div className="mb-1">Acc Name: {selectBalanceInfo.accountFullName}</div>
                                        <div className="mb-1">Acc: {selectBalanceInfo.account}</div>
                                        <div className="mb-1">BSB: {selectBalanceInfo.bsb}</div>
                                        <div className="mb-1">BankName: {selectBalanceInfo.bankName}</div>
                                        <div className="mb-1">bankAddress: {selectBalanceInfo.bankAddress}</div>
                                        <div className="mb-1">Balance: {selectBalanceInfo.balanceAmount}</div>
                                    </>): <div className="mb-1">Not Data</div>}
                                </div>
                            </FormHorizontalGroup>
                        </>)}


                        <FormHorizontalGroup label="InBound Option">
                            <Controller
                                control={control}
                                name="transferOption"
                                defaultValue="1"
                                render={({onChange, value}) => {
                                    return (<Select
                                        onChange={onChange}
                                        value={value}
                                        options={
                                            [
                                                {text: 'Please Select', value: ''},
                                                ...transferOptions.map(row => {
                                                    return {text: row.name, value: String(row.id)};
                                                })
                                            ]
                                        }
                                    />);
                                }}

                            />
                        </FormHorizontalGroup>

                        <hr/>


                        <FormHorizontalGroup label="Outbound Option">
                            <Controller
                                control={control}
                                name="outboundOption"
                                defaultValue=""
                                render={({onChange, value}) => {
                                    return (<Select
                                        onChange={onChange}
                                        value={value}
                                        options={
                                            [
                                                {text: 'Please Select', value: ''},
                                                ...transferOptions
                                                    .filter(row => row.id != 1)
                                                    .map(row => {
                                                        return {text: row.name, value: String(row.id)};
                                                    })
                                            ]
                                        }
                                    />);
                                }}

                            />
                        </FormHorizontalGroup>

                        {['3'].includes(String(transferOptionId)) && (<>
                            {memberUserId && (
                                <div className="d-flex flex-row justify-content-center mb-4">
                                    <Button color="primary" onClick={handleSetSameMemberData}>Same Member Data</Button>
                                </div>
                            )}








                            <FormHorizontalGroup label="Payee">
                                <Controller
                                    control={control}
                                    name="payeeFullName"
                                    defaultValue=""
                                    render={({onChange, value}) => {
                                        return (<TextField
                                            onChange={onChange}
                                            value={value}
                                        />);
                                    }}
                                />
                                {/*<Controller*/}
                                {/*    control={control}*/}
                                {/*    name="payeeLastName"*/}
                                {/*    defaultValue=""*/}
                                {/*    render={({onChange, value}) => {*/}
                                {/*        return (<TextField*/}
                                {/*            onChange={onChange}*/}
                                {/*            value={value}*/}
                                {/*            placeholder="Last Name"*/}
                                {/*        />);*/}
                                {/*    }}*/}
                                {/*/>*/}
                            </FormHorizontalGroup>

                            <FormHorizontalGroup label="BSB">
                                <Controller
                                    control={control}
                                    name="bsb"
                                    defaultValue=""
                                    render={({onChange, value}) => {
                                        return (<TextField
                                            className="mr-1"
                                            onChange={onChange}
                                            value={value}
                                        />);
                                    }}
                                />
                            </FormHorizontalGroup>

                            <FormHorizontalGroup label="Acc Number">
                                <Controller
                                    control={control}
                                    name="account"
                                    defaultValue=""
                                    render={({onChange, value}) => {
                                        return (<TextField
                                            onChange={onChange}
                                            value={value}
                                        />);
                                    }}
                                />
                            </FormHorizontalGroup>

                            <FormHorizontalGroup label="Bank Name">
                                <Controller
                                    control={control}
                                    name="bankName"
                                    defaultValue=""
                                    render={({onChange, value}) => {
                                        return (<TextField
                                            onChange={onChange}
                                            value={value}
                                        />);
                                    }}
                                />
                            </FormHorizontalGroup>

                            <FormHorizontalGroup label="Bank Address">
                                <Controller
                                    control={control}
                                    name="bankAddress"
                                    defaultValue=""
                                    render={({onChange, value}) => {
                                        return (<TextField
                                            onChange={onChange}
                                            value={value}
                                        />);
                                    }}
                                />
                            </FormHorizontalGroup>

                            <FormHorizontalGroup label="Beneficiary Address">
                                <Controller
                                    control={control}
                                    name="beneficiaryAddress"
                                    defaultValue=""
                                    render={({onChange, value}) => {
                                        return (<TextField
                                            onChange={onChange}
                                            value={value}
                                        />);
                                    }}
                                />
                            </FormHorizontalGroup>

                            {/*<FormHorizontalGroup label="Post code">*/}
                            {/*    <Controller*/}
                            {/*        control={control}*/}
                            {/*        name="postCode"*/}
                            {/*        defaultValue=""*/}
                            {/*        render={({onChange, value}) => {*/}
                            {/*            return (<TextField*/}
                            {/*                onChange={onChange}*/}
                            {/*                value={value}*/}
                            {/*            />);*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</FormHorizontalGroup>*/}

                            {/*<FormHorizontalGroup label="Balance Amount">*/}
                            {/*    <Controller*/}
                            {/*        control={control}*/}
                            {/*        name="balanceAmount"*/}
                            {/*        defaultValue=""*/}
                            {/*        render={({onChange, value}) => {*/}
                            {/*            return (<TextField*/}
                            {/*                onChange={onChange}*/}
                            {/*                value={value}*/}
                            {/*            />);*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</FormHorizontalGroup>*/}


                        </>)}







                        <FormHorizontalGroup label="Handle Fee">
                            <Controller
                                control={control}
                                name="isHandleFee"
                                defaultValue=""
                                render={({onChange, value}) => {
                                    return <SwitchControl checked={value} onChange={onChange}/>;
                                }}
                            />
                        </FormHorizontalGroup>

                        <FormHorizontalGroup label="Paid To Member">
                            <Controller
                                control={control}
                                name="paidToMember"
                                defaultValue=""
                                render={({onChange, value}) => {
                                    return (<TextField
                                        onChange={onChange}
                                        value={value}
                                        maxLength={11}
                                    />);
                                }}
                            />
                        </FormHorizontalGroup>


                        <FormHorizontalGroup label="Lodged">
                            <Controller
                                control={control}
                                name="isLodged"
                                defaultValue=""
                                render={({onChange, value}) => {
                                    return <SwitchControl checked={value} onChange={onChange}/>;
                                }}

                            />
                        </FormHorizontalGroup>

                        <FormHorizontalGroup label="Completed">
                            <Controller
                                control={control}
                                name="isCompleted"
                                defaultValue=""
                                render={({onChange, value}) => {
                                    return <SwitchControl checked={value} onChange={onChange}/>;
                                }}

                            />
                        </FormHorizontalGroup>

                        {currentData && (
                            <FormHorizontalGroup label="Create By">
                                #{currentData?.rootUser.id} {currentData?.rootUser.name} In {currentData?.createdAt}
                            </FormHorizontalGroup>
                        )}


                        <FormHorizontalGroup>
                            <Button type="submit" color="primary" className="mr-2">{isCreate ? 'Create': 'Save'}</Button>
                            <Button type="button" color="gray" onClick={() => history.push(baseRoutePath)}>{isCreate ? 'Cancel': 'Back'}</Button>
                        </FormHorizontalGroup>

                    </form>
                </Card>
            </Container>

        </PageRoot>
    );
};

export default Edit;


const PageRoot = styled.div`
    padding: 0 20px;
`;

import {initialState as startUp} from './startUp';
import {initialState as language} from'./language';
import {initialState as system} from'./system';
import {initialState as auth} from'./auth';
import {initialState as uiBlock} from'./uiBlock';
import {initialState as uiDialog} from'./uiDialog';
import {initialState as uiImage} from'./uiImage';

import {initialState as dashboard} from'./dashboard';

import {initialState as rootUser} from'./rootUser';
import {initialState as rootRole} from'./rootRole';
import {initialState as rootNotice} from'./rootNotice';



import {initialState as message} from'./message';


import {initialState as memberUser} from'./memberUser';
import {initialState as memberBalance} from'./memberBalance';

import {initialState as store} from'./store';
import {initialState as job} from'./job';
import {initialState as report} from'./report';


import {initialState as settingSite} from'./settingSite';



export default {
    startUp,
    language,
    system,
    auth,
    uiBlock,
    uiDialog,
    uiImage,


    dashboard,



    rootUser,
    rootRole,
    rootNotice,




    message,



    memberUser,
    memberBalance,

    store,
    job,
    report,

    settingSite,
};




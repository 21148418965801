import {useEffect, useCallback} from 'react';
import styled from 'styled-components/macro';
import dayjs from 'dayjs';
import {Container} from 'library/styled-bs-grid';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';

// Components
import {CardTable as Table} from 'components/atoms/Table';
import PageHeader from 'components/organisms/PageHeader';
import Avatar from 'components/atoms/Avatar';
import Label from 'components/atoms/Label';

// Stores
import {actions as messageActions} from 'store/message';


/**
 * 廣告/最新消息/列表
 */
const List = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const tableForm = useForm<{checkedId: {
            [key: string]: boolean
        }}>();
    const baseRoutePath = '/message';

    const {paginateData, paginateMeta, isFetching} = useSelector(state => ({
        paginateData: state.message.paginateData,
        paginateMeta: state.message.paginateMeta,
        isFetching: state.message.isFetching,
    }));


    useEffect(() => {
        handleFetchPaginate(1, 50);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     * 查詢分頁
     */
    const handleFetchPaginate = useCallback((currentPage?: number, pageLimit?: number) => {

        dispatch(messageActions.fetchPaginate({
            currentPage,
            pageLimit,
        }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <PageRoot>

            <PageHeader
                breadcrumbData={[
                    {text: '站內訊息'},
                ]}
                tabsData={[
                    {text: '列表', href: baseRoutePath},
                ]}
            />


            {/* 內容 */}
            <Container fluid>

                {/* 表格資料 */}
                <Table
                    mode="nonLine"
                    isVisibleHeader={false}
                    isEnableChecked={false}
                    isVisibleActions={false}
                    isFetching={isFetching}
                    hookForm={tableForm}
                    title={[
                        {text: '#',        field: 'avatar', width: 50},
                        {text: '會員',        field: 'name', width: 180},
                        {text: '類別',        field: 'categoryName', width: 80},
                        {text: '訊息',        field: 'content', col: true},
                        {text: '是否未讀',        field: 'isRead', width: 90},
                        {text: '建立時間',    field: 'createdAt'    , width: 100},
                    ]}
                    data={paginateData.map(row => {
                        const notReadCount = row.notReadCount;
                        return {
                            id: row.id,
                            avatar: <Avatar image={row.avatarUrl}/>,
                            name: <div className="d-flex flex-column">
                                <div>{row.realName}</div>
                                <div>V{row.id}</div>
                            </div>,
                            categoryName: <div style={{color: row.message?.category?.themeColor}}>{row.message?.category?.name}</div>,
                            content: <ClampText>{row.message.content}</ClampText>,
                            isRead: <Label status={notReadCount === 0 ? 'success':'gray'}>{notReadCount === 0 ? '已讀':`未讀${notReadCount}`}</Label>,
                            createdAt: <div style={{fontSize: 12}}>
                                {dayjs(row.message.createdAt).format('YYYY-MM-DD')}<br/>
                                {dayjs(row.message.createdAt).format('HH:mm:ss')}
                            </div>,
                            onClickRow: () => history.push(`${baseRoutePath}/${row.id}/${row.message.category.id}`),
                        };
                    })}
                    onEditRow={(id, isOpen) => {
                        if(isOpen){
                            window.open(`${baseRoutePath}/${id}`);
                        }else{
                            history.push(`${baseRoutePath}/${id}`);
                        }
                    }}
                    // onDeleteRow={id => handleDeleteRow(id)}
                    onChangePage={(page, limit) => handleFetchPaginate(page, limit)}
                    meta={paginateMeta}
                />
            </Container>


        </PageRoot>
    );
};

export default List;


const ClampText = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

const PageRoot = styled.div`
    padding: 0 20px;
`;

import {Link} from 'react-router-dom';
import {FCChildrenProps} from 'types';

interface IProps extends FCChildrenProps{
    href: string;
    type?: 'a'|'link';
    alt?: string;
}

/**
 * 路由超連結
 */
const A = ({
    style,
    className,
    children,
    href,
    type = 'link',
}: IProps) => {
    if (type === 'a') {
        return <a style={style} className={className} href={href}>{children}</a>;
    }

    return (
        <Link className={className} style={style} to={href}>{children}</Link>
    );
};

export default A;

import {ApiResponse, ApisauceInstance, create} from 'apisauce';
import {AxiosRequestConfig} from 'axios';
import {autoMapper} from 'utils/format';
import {sec2ms} from 'utils/number';


export const requestHeader = {
    formData: {'Content-Type': 'multipart/form-data'},
    formUrlDecode: {'Content-Type': 'application/x-www-form-urlencoded'},  // 需加 qs.stringify()
    json: {'Content-Type': 'application/json'},
};

export const apiService: ApisauceInstance = create({
    baseURL: '/api',
    headers: {
        ...requestHeader.json,
        'Cache-Control': 'no-cache',
        // Extend Headers...
        // 'Site-ID': siteId, // 由 Server端(Nginx)設定
    },
    timeout: sec2ms(10),
});

const reMapping: <T>(apiRequest: ApiResponse<T>) => any = (apiRequest) => autoMapper(apiRequest, {data: 'body'});

export default {
    get: async (url: string, params?: {}, config?: AxiosRequestConfig) => reMapping(await apiService.get(url, params, config)),
    delete: async (url: string, data?: {}, config?: AxiosRequestConfig) => reMapping(await apiService.delete(url, {}, {...config, data: data})),
    post: async (url: string, data?: {}, config?: AxiosRequestConfig) => reMapping(await apiService.post(url, data, config)),
    put: async (url: string, data?: {}, config?: AxiosRequestConfig) => reMapping(await apiService.put(url, data, config)),
};

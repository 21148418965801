import {useEffect, useCallback, useMemo} from 'react';
import {Row, Col, Container} from 'library/styled-bs-grid';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {getDistrictOptions, getCountyOptions} from 'config/address';
import {isEmpty} from 'utils/equal';
import FormHorizontalGroup from 'components/forms/FormHorizontalGroup';

// Components
import TextField from 'components/forms/TextField';
import Select from 'components/forms/Select';
import Card from 'components/atoms/Card';
import Button from 'components/atoms/Button';
import Select2 from 'components/forms/Select2';
// import FilePreviewField from 'components/forms/FilePreviewField';
import FileField from 'components/forms/FileField';


// Stores
import {actions as memberUserActions} from 'store/memberUser';
import {selector as authSelector} from 'store/auth';
import {IFormData} from './types';


/**
 * 會員管理/基本資料
 * 參考: https://ericssonr.nsdbytes.com/setting
 */
const Profile = () => {
    const params = useParams<{id: string}>();
    const history = useHistory();
    const currentId = Number(params.id);
    const isCreate = params.id === 'create';
    const baseRoutePath = '/member/user';

    const dispatch = useDispatch();

    const {handleSubmit, control, reset, watch} = useForm<IFormData>();


    const storeId = useSelector(authSelector.storeId);
    const {currentData, settingData} = useSelector(state => ({
        currentData: state.memberUser.currentData,
        settingData: state.memberUser.settingData,
    }));


    useEffect(() => {
        if(!isCreate){
            dispatch(memberUserActions.fetchCurrent({id: currentId}));
        }

        dispatch(memberUserActions.fetchSetting());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     * 設定預設值
     */
    useEffect(() => {

        if(isCreate){
            reset();

        }else{
            reset({
                fullName: currentData?.fullName ?? '',
                // firstName: currentData?.firstName ?? '',
                // lastName: currentData?.lastName ?? '',
                sex: currentData?.sex ?? 'F',
                email: currentData?.email ?? '',
                address: currentData?.address ?? '',
                phone: currentData?.phone ?? '',
                storeId: currentData?.storeId,

                idCard1: undefined,
                idCard2: undefined,
                proofOfAddress: undefined,
                avatar: undefined,
            });
        }


        // eslint-disable-next-line
    }, [currentData]);



    /**
     * MEMO 店
     */
    const memoStores = useMemo(() => {

        const data = settingData?.stores?.map(row => {
            return {text: row.name, value: String(row.id)};
        }) || [];

        return [{text: 'Select Store', value: ''}, ...data];

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingData?.stores.length]);


    /**
     * 送出表單
     * @param data
     */
    const handleSubmitHandler: SubmitHandler<IFormData> = useCallback(formData => {

        if(isCreate){
            dispatch(memberUserActions.createData({data: {
                ...formData,
                storeId: storeId ?? formData.storeId
            }}));
        }else{

            dispatch(memberUserActions.updateData({id: currentId, data: {
                ...formData,
                storeId: storeId ?? formData.storeId
            }}));

        }



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const handleDeleteImage = (imageName: string) => {
        dispatch(memberUserActions.deleteImage({id: currentId, imageName}));
    };

    return (
        <Container fluid>
            <Card
                title="BASIC INFORMATION"
                direction="column"
            >
                <form onSubmit={handleSubmit(handleSubmitHandler)}>
                    <FormHorizontalGroup label="Name">
                        <Controller
                            control={control}
                            name="fullName"
                            defaultValue=""
                            render={({onChange, value}) => {
                                return (<TextField
                                    onChange={onChange}
                                    value={value}
                                />);
                            }}
                        />
                    </FormHorizontalGroup>

                    {/*<FormHorizontalGroup label="Last Name">*/}
                    {/*    <Controller*/}
                    {/*        control={control}*/}
                    {/*        name="lastName"*/}
                    {/*        defaultValue=""*/}
                    {/*        render={({onChange, value}) => {*/}
                    {/*            return (<TextField*/}
                    {/*                onChange={onChange}*/}
                    {/*                value={value}*/}
                    {/*            />);*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*</FormHorizontalGroup>*/}

                    <FormHorizontalGroup label="Sex">
                        <Controller
                            control={control}
                            name="sex"
                            defaultValue=""
                            render={({onChange, value}) => {
                                return (<Select
                                    onChange={onChange}
                                    value={value}
                                    options={[
                                        {text: 'Please Select', value: ''},
                                        {text: 'Female', value: 'F'},
                                        {text: 'Male', value: 'M'}
                                    ]}
                                />);
                            }}
                        />
                    </FormHorizontalGroup>

                    <FormHorizontalGroup label="Phone">
                        <Controller
                            control={control}
                            name="phone"
                            defaultValue=""
                            render={({onChange, value}) => {
                                return (<TextField
                                    onChange={onChange}
                                    value={value}
                                    beforeIconCode="mobile"
                                />);
                            }}
                        />
                    </FormHorizontalGroup>

                    <FormHorizontalGroup label="Address">
                        <Controller
                            control={control}
                            name="address"
                            defaultValue=""
                            render={({onChange, value}) => {
                                return (<TextField
                                    onChange={onChange}
                                    value={value}
                                />);
                            }}
                        />
                    </FormHorizontalGroup>

                    <FormHorizontalGroup label="Email">
                        <Controller
                            control={control}
                            name="email"
                            defaultValue=""
                            render={({onChange, value}) => {
                                return (<TextField
                                    onChange={onChange}
                                    value={value}
                                />);
                            }}
                        />
                    </FormHorizontalGroup>


                    {isEmpty(storeId) && (
                        <FormHorizontalGroup label="In Store">
                            <Controller
                                control={control}
                                name="storeId"
                                defaultValue=""
                                render={({onChange, value}) => {
                                    return (<Select2
                                        onChange={onChange}
                                        value={value}
                                        options={memoStores}
                                    />);
                                }}

                            />
                        </FormHorizontalGroup>
                    )}




                    <FormHorizontalGroup label="ID Card">
                        <Controller
                            control={control}
                            name="idCard1"
                            as={<FileField
                                label="ID Card 1"
                                accept={['image/jpeg', 'image/png', 'application/pdf']}
                                currentFileUrl={isCreate ? '':currentData?.idCard1Url}
                                onDelete={() => handleDeleteImage('idCard1')}
                            />}
                        />
                    </FormHorizontalGroup>

                    <FormHorizontalGroup>
                        <Controller
                            control={control}
                            name="idCard2"
                            as={<FileField
                                label="ID Card 2"
                                accept={['image/jpeg', 'image/png', 'application/pdf']}
                                currentFileUrl={isCreate ? '':currentData?.idCard2Url}
                                onDelete={() => handleDeleteImage('idCard2')}
                            />}
                        />
                    </FormHorizontalGroup>

                    <FormHorizontalGroup>
                        <Controller
                            control={control}
                            name="idCard3"
                            as={<FileField
                                label="ID Card 3"
                                accept={['image/jpeg', 'image/png', 'application/pdf']}
                                currentFileUrl={isCreate ? '':currentData?.idCard3Url}
                                onDelete={() => handleDeleteImage('idCard3')}
                            />}
                        />
                    </FormHorizontalGroup>

                    <FormHorizontalGroup>
                        <Controller
                            control={control}
                            name="idCard4"
                            as={<FileField
                                label="ID Card 4"
                                accept={['image/jpeg', 'image/png', 'application/pdf']}
                                currentFileUrl={isCreate ? '':currentData?.idCard4Url}
                                onDelete={() => handleDeleteImage('idCard4')}
                            />}
                        />
                    </FormHorizontalGroup>
                    {/*<FormHorizontalGroup label="Proof Of Address">*/}
                    {/*    <Controller*/}
                    {/*        control={control}*/}
                    {/*        name="proofOfAddress"*/}
                    {/*        as={<FilePreviewField*/}
                    {/*            style={{*/}
                    {/*                width: '410px',*/}
                    {/*                height: '200px',*/}
                    {/*            }}*/}
                    {/*            exampleSize={{*/}
                    {/*                width: 480,*/}
                    {/*                height: 480,*/}
                    {/*            }}*/}
                    {/*            maxFileSize={100}*/}
                    {/*            accept={['image/jpeg']}*/}
                    {/*            currentImageUrl={isCreate ? '':currentData?.proofOfAddressUrl}/>}*/}
                    {/*    />*/}
                    {/*</FormHorizontalGroup>*/}


                    {/*<FormHorizontalGroup label="Avatar">*/}
                    {/*    <Controller*/}
                    {/*        control={control}*/}
                    {/*        name="avatar"*/}
                    {/*        as={<FilePreviewField*/}
                    {/*            style={{*/}
                    {/*                width: '200px',*/}
                    {/*                height: '200px',*/}
                    {/*            }}*/}
                    {/*            exampleSize={{*/}
                    {/*                width: 480,*/}
                    {/*                height: 480,*/}
                    {/*            }}*/}
                    {/*            maxFileSize={100}*/}
                    {/*            accept={['image/jpeg']}*/}
                    {/*            currentImageUrl={isCreate ? '':currentData?.avatarUrl}/>}*/}
                    {/*    />*/}
                    {/*</FormHorizontalGroup>*/}


                    <FormHorizontalGroup>
                        <Button type="submit" color="primary" className="mr-2">Save</Button>
                        <Button type="button" color="gray" onClick={() => history.push(baseRoutePath)}>Back</Button>
                    </FormHorizontalGroup>

                </form>
            </Card>
        </Container>
    );
};

export default Profile;

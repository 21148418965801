import {history} from 'library/react-router';
import configureStore from 'library/redux/configureStore';
import get from 'lodash/get';

// Configs & Data
import gridConfig from 'config/grid';
import {getSiteConfig} from 'utils/config';
import {persistKey, persistVersion} from 'config/app';
import {version} from '../../../package.json';
import {ESiteEnv, TSiteCode} from 'config/types';

const {theme, blackLang} = getSiteConfig();


/**
 * 取得持久化預設Redux狀態
 */
function getPreloadedState(){
    // 持久化資料回存到 Redux (若版本不同則重設)
    let initialState: {
        language?: { locale: string }
    } = {};
    if (persistVersion !== window.localStorage.getItem(`${persistKey}-version`)) {
        window.localStorage.setItem(`${persistKey}-version`, persistVersion);
        window.localStorage.setItem(persistKey, '{}');
    } else {
        initialState = JSON.parse(window.localStorage.getItem(persistKey) || '{}');

        // 檢查語系(若非正式語系則清除預設值)
        const locale = get(initialState, 'language.locale');
        if (locale && !blackLang.includes(locale)) {
            delete initialState.language;
        }
    }

    return initialState;
}

function getSetupParam(){
    console.log('info: ', JSON.stringify({
        nodeEnv: process.env.NODE_ENV,
        siteEnv: (window as any).siteEnv as ESiteEnv,
        siteCode: (window as any).siteCode as TSiteCode,
        version: version,
    }));


    const preloadedState = getPreloadedState();
    const store = configureStore(preloadedState, history);

    return {store, history, gridConfig, theme};
}

export default getSetupParam;

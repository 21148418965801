import api from 'library/apisauce';

import {TGetSetting} from './types';


export default {
    /**
     * 取得系統設定
     */
    getSetting: (): Promise<TGetSetting> => {
        return api.get('/system/setting', {});
    },
};

import {useCallback} from 'react';
import styled, {css, keyframes} from 'styled-components/macro';
import {media, Row, Col, Container} from 'library/styled-bs-grid';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

// Components
import Icon from 'components/atoms/Icon';
import TextField from 'components/forms/TextField';
import Avatar from 'components/atoms/Avatar';
import DropDownList from 'components/atoms/DropDownList';

// Stores
import {actions as authActions, selector as authSelector} from 'store/auth';
import {IFormData} from './types';


interface IProps {
    isFixed?: boolean,
    onClickMenu?: () => void;
    isVisiblePanel?: boolean,
}


/**
 * HeaderRoot
 * @param className
 */
const LayoutHeader = ({
    isFixed = true,
    onClickMenu,
    isVisiblePanel = false,
}: IProps) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const {handleSubmit, control, reset} = useForm<IFormData>();

    const {avatarUrl, name, permissions, isMessageNotRead, isNoticeNotRead} = useSelector(state => ({
        avatarUrl: state.auth.avatarUrl,
        name: authSelector.payload(state)?.name ?? 'NoName',
        isMessageNotRead: !!state.message.notReadCount,
        isNoticeNotRead: !!state.rootNotice.notReadCount,
        permissions: authSelector.permissions(state),
    }));

    const handleSubmitHandler: SubmitHandler<IFormData> = useCallback(formData => {
        history.replace('/job', {model: formData.model});
        reset({
            model: '',
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <HeaderRoot
            className="no-print"
            isFixed={isFixed}
            isVisiblePanel={isVisiblePanel}
        >
            <HeaderContainer fluid>
                <Row className="align-items-center h-100">
                    <Col col className="d-none d-md-block">
                        {/*<form onSubmit={handleSubmit(handleSubmitHandler)}  className="d-flex">*/}
                        {/*    <Controller*/}
                        {/*        control={control}*/}
                        {/*        name="model"*/}
                        {/*        defaultValue=""*/}
                        {/*        render={({onChange, value}) => {*/}
                        {/*            return (<TextField*/}
                        {/*                onChange={onChange}*/}
                        {/*                value={value}*/}
                        {/*                placeholder="Find your product model"*/}
                        {/*                style={{*/}
                        {/*                    borderRight: 0,*/}
                        {/*                    borderTopRightRadius: 0,*/}
                        {/*                    borderBottomRightRadius: 0,*/}
                        {/*                }}*/}
                        {/*            />);*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*    <SearchButton>*/}
                        {/*        Search*/}
                        {/*    </SearchButton>*/}
                        {/*</form>*/}
                    </Col>

                    <Col col="auto" className="d-md-none">
                        <FeatureIconButton type="button" onClick={onClickMenu}>
                            <Icon code="align-center" size={24}/>
                        </FeatureIconButton>
                    </Col>

                    <Col className="d-flex flex-column align-items-end" col>
                        <Row>
                            <Col col="auto">
                                <NotificationUl>
                                    {/*<NotificationLi>*/}
                                    {/*    <DropDownList*/}
                                    {/*        menuTitle="Pages"*/}
                                    {/*        contentList={[*/}
                                    {/*            {*/}
                                    {/*                text: '會員批貨平台',*/}
                                    {/*                iconCode: 'bear-smile-line',*/}
                                    {/*                onClick: () => window.open('https://kr8851.com'),*/}
                                    {/*            },*/}
                                    {/*            {*/}
                                    {/*                text: '爬蟲系統',*/}
                                    {/*                iconCode: 'spider-solid',*/}
                                    {/*                onClick: () => window.open('http://spider.kr8851.club:10080'),*/}
                                    {/*            },*/}
                                    {/*        ]}*/}
                                    {/*    />*/}
                                    {/*</NotificationLi>*/}

                                    {/* 語言切換 */}
                                    <NotificationLi className="d-none d-md-block">
                                        <DropDownList
                                            menuTitle={<Icon code="language" color="#7d8490"/>}
                                            isVisibleArrow={false}
                                            contentList={[
                                                {
                                                    text: 'ENGLISH', onClick: () => {},
                                                },
                                            ]}
                                        />
                                    </NotificationLi>


                                    {/* 系統通知未讀提醒 */}
                                    {permissions.includes('/root/notice') && (
                                        <NotificationLi>
                                            <Dropdown>
                                                <DropdownButton isNotice={isNoticeNotRead} onClick={()=>history.push('/root/notice')}>
                                                    <Icon code="bell" color="#7d8490"/>
                                                </DropdownButton>
                                            </Dropdown>
                                        </NotificationLi>
                                    )}

                                    {/* 會員訊息未讀提醒 */}
                                    {permissions.includes('/message') && (
                                        <NotificationLi>
                                            <Dropdown>
                                                <DropdownButton isNotice={isMessageNotRead} noticeColor="#007bff" onClick={()=>history.push('/message')}>
                                                    <Icon code="comment-lines" color="#7d8490"/>
                                                </DropdownButton>
                                            </Dropdown>
                                        </NotificationLi>
                                    )}
                                </NotificationUl>
                            </Col>

                            <Col col="auto">
                                <DropDownList
                                    isVisibleArrow={false}
                                    direction="right"
                                    menuTitle={
                                        <AvatarButton isActive>
                                            <AvatarImage image={avatarUrl} size={30}/>
                                            <span className="d-none d-md-inline-block">
                                                {name}
                                            </span>
                                            {/*<AvatarNotice isActive/>*/}
                                        </AvatarButton>
                                    }
                                    contentList={[
                                        {
                                            text: 'Change Password',
                                            iconCode: 'locked',
                                            onClick: () => history.push('/profile/me/password'),
                                        },
                                        {
                                            text: 'Logout',
                                            iconCode: 'sign-out',
                                            onClick: () => dispatch(authActions.logout()),
                                        },
                                    ]}
                                />

                            </Col>
                        </Row>




                    </Col>
                </Row>


            </HeaderContainer>
        </HeaderRoot>

    );
};

export default LayoutHeader;


const FeatureIconButton = styled.button`

    > i{
       color: hsla(0,0%,100%,.9);
    }

    :disabled{
        opacity: .2;
    }
`;


const SearchButton = styled.button`
    background: 0 0;
    border: 1px solid #343a40;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color: #6c757d;
    font-size: 14px;
    height: 35px;
    padding: 0 15px;

    display: flex;
    justify-content: center;
    align-items: center;
`;


const NoticeKeyFrame = keyframes`
    0% {
        box-shadow: 0 0 0 0 rgba(67,74,84,.7);
    }
    70% {
        box-shadow: 0 0 0 10px transparent;
    }
    100% {
        box-shadow: 0 0 0 0 transparent;
    }
`;

const AvatarImage = styled(Avatar)`
  border: none;
  //transform: translateX(-6px);
  position: absolute;
  left: 0;
`;


const AvatarButton = styled.div<{
    isActive?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  //flex-direction: column;
  position: relative;
  //width: 28px;
  height: 30px;
  padding-left: 0;
  padding-right: 0;
  min-width: 30px;

  border-radius: 1rem;
  font-weight: 500;
  background-color: #e8e9e9;
  color: #6e7687;
  font-size: 12px;


  ${media.md`
      padding-left: 40px;
      padding-right: 15px;
  `}


`;

const DropdownButton = styled.button<{
    isNotice?: boolean;
    noticeColor?: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 28px;
  height: 28px;
  color: #80868e;
  font-size: 14px;

  &:hover{

      &:before{
          background-color: rgba(33,33,33,.1);
          opacity: 1;
          transform: scale(1);
      }
  }

  :after {
       content: "";
       border-radius: 100%;
       height: 10px;
       width: 10px;
       background-color: ${props => props.noticeColor ?? '#28a745'};
       position: absolute;
       bottom: 13px;
       right: 2px;
       top: 2px;
       opacity: .98;
       display: none;
       z-index: 2;

       transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

      box-shadow: 0 0 0 rgba(67,74,84,.9);
      animation: ${NoticeKeyFrame} 2s infinite;

  }

  ${props => props.isNotice && css`
      :after{
          display: block;
      }
  `}
`;


const Dropdown = styled.div`

`;

const NotificationLi = styled.li`
  line-height: 12px;
  padding: 0 3px;
`;

const NotificationUl = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 23px;
  border-width: 0 1px;
  border-color: rgba(33,33,33,.14);
  border-style: solid;

  margin-bottom: 0;
  //padding: 0 12px;
`;



const HeaderContainer = styled(Container)`
  height: inherit;
  border-bottom: 1px solid #343a40;
`;

const HeaderRoot = styled.div<{
    isHold?: boolean,
    isFixed?: boolean,
    isVisiblePanel?: boolean,
}>`
    //position: relative;
    //display: flex;
    //
    z-index: 800;
    background: #2b3035;
    //background-color: #fff;


    //align-items: center;
    //justify-content: space-between;
    //
    //padding: 0 15px;
    //width: 100%;
    //border-bottom: 1px solid rgba(0,0,0,.07);
    height: 56px;

    ${props => props.isFixed && css`
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        user-select: none;
    `}


    ${media.md`
       padding: 0 20px 0 80px;
       height: 76px;

    `}

    ${media.xl`
      padding-left: 80px;

       ${(props: any) => props.isVisiblePanel && css`
            //transform: translate3d(280px,0,0);
          padding-left: 300px;

        `}
    `}
`;

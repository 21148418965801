import {useEffect, useCallback} from 'react';
import styled from 'styled-components/macro';
import dayjs from 'dayjs';
import {Container} from 'library/styled-bs-grid';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {objFilterNotTrue2Array} from 'utils/format';

// Components
import {CardTable as Table} from 'components/atoms/Table';
import PageHeader from 'components/organisms/PageHeader';
import Label from 'components/atoms/Label';
import ListFeatureGroup from 'components/organisms/ListFeatureGroup';

// Stores
import {actions as rootRoleActions} from 'store/rootRole';
import {IFormData} from './types';


/**
 * 權限角色管理/列表
 */
const List = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const tableForm = useForm<{checkedId: {
            [key: string]: boolean
        }}>();
    const searchForm = useForm<IFormData>();

    const baseRoutePath = '/root/role';

    const {paginateData, paginateMeta, isFetching} = useSelector(state => ({
        paginateData: state.rootRole.paginateData,
        paginateMeta: state.rootRole.paginateMeta,
        isFetching: state.rootRole.isFetching,
    }));


    useEffect(() => {
        handleFetchPaginate(1, 50);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     * 查詢分頁
     */
    const handleFetchPaginate = useCallback((currentPage?: number, pageLimit?: number) => {
        // 取得查詢項目
        const {keyword} = searchForm.getValues();

        dispatch(rootRoleActions.fetchPaginate({
            currentPage,
            pageLimit,
            keyword,
        }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     * 刪除多筆資料
     */
    const handleDeleteRows = () => {
        // 取得 Table中的 CheckId
        // const {checkedId} = tableForm.getValues({nest: true});
        const {checkedId} = tableForm.getValues();
        const ids = objFilterNotTrue2Array(checkedId);

        if(ids.length === 0){
            alert('請選擇項目');
        }
        dispatch(rootRoleActions.deleteData({ids}));
    };


    /**
     * 刪除一筆資料
     */
    const handleDeleteRow = (id: number) => {
        dispatch(rootRoleActions.deleteData({ids: [id]}));
    };


    /**
     * 送出8查詢表單
     */
    // const handleSubmitHandler: SubmitHandler<IFormData> = useCallback(formData => {
    //     handleFetchPaginate(1);
    //
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    return (
        <PageRoot>

            <PageHeader
                breadcrumbData={[
                    {text: 'Root Role'},
                    {text: 'Root RoleManage'},
                ]}
                tabsData={[
                    {text: 'List', href: '/root/role'},
                ]}
            />

            <ListFeatureGroup data={[
                {text: 'Add', onClick: () => history.push(`${baseRoutePath}/create`), color: 'primary'},
                {text: 'Delete', onClick: handleDeleteRows, color: 'danger'},
            ]}/>

            {/* 內容 */}
            <Container fluid>

                {/* 表格資料 */}
                <Table
                    isFetching={isFetching}
                    hookForm={tableForm}
                    title={[
                        {text: 'Name',     field: 'name'       , col: true},
                        {text: 'Create',   field: 'createdAt',width: 110},
                        {text: 'Status',      field: 'isEnable', dataAlign: 'center',width: 80},
                    ]}
                    data={paginateData.map(row => {
                        return {
                            id: row.id,
                            name: row.name,
                            isEnable: <Label status={row.isEnable ? 'success':'gray'}>{row.isEnable ? '啟用':'停用'}</Label>,
                            createdAt: <div style={{fontSize: 12}}>
                                {dayjs(row.createdAt).format('YYYY-MM-DD')}<br/>
                                {dayjs(row.createdAt).format('HH:mm:ss')}
                            </div>
                        };
                    })}
                    onEditRow={id => history.push(`/root/role/${id}`)}
                    onDeleteRow={id => handleDeleteRow(id)}
                    onChangePage={(page, limit) => handleFetchPaginate(page, limit)}
                    meta={paginateMeta}
                />
            </Container>



            {/*<Col col className="d-flex justify-content-end">*/}
            {/*    <ActionButton type="button" onClick={event => {*/}
            {/*    }}>*/}
            {/*        <Icon code="file-plus"/>*/}
            {/*    </ActionButton>*/}
            {/*    <ActionButton type="button" onClick={handleDeleteRows}>*/}
            {/*        <Icon code="trash"/>*/}
            {/*    </ActionButton>*/}
            {/*</Col>*/}

        </PageRoot>
    );
};

export default List;


const PageRoot = styled.div`
    padding: 0 20px;
`;

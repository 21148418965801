import {useEffect, useCallback, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {Container} from 'library/styled-bs-grid';
import {isEmpty} from 'utils/equal';

// Components
import TextField from 'components/forms/TextField';
import Card from 'components/atoms/Card';
import FormHorizontalGroup from 'components/forms/FormHorizontalGroup';
import Select from 'components/forms/Select';
import Button from 'components/atoms/Button';
import PasswordField from 'components/forms/PasswordField';

// Stores
import {actions as rootUserActions} from 'store/rootUser';
import {IFormData} from './types';
import {selector as authSelector} from '../../../../../../store/auth';
import FilePreviewField from '../../../../../../components/forms/FilePreviewField';



/**
 * 管理者管理/編輯
 *
 * 參考: https://ericssonr.nsdbytes.com/setting
 */
const Account = () => {
    const params = useParams<{id: string}>();
    const currentId = Number(params.id);
    const isCreate = params.id === 'create';

    const dispatch = useDispatch();

    const {handleSubmit, control, reset} = useForm<IFormData>();


    const {currentData, settingData} = useSelector(state => ({
        currentData: state.rootUser.currentData,
        settingData: state.rootUser.settingData,
    }));
    const storeId = useSelector(authSelector.storeId);


    useEffect(() => {
        if(!isCreate){
            dispatch(rootUserActions.fetchCurrent({id: currentId}));
        }
        dispatch(rootUserActions.fetchSetting());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    /**
     * 設定預設值
     */
    useEffect(() => {
        if(isCreate){
            reset();
        }else{
            reset({
                name: currentData?.name || '',
                email: currentData?.email || '',
                roleId: currentData?.role?.id || undefined,
                isEnable: currentData?.isEnable === true ? 'true': 'false',
                storeId: currentData?.storeId,

                avatar: undefined,
            });
        }

        // eslint-disable-next-line
    }, [currentData]);


    /**
     * MEMO 角色
     */
    const memoRoles = useMemo(() => {
        const data = settingData?.roles?.map(row => {
            return {text: row.name, value: String(row.id)};
        }) || [];

        return [{text: 'Please Select', value: ''}, ...data];

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingData?.roles]);



    /**
     * MEMO 店
     */
    const memoStores = useMemo(() => {
        const data = settingData?.stores?.map(row => {
            return {text: row.name, value: String(row.id)};
        }) || [];

        return [{text: 'Please Select', value: ''}, ...data];

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingData?.stores]);


    /**
     * 送出表單
     * @param data
     */
    const handleSubmitHandler: SubmitHandler<IFormData> = useCallback(formData => {
        if(isCreate){
            dispatch(rootUserActions.createData({data: {
                ...formData,
                isEnable: formData.isEnable === 'true',
                storeId: storeId ?? formData.storeId,
            }}));
        }else{
            dispatch(rootUserActions.updateData({id: currentId, data: {
                ...formData,
                isEnable: formData.isEnable === 'true',
                storeId: storeId ?? formData.storeId,
            }}));
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container fluid>
            <Card
                title="Account"
                direction="column"
            >
                <form onSubmit={handleSubmit(handleSubmitHandler)}>
                    <FormHorizontalGroup label="Account">
                        {isCreate ?
                            <Controller
                                control={control}
                                name="account"
                                defaultValue=""
                                render={({onChange, value}) => {
                                    return (<TextField
                                        onChange={onChange}
                                        value={value}
                                    />);
                                }}
                            />:
                            currentData?.account
                        }
                    </FormHorizontalGroup>

                    {isCreate && (<>

                        <FormHorizontalGroup label="New Password">
                            <Controller
                                control={control}
                                name="password"
                                defaultValue=""
                                render={({onChange, value}) => {
                                    return (<PasswordField
                                        onChange={onChange}
                                        value={value}
                                        placeholder="Password"
                                    />);
                                }}
                            />
                        </FormHorizontalGroup>

                        <FormHorizontalGroup label="Confirm Password">
                            <Controller
                                control={control}
                                name="confirmPassword"
                                defaultValue=""
                                render={({onChange, value}) => {
                                    return (<PasswordField
                                        onChange={onChange}
                                        value={value}
                                        placeholder="Password"
                                    />);
                                }}
                            />
                        </FormHorizontalGroup>
                    </>)}


                    <FormHorizontalGroup label="Name">
                        <Controller
                            control={control}
                            name="name"
                            defaultValue=""
                            render={({onChange, value}) => {
                                return (<TextField
                                    onChange={onChange}
                                    value={value}
                                />);
                            }}
                        />
                    </FormHorizontalGroup>

                    <FormHorizontalGroup label="Email">
                        <Controller
                            control={control}
                            name="email"
                            defaultValue=""
                            render={({onChange, value}) => {
                                return (<TextField
                                    onChange={onChange}
                                    value={value}
                                />);
                            }}
                        />
                    </FormHorizontalGroup>


                    <FormHorizontalGroup label="Role">
                        <Controller
                            control={control}
                            name="roleId"
                            defaultValue=""
                            render={({onChange, value}) => {
                                return (<Select
                                    onChange={onChange}
                                    value={value}
                                    options={memoRoles}
                                />);
                            }}
                        />
                    </FormHorizontalGroup>


                    {isEmpty(storeId) && (
                        <FormHorizontalGroup label="Store">
                            <Controller
                                control={control}
                                name="storeId"
                                defaultValue=""
                                render={({onChange, value}) => {
                                    return (<Select
                                        onChange={onChange}
                                        value={value}
                                        options={memoStores}
                                    />);
                                }}
                            />
                        </FormHorizontalGroup>
                    )}


                    <FormHorizontalGroup label="Avatar">
                        <Controller
                            control={control}
                            name="avatar"
                            defaultValue=""
                            as={<FilePreviewField
                                style={{
                                    width: '200px',
                                    height: '200px',
                                }}
                                exampleSize={{
                                    width: 480,
                                    height: 480,
                                }}
                                maxFileSize={100}
                                accept={['image/jpeg']}
                                currentImageUrl={!isCreate ? currentData?.avatarUrl: ''}/>}
                        />
                    </FormHorizontalGroup>

                    <FormHorizontalGroup label="Enable">
                        <Controller
                            control={control}
                            name="isEnable"
                            defaultValue=""
                            render={({onChange, value}) => {
                                return (<Select
                                    onChange={onChange}
                                    value={value}
                                    options={[
                                        {text: 'Disable', value: 'false'},
                                        {text: 'Enable', value: 'true'},
                                    ]}
                                />);
                            }}

                        />
                    </FormHorizontalGroup>

                    <FormHorizontalGroup>
                        <Button type="submit" color="primary">Save</Button>
                    </FormHorizontalGroup>
                </form>

            </Card>
        </Container>
    );
};

export default Account;

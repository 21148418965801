import {useCallback, useEffect, useMemo} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components/macro';
import {Container} from 'library/styled-bs-grid';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import dayjs from 'dayjs';
import {isEmpty} from 'utils/equal';

// Components
import Card from 'components/atoms/Card';
import Button from 'components/atoms/Button';
import FormHorizontalGroup from 'components/forms/FormHorizontalGroup';
import PageHeader from 'components/organisms/PageHeader';
import TextField from 'components/forms/TextField';
import Select2 from 'components/forms/Select2';

// Stores
import {actions as storeActions} from 'store/store';

import {IFormData} from './types';

/**
 * 廣告/最新消息/編輯
 */
const Edit = () => {
    const history = useHistory();
    const params = useParams<{id: string}>();
    const currentId = params.id;
    const isCreate = params.id === 'create';
    const baseRoutePath = '/store';

    const dispatch = useDispatch();

    const {handleSubmit, control, reset} = useForm<IFormData>();

    const {currentData, settingData} = useSelector(state => ({
        currentData: state.store.currentData,
        settingData: state.store.settingData,
    }));

    useEffect(() => {
        if(isCreate){
            dispatch(storeActions.resetCurrent());
        }else{
            dispatch(storeActions.fetchCurrent({id: Number(currentId)}));
        }

        if(!isCreate){
            dispatch(storeActions.fetchSetting({storeId: Number(currentId)}));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * 設定預設值
     */
    useEffect(() => {
        if(isCreate){
            reset();

        }else{
            reset({
                name: currentData?.name || '',
                phone: currentData?.phone || '',
                addressDetail: currentData?.addressDetail || '',
                addressState: currentData?.addressState || '',
                adminUserId: currentData?.adminUserId || undefined,
            });

        }
        // eslint-disable-next-line
    }, [currentData]);


    /**
     * MEMO 店長
     */
    const memoAdminUsers = useMemo(() => {

        const data = settingData?.adminUsers?.map(row => {
            return {text: `#${row.id} ${row.name}`, value: String(row.id)};
        }) || [];

        return [{text: 'Select Admin', value: ''}, ...data];

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingData?.adminUsers?.length]);




    /**
     * 送出表單
     * @param data
     */
    const handleSubmitHandler: SubmitHandler<IFormData> = useCallback(formData => {
        if(isCreate){
            // 新增
            dispatch(storeActions.createData({data: formData}));

        }else{
            // 編輯
            dispatch(storeActions.updateData({id: Number(currentId), data: formData}));

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageRoot>
            <PageHeader
                breadcrumbData={[
                    {text: 'Store Manage', href: baseRoutePath},
                    {text: 'Profile'},
                ]}
                tabsData={[
                    {text: isCreate ? 'Add':'Edit', href: `${baseRoutePath}/${currentId}`},
                ]}
            />

            {/* 內容 */}
            <Container fluid>
                <Card direction="column">
                    <form onSubmit={handleSubmit(handleSubmitHandler)}>

                        <FormHorizontalGroup label="Name">
                            <Controller
                                control={control}
                                name="name"
                                defaultValue=""
                                render={({onChange, value}) => {
                                    return (<TextField
                                        onChange={onChange}
                                        value={value}
                                    />);
                                }}
                            />
                        </FormHorizontalGroup>

                        <FormHorizontalGroup label="Phone">
                            <Controller
                                control={control}
                                name="phone"
                                defaultValue=""
                                render={({onChange, value}) => {
                                    return (<TextField
                                        onChange={onChange}
                                        value={value}
                                        beforeIconCode="mobile"
                                    />);
                                }}
                            />
                        </FormHorizontalGroup>

                        <FormHorizontalGroup label="Address State">
                            <Controller
                                control={control}
                                name="addressState"
                                defaultValue=""
                                render={({onChange, value}) => {
                                    return (<TextField
                                        onChange={onChange}
                                        value={value}
                                    />);
                                }}
                            />
                        </FormHorizontalGroup>

                        <FormHorizontalGroup label="Address Detail">
                            <Controller
                                control={control}
                                name="addressDetail"
                                defaultValue=""
                                render={({onChange, value}) => {
                                    return (<TextField
                                        onChange={onChange}
                                        value={value}
                                    />);
                                }}
                            />
                        </FormHorizontalGroup>



                        <FormHorizontalGroup label="Admin">

                            {isCreate ? 'You need to add it again and add Root to the store to specify who is the store manager' : (
                                <Controller
                                    control={control}
                                    name="adminUserId"
                                    defaultValue=""
                                    render={({onChange, value}) => {
                                        return (<Select2
                                            onChange={onChange}
                                            value={value}
                                            options={memoAdminUsers}
                                        />);
                                    }}
                                />
                            )}
                        </FormHorizontalGroup>



                        <FormHorizontalGroup>
                            <Button type="submit" color="primary" className="mr-2">{isCreate ? 'Create': 'Save'}</Button>
                            <Button type="button" color="gray" onClick={() => history.push(baseRoutePath)}>{isCreate ? 'Cancel': 'Back'}</Button>
                        </FormHorizontalGroup>

                    </form>
                </Card>
            </Container>

        </PageRoot>
    );
};

export default Edit;


const PageRoot = styled.div`
    padding: 0 20px;
`;

import {useEffect, useCallback} from 'react';
import styled from 'styled-components/macro';
import dayjs from 'dayjs';
import {Container} from 'library/styled-bs-grid';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {SubmitHandler, useForm} from 'react-hook-form';
import {objFilterNotTrue2Array, toBoolean, toNumber} from 'utils/format';
import {formatCurrency} from 'utils/number';
import {transferOptions} from 'config/common';

// Components
import {CardTable as Table} from 'components/atoms/Table';
import PageHeader from 'components/organisms/PageHeader';
import ListFeatureGroup from 'components/organisms/ListFeatureGroup';
import Button from 'components/atoms/Button';
import Label from 'components/atoms/Label';
import RemarkModal from './RemarkModal';

// Stores
import {actions as jobActions} from 'store/job';
import SearchCard from './SearchCard';
import {IFormData} from './types';


/**
 * 廣告/最新消息/列表
 */
const List = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const tableForm = useForm<{checkedId: {
            [key: string]: boolean
        }}>();
    const searchForm = useForm<IFormData>();

    const baseRoutePath = '/job';

    const {paginateData, paginateMeta, paginateSum, isFetching} = useSelector(state => ({
        paginateData: state.job.paginateData,
        paginateMeta: state.job.paginateMeta,
        paginateSum: state.job.paginateSum,
        isFetching: state.job.isFetching,
    }));


    useEffect(() => {
        dispatch(jobActions.fetchSetting());
        handleFetchPaginate(1, 50);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     * 查詢分頁
     */
    const handleFetchPaginate = useCallback((currentPage?: number, pageLimit?: number) => {
        // 取得查詢項目
        const searchData = searchForm.getValues();

        dispatch(jobActions.fetchPaginate({
            currentPage,
            pageLimit,

            ...searchData,
            memberUserId: toNumber(searchData.memberUserId),
            storeId: toNumber(searchData.storeId),
            isCompleted: toBoolean(searchData.isCompleted),
            isLodged: toBoolean(searchData.isLodged),
        }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     * 刪除多筆資料
     */
    const handleDeleteRows = () => {
        // 取得 Table中的 CheckId
        // const {checkedId} = tableForm.getValues({nest: true});
        const {checkedId} = tableForm.getValues();
        const ids = objFilterNotTrue2Array(checkedId);

        if(ids.length === 0){
            alert('請選擇項目');
        }else{
            dispatch(jobActions.deleteData({ids}));
        }
    };


    /**
     * 送出8查詢表單
     */
    const handleSubmitHandler: SubmitHandler<IFormData> = formData => {
        handleFetchPaginate(1);
    };


    /**
     * 刪除一筆資料
     */
    const handleDeleteRow = (id: number) => {
        dispatch(jobActions.deleteData({ids: [id]}));
    };


    /**
     * 開啟 光箱視窗進入編輯 備註欄位
     */
    const handleVisibleRemark = useCallback((id: number, paidToMember: number, isLodged: boolean, isCompleted) => {
        dispatch(jobActions.setVisibleRemarkModal({
            isVisible: true,
            data: {
                id,
                paidToMember,
                isLodged,
                isCompleted,
            },
        }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <PageRoot>

                <PageHeader
                    breadcrumbData={[
                        {text: 'Job Manage'},
                    ]}
                    tabsData={[
                        {text: 'List', href: baseRoutePath},
                    ]}
                />

                <ListFeatureGroup data={[
                    {text: 'Add', onClick: () => history.push(`${baseRoutePath}/create`), color: 'primary'},
                    {text: 'Delete', onClick: handleDeleteRows, color: 'danger'},
                ]}/>

                {/* 搜尋列 */}
                <SearchCard
                    hookForm={searchForm}
                    handleSubmitHandler={handleSubmitHandler}
                />

                {/* 內容 */}
                <Container fluid>

                    {/* 表格資料 */}
                    <Table
                        bodyHeight={80}
                        isFetching={isFetching}
                        hookForm={tableForm}
                        title={[
                            {text: 'Id',        field: 'key', col: true},
                            {text: 'Paid To Customer',        field: 'paidToMember', titleAlign: 'right', dataAlign: 'right', width: 110},
                            {text: 'Buy',        field: 'sold', titleAlign: 'right', dataAlign: 'right', width: 110},
                            {text: 'rate',        field: 'rate', titleAlign: 'right', dataAlign: 'right', width: 90},
                            {text: 'Sold',        field: 'buy', titleAlign: 'right', dataAlign: 'right', width: 110},
                            {text: 'User',        field: 'rootUser', width: 130},
                            // {text: 'Customer',        field: 'memberUser', col: true},
                            {text: 'Lodged',    field: 'isLodged'    , width: 80},
                            {text: 'Completed',    field: 'isCompleted'    , width: 110},
                            {text: 'Created',    field: 'createdAt'    , width: 100},

                        ]}
                        data={paginateData?.map(row => {
                            return {
                                id: row.id,
                                key: <div className="text-ellipsis-parent">
                                    #{row.id}<br/>
                                    {row.date}<br/>
                                    <div className="text-multiline-ellipsis">{row.memberUser?.fullName}</div>
                                </div>,
                                rate: row.rate,
                                paidToMember: <div className="text-right">
                                    <Button color="primary" onClick={() => handleVisibleRemark(row.id, row.paidToMember, row.isLodged, row.isCompleted)}>$ {formatCurrency(row.paidToMember)}</Button>
                                </div>,
                                sold: <div className="text-right">
                                    {transferOptions.find(opRow => opRow.id === row.transferOption)?.name ?? ''}<br/>
                                    {row.buyCurrency}<br/>
                                    $ {formatCurrency(row.buyAmount)}

                                </div>,

                                buy: <div className="text-right">
                                    {transferOptions.find(opRow => opRow.id === row.outboundOption)?.name ?? ''}<br/>
                                    {row.soldCurrency}<br/>
                                    $ {formatCurrency(row.soldAmount)}

                                </div>,
                                option: transferOptions.find(opRow => opRow.id === row.transferOption)?.name ?? '',
                                isHandleFee: row.isHandleFee,
                                rootUser: row.rootUser?.name,
                                // memberUser: row.memberUser?.fullName,
                                isLodged: <Button color={row.isLodged ? 'success':'gray'} onClick={() => handleVisibleRemark(row.id, row.paidToMember, row.isLodged, row.isCompleted)}>{row.isLodged ? 'Yes':'No'}</Button>,
                                isCompleted: <Button color={row.isCompleted ? 'success':'gray'} onClick={() => handleVisibleRemark(row.id, row.paidToMember, row.isLodged, row.isCompleted)}>{row.isCompleted ? 'Yes':'No'}</Button>,

                                createdAt: <div style={{fontSize: 12}}>
                                    {dayjs(row.createdAt).format('YYYY-MM-DD')}<br/>
                                    {dayjs(row.createdAt).format('HH:mm:ss')}
                                </div>,
                                updatedAt: <div style={{fontSize: 12}}>
                                    {dayjs(row.updatedAt).format('YYYY-MM-DD')}<br/>
                                    {dayjs(row.updatedAt).format('HH:mm:ss')}
                                </div>,
                            };
                        })}
                        onEditRow={(id, isOpen) => {
                            if(isOpen){
                                window.open(`${baseRoutePath}/${id}`);
                            }else{
                                history.push(`${baseRoutePath}/${id}`);
                            }
                        }}
                        onDeleteRow={id => handleDeleteRow(id)}
                        onChangePage={(page, limit) => handleFetchPaginate(page, limit)}
                        meta={paginateMeta}
                    />
                </Container>

                <div className="d-flex flex-row" style={{width: 500, color: '#fff', fontSize: 12}}>
                    <div className="m-4">
                        <Label status="success">Total</Label>
                    </div>

                    <div className="m-4">
                        BUY
                        {paginateSum?.groupSold.map(row => {
                            return (
                                <div>{row.currency} ${formatCurrency(row.totalAmount)}</div>
                            );
                        })}
                    </div>

                    <div className="m-4">
                        SOLD

                        {paginateSum?.groupBuy.map(row => {
                            return (
                                <div>{row.currency} ${formatCurrency(row.totalAmount)}</div>
                            );
                        })}
                    </div>
                </div>


            </PageRoot>

            <RemarkModal/>
        </>
    );
};

export default List;


const PageRoot = styled.div`
    padding: 0 20px;
`;

import {useEffect, useCallback} from 'react';
import styled from 'styled-components/macro';
import dayjs from 'dayjs';
import {Container} from 'library/styled-bs-grid';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';

// Components
import {CardTable as Table} from 'components/atoms/Table';
import PageHeader from 'components/organisms/PageHeader';

// Stores
import {actions as settingSiteActions} from 'store/settingSite';


/**
 * 網站設定/網站參數/列表
 */
const List = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const tableForm = useForm<{checkedId: {
            [key: string]: boolean
        }}>();
    const baseRoutePath = '/setting/site';

    const {paginateData, paginateMeta, isFetching} = useSelector(state => ({
        paginateData: state.settingSite.paginateData,
        paginateMeta: state.settingSite.paginateMeta,
        isFetching: state.settingSite.isFetching,
    }));


    useEffect(() => {
        handleFetchPaginate(1, 50);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     * 查詢分頁
     */
    const handleFetchPaginate = useCallback((currentPage?: number, pageLimit?: number) => {

        dispatch(settingSiteActions.fetchPaginate({
            currentPage,
            pageLimit,
        }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <PageRoot>

            <PageHeader
                breadcrumbData={[
                    {text: 'Site Setting'},
                    {text: 'Site Params'},
                ]}
                tabsData={[
                    {text: 'List', href: baseRoutePath},
                ]}
            />


            {/* 內容 */}
            <Container fluid>

                {/* 表格資料 */}
                <Table
                    isFetching={isFetching}
                    hookForm={tableForm}
                    title={[
                        {text: 'Group',      field: 'group', width: 100},
                        {text: 'Code',      field: 'code', width: 100},
                        {text: 'Name',      field: 'name', width: 100},
                        {text: 'Value',      field: 'value', col: true},
                        {text: 'Updated', field: 'updatedAt' , width: 100},
                    ]}
                    data={paginateData.map(row => {
                        return {
                            id: row.id,
                            group: row.group,
                            code: row.code,
                            name: row.name,
                            value: row.value,
                            updatedAt: <div style={{fontSize: 12}}>
                                {dayjs(row.updatedAt).format('YYYY-MM-DD')}<br/>
                                {dayjs(row.updatedAt).format('HH:mm:ss')}
                            </div>,
                        };
                    })}
                    isEnableChecked={false}
                    onEditRow={(id, isOpen) => {
                        if(isOpen){
                            window.open(`${baseRoutePath}/${id}`);
                        }else{
                            history.push(`${baseRoutePath}/${id}`);
                        }
                    }}
                    onChangePage={(page, limit) => handleFetchPaginate(page, limit)}
                    meta={paginateMeta}
                />
            </Container>


        </PageRoot>
    );
};

export default List;


const PageRoot = styled.div`
    padding: 0 20px;
`;

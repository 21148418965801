import {useEffect, useCallback} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components/macro';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {Container} from 'library/styled-bs-grid';
import {isEmpty} from 'utils/equal';

// Components
import TextField from 'components/forms/TextField';
import Card from 'components/atoms/Card';
import FormHorizontalGroup from 'components/forms/FormHorizontalGroup';
import Checkbox from 'components/forms/Checkbox';
import Button from 'components/atoms/Button';
import PageHeader from 'components/organisms/PageHeader';
import Table from 'components/atoms/Table';
import SwitchControl from 'components/forms/SwitchControl';

// Stores
import {actions as rootRoleActions} from 'store/rootRole';
import {IFormData} from './types';
import {objFilterNotTrue2Array} from 'utils/format';



/**
 * 權限角色管理/編輯
 *
 * 參考: https://ericssonr.nsdbytes.com/setting
 */
const Edit = () => {
    const params = useParams<{id: string}>();
    const currentId = Number(params.id);
    const isCreate = params.id === 'create';
    const baseRoutePath = '/root/role';

    const dispatch = useDispatch();

    const {handleSubmit, setValue, getValues, control, reset} = useForm<IFormData>();


    const {currentData, settingData} = useSelector(state => ({
        currentData: state.rootRole.currentData,
        settingData: state.rootRole.settingData,
    }));


    useEffect(() => {
        dispatch(rootRoleActions.fetchSetting());

        if(isCreate){
            dispatch(rootRoleActions.resetCurrent());
        }else{
            dispatch(rootRoleActions.fetchCurrent({id: Number(currentId)}));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);






    /**
     * 設定預設值
     */
    useEffect(() => {
        const permissions: {
            [key: string]: boolean
        } = {};


        if(currentData?.permissions){
            for(const row of currentData?.permissions){
                permissions[`id_${row.id}`] = true;
            }
        }

        if(isCreate){
            reset();
        }else{
            reset({
                name: currentData?.name ?? '',
                isEnable: currentData?.isEnable || false,
                allowCheckId: permissions,
            });
        }


        // eslint-disable-next-line
    }, [currentData]);


    /**
     * 全選控制
     */
    const handleCheckedAll = useCallback((isChecked: boolean) => {
        const keys = getValues();
        for(const key of Object.keys(keys.allowCheckId)){
            setValue(`allowCheckId[${key}]`, isChecked);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    /**
     * 送出表單
     * @param data
     */
    const handleSubmitHandler: SubmitHandler<IFormData> = useCallback(formData => {
        const allowCheckId = objFilterNotTrue2Array(formData.allowCheckId);

        if(isCreate){
            dispatch(rootRoleActions.createData({
                data: {
                    ...formData,
                    allowCheckId,
                }}));
        }else{
            dispatch(rootRoleActions.updateData({
                id: currentId,
                data: {
                    ...formData,
                    allowCheckId,
                }}));
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageRoot>
            <PageHeader
                breadcrumbData={[
                    {text: 'Root Role'},
                    {text: 'Root Role Manage', href: baseRoutePath},
                ]}
                tabsData={[
                    {text: isCreate ? 'Add':'Edit', href: `${baseRoutePath}/${currentId}`},
                ]}
            />

            <Container fluid>
                <Card
                    title="Edit"
                    direction="column"
                >
                    <form onSubmit={handleSubmit(handleSubmitHandler)}>

                        <FormHorizontalGroup label="名稱">
                            <Controller
                                control={control}
                                name="name"
                                defaultValue=""
                                render={({onChange, value}) => {
                                    return (<TextField
                                        onChange={onChange}
                                        value={value}
                                    />);
                                }}
                            />
                        </FormHorizontalGroup>


                        <FormHorizontalGroup label="允許功能" dataDirection="column">
                            <Table
                                title={[
                                    {text: '#',        field: 'index'         , width: 50},
                                    {text: '功能名稱',     field: 'name'       , col: true},
                                    {text: <Controller
                                        name="checkedAll"
                                        control={control}
                                        render={({onChange, value}) => {
                                            return <Checkbox checked={value} onChange={isChecked =>{
                                                onChange(isChecked);
                                                handleCheckedAll(isChecked);
                                            }}/>;
                                        }}
                                    />,
                                    field: 'allow'  , width: 50},
                                ]}
                                data={settingData?.permissions ? settingData?.permissions.map((row, index) => {
                                    return {
                                        id: row.id,
                                        index: index + 1,
                                        name: row.name,
                                        // allow: <Checkbox checked={memoAllowIds?.includes(row.id)}/> ,
                                        allow: <Controller
                                            control={control}
                                            name={`allowCheckId[id_${row.id}]`}
                                            defaultValue={false}
                                            render={({onChange, value}) => {
                                                return <Checkbox value={String(row.id)} checked={value} onChange={onChange}/>;
                                            }}
                                        /> ,
                                    };
                                }) : []}
                                isEnableChecked={false}
                            />

                        </FormHorizontalGroup>

                        <FormHorizontalGroup label="啟用">
                            <Controller
                                control={control}
                                name="isEnable"
                                render={({onChange, value}) => {
                                    return <SwitchControl checked={value} onChange={onChange}/>;
                                }}
                            />
                        </FormHorizontalGroup>



                        <FormHorizontalGroup>
                            <Button type="submit" color="primary">Save</Button>
                        </FormHorizontalGroup>
                    </form>

                </Card>
            </Container>
        </PageRoot>
    );
};

export default Edit;


const PageRoot = styled.div`
    padding: 0 20px;
`;

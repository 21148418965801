export default class HttpException extends Error {
    constructor(
        message: string|undefined,
        public code?: string|undefined,
        public formMessage?: unknown,
    ) {
        super(message);
        this.name = 'HttpException';
    }
}

import {useParams} from 'react-router-dom';
import styled from 'styled-components/macro';
import Route from './Route';

// Components
import PageHeader from 'components/organisms/PageHeader';


/**
 * 管理者管理/明細
 *
 */
const Detail = () => {
    const baseRoutePath = '/profile';

    return (
        <PageRoot>
            <PageHeader
                breadcrumbData={[
                    {text: 'Profile'},
                    {text: 'Profile Manage'},
                ]}
                tabsData={[
                    {text: 'Password', href: `${baseRoutePath}/me/password`},
                ]}
            />

            {/* 內容 */}
            <Route/>

        </PageRoot>

    );
};

export default Detail;


const PageRoot = styled.div`
    padding: 0 20px;
`;


/* eslint-disable */
/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `src/resources/lang`)
 */

import LanguageProvider from './LanguageProvider';

export default LanguageProvider;

import {all, call, delay, put, select, takeLatest, takeEvery} from 'redux-saga/effects';
import {IPickAction} from 'library/redux';
import dayjs from 'dayjs';
import {fetchDataDelayMinMs} from 'config/app';

// Stores
import {actions as uiDialogActions} from 'store/uiDialog';
import {actions} from './reducer';
import {IActionCreators} from './types';

// Services
import ApiService, {TGetPaginateRes, TGetDetailRes, TGetCategoryOutline} from 'services/message';




/**
 * 取列表資料
 */
function* fetchPaginate(action: IPickAction<IActionCreators, 'fetchPaginate'>) {
    yield put(actions.fetchPaginateBegin());

    const {payload} = action;

    const currentMeta = yield select(state => state.message.paginateMeta);
    const currentPage = payload?.currentPage ?? currentMeta.currentPage;
    const pageLimit = payload?.pageLimit ?? currentMeta.pageLimit;

    try {

        const [{body}]: [TGetPaginateRes] = yield all([
            call(ApiService.getPaginate, currentPage, pageLimit),
            delay(fetchDataDelayMinMs),
        ]);

        const {data: {rows, meta}} = body;

        yield put(actions.fetchPaginateSuccess({rows, meta}));

    } catch (err) {
        yield put(actions.fetchPaginateFail({message: err.message}));
        yield put(uiDialogActions.openError({message: err.message, code: err.code}));

    }
}




/**
 * 取明細資料
 */
function* fetchCurrent(action: IPickAction<IActionCreators, 'fetchCurrent'>) {
    yield put(actions.fetchCurrentBegin());

    const {categoryId, memberId} = action.payload;

    const {payload} = action;

    const currentMeta = yield select(state => state.message.currentMeta);
    const currentPage = payload?.currentPage ?? currentMeta.currentPage;


    try {

        const [{body}]: [TGetDetailRes] = yield all([
            call(ApiService.getDetail, currentPage, memberId, categoryId),
            delay(fetchDataDelayMinMs),
        ]);


        const {data: {rows, meta, memberUser}} = body;

        yield put(actions.setNotReadCount({count: body.data.notReadCount}));
        yield put(actions.fetchCurrentSuccess({rows, meta, memberUser}));

    } catch (err) {
        yield put(actions.fetchCurrentFail({message: err.message}));
        yield put(uiDialogActions.openError({message: err.message, code: err.code}));

    }
}



/**
 * 取明細資料
 */
function* fetchCategoryOutline(action: IPickAction<IActionCreators, 'fetchCategoryOutline'>) {
    yield put(actions.fetchCategoryOutlineBegin());

    const {memberId} = action.payload;

    try {
        const [{body}]: [TGetCategoryOutline] = yield all([
            call(ApiService.getCategoryOutline, memberId),
            delay(fetchDataDelayMinMs),
        ]);

        yield put(actions.fetchCategoryOutlineSuccess({data: body.data.rows}));

    } catch (err) {
        yield put(actions.fetchCategoryOutlineFail({message: err.message}));
        yield put(uiDialogActions.openError({message: err.message, code: err.code}));

    }
}



/**
 * 新增資料
 */
function* createData(action: IPickAction<IActionCreators, 'createData'>) {
    const {memberId, categoryId, data} = action.payload;

    const currentData = yield select(state => state.message.currentData);
    const nextIndex = currentData.length;

    yield put(actions.createDataBegin({
        data: {
            id: -1,
            content: data.content,
            isMemberPost: false,
            isRead: true,
            createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        }
    }));

    try {

        yield all([
            call(ApiService.createData, memberId, categoryId, data),
            delay(fetchDataDelayMinMs),
        ]);

        yield put(actions.createDataSuccess({currentIndex: nextIndex}));

        // 重取
        // yield put(actions.fetchCurrent({currentPage: 1, pageLimit: 50, memberId, categoryId}));

    } catch (err) {
        yield put(actions.createDataFail({message: err.message}));
        yield put(uiDialogActions.openError({message: err.message, code: err.code}));

    }
}




export default [
    takeLatest(actions.fetchPaginate.type, fetchPaginate),
    takeLatest(actions.fetchCurrent.type, fetchCurrent),

    takeLatest(actions.fetchCategoryOutline.type, fetchCategoryOutline),

    takeEvery(actions.createData.type, createData),
];

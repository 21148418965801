import {useParams} from 'react-router-dom';
import styled from 'styled-components/macro';
import Route from './Route';

// Components
import PageHeader from 'components/organisms/PageHeader';


/**
 * 會員管理/明細
 */
const Detail = () => {
    const params = useParams<{ id: string }>();
    const currentId = params.id;
    const baseRoutePath = '/member/user';

    const isCreate = params.id === 'create';
    return (
        <PageRoot>
            <PageHeader
                breadcrumbData={[
                    {text: 'Customer Manage', href: baseRoutePath},
                    {text: 'Profile'},
                ]}
                tabsData={[
                    {text: 'View', href: `${baseRoutePath}/${currentId}`},
                    {text: 'Bank Account', href: `${baseRoutePath}/${currentId}/balance`, isVisible: !isCreate},
                ]}
            />

            {/* 內容 */}
            <Route/>

        </PageRoot>

    );
};

export default Detail;


const PageRoot = styled.div`
    padding: 0 20px;
`;


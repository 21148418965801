import {IItem, AfterType} from '../types';

/**
 * 主要
 */
export const extraData: IItem[] = [
    // {
    //     text: '報表系統',
    //     after: {type: AfterType.icon, code: 'analytics'},
    //     path: '/report',
    //     children: [
    //         {
    //             text: '已配統計',
    //             path: '/report/assignGoods',
    //             after: {type: AfterType.text, code: 'ag'},
    //         },
    //         {
    //             text: '收支報表',
    //             path: '/report/incomeExpense',
    //             after: {type: AfterType.text, code: 'ie'},
    //         },
    //         {
    //             text: '會員訂單金額統計',
    //             path: '/report/memberOrderAmount',
    //             after: {type: AfterType.text, code: 'ro'},
    //         },
    //     ]
    // },

];

export const adminData: IItem[] = [
    {
        text: 'Root User Manage',
        path: '/root/user',
        after: {type: AfterType.icon, code: 'user-astronaut'},
    },
    {
        text: 'Root Role Mange',
        path: '/root/role',
        after: {type: AfterType.icon, code: 'control'},
    }
];

export const featureData: IItem[] = [
    {
        text: 'Dashboard',
        path: '/dashboard',
        after: {type: AfterType.icon, code: 'home', color: '#fbbd08'},
    },
    // {
    //     text: 'SiteSetting',
    //     after: {type: AfterType.icon, code: 'desktop'},
    //     path: '/site',
    //     children: [
    //         {
    //             text: 'Params Setting',
    //             path: '/setting/site',
    //             after: {type: AfterType.text, code: 'ss'},
    //         },
    //     ]
    // },

    {
        text: 'Customer',
        after: {type: AfterType.icon, code: 'member', color: '#007bff'},
        path: '/member/user',
    },

    {
        text: 'Store',
        after: {type: AfterType.icon, code: 'store', color: '#ff6655'},
        path: '/store',
    },
    {
        text: 'Job',
        after: {type: AfterType.icon, code: 'job', color: '#80ff67'},
        path: '/job',
    },

    {
        text: 'Report',
        after: {type: AfterType.icon, code: 'analytics'},
        path: '/report',
    },


];

import {all, call, delay, put, select, takeLatest} from 'redux-saga/effects';
import {IPickAction} from 'library/redux';
import {fetchDataDelayMinMs} from 'config/app';

// Stores
import {actions} from './reducer';
import {actions as uiDialogActions} from 'store/uiDialog';
import {actions as uiBlockActions} from 'store/uiBlock';
import {IActionCreators} from './types';

// Services
import ApiService, {TGetPaginateRes, TGetDetailRes} from 'services/settingSite';




/**
 * 取列表資料
 */
function* fetchPaginate(action: IPickAction<IActionCreators, 'fetchPaginate'>) {
    yield put(actions.fetchPaginateBegin());

    const {payload} = action;

    const currentMeta = yield select(state => state.settingSite.paginateMeta);
    const currentPage = payload?.currentPage ?? currentMeta.currentPage;
    const pageLimit = payload?.pageLimit ?? currentMeta.pageLimit;

    try {
        const [{body}]: [TGetPaginateRes] = yield all([
            call(ApiService.getPaginate, currentPage, pageLimit),
            delay(fetchDataDelayMinMs),
        ]);

        const {data: {rows, meta}} = body;

        yield put(actions.fetchPaginateSuccess({rows, meta}));

    } catch (err) {
        yield put(actions.fetchPaginateFail({message: err.message}));
        yield put(uiDialogActions.openError({message: err.message, code: err.code}));

    }
}





/**
 * 取明細資料
 */
function* fetchCurrent(action: IPickAction<IActionCreators, 'fetchCurrent'>) {
    yield put(actions.fetchCurrentBegin());

    const {id} = action.payload;

    try {
        const [{body}]: [TGetDetailRes] = yield all([
            call(ApiService.getDetail, id),
            delay(fetchDataDelayMinMs),
        ]);

        yield put(actions.fetchCurrentSuccess({data: body.data}));

    } catch (err) {
        yield put(actions.fetchCurrentFail({message: err.message}));
        yield put(uiDialogActions.openError({message: err.message, code: err.code}));

    }
}


/**
 * 更新資料
 */
function* updateData(action: IPickAction<IActionCreators, 'updateData'>) {
    yield put(actions.updateDataBegin());
    yield put(uiBlockActions.visible());

    const {id, data} = action.payload;

    try {
        yield all([
            call(ApiService.updateData, id, data),
            delay(fetchDataDelayMinMs),
        ]);

        yield put(actions.updateDataSuccess());

    } catch (err) {
        yield put(actions.updateDataFail({message: err.message}));
        yield put(uiDialogActions.openError({message: err.message, code: err.code}));

    } finally {
        yield put(uiBlockActions.hidden());
    }
}




export default [
    takeLatest(actions.fetchPaginate.type, fetchPaginate),
    takeLatest(actions.fetchCurrent.type, fetchCurrent),
    takeLatest(actions.updateData.type, updateData),

];

import api from 'library/apisauce';

import {TGetPaginateRes, TGetDetailRes, TCreateDataRes, TUpdateDataRes, TDeleteDataRes, TUpdatePasswordRes, TGetSettingsRes} from './types';

/**
 * 管理者/資料管理
 */
export default {
    /**
     * 查詢 列表資料
     */
    getPaginate: (currentPage: number, pageLimit: number, keyword = ''): Promise<TGetPaginateRes> => {
        return api.get('/root/role', {currentPage, pageLimit, keyword}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 查詢 設定資料
     */
    getSettings: (): Promise<TGetSettingsRes> => {
        return api.get('/root/role/setting', {}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 查詢 明細資料
     */
    getDetail: (id: number): Promise<TGetDetailRes> => {
        return api.get(`/root/role/${id}`, {}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 新增 資料
     */
    createData: (data: {
        name: string,
        isEnable: boolean,
    }): Promise<TCreateDataRes> => {
        return api.post('/root/role', data, {
            headers: {isAuth: true},
        });
    },
    /**
     * 更新 資料
     */
    updateData: (id: number, data: {
        name: string,
        isEnable: boolean,
    }): Promise<TUpdateDataRes> => {
        return api.put(`/root/role/${id}`, data, {
            headers: {isAuth: true},
        });
    },


    /**
     * 更新 密碼
     */
    updatePassword: (id: number, data: {
        password: string,
        confirmPassword: string,
    }): Promise<TUpdatePasswordRes> => {
        return api.put(`/root/role/${id}/password`, data, {
            headers: {isAuth: true},
        });
    },

    /**
     * 刪除
     */
    deleteData: (ids: number[]): Promise<TDeleteDataRes> => {
        return api.delete('/root/role', {ids}, {
            headers: {isAuth: true},
        });
    },

};

import {fork, take, cancel} from 'redux-saga/effects';
import rootSaga from 'store/rootSaga';


/** -----------------------------------------
 Connect Types To Sagas
 /** --------------------------------------*/
// const rootSaga = [sagas];

const CANCEL_SAGAS_HMR = 'CANCEL_SAGAS_HMR';

const createAbortableSaga = (saga: any) => {
    const isDev = process.env.NODE_ENV !== 'production';

    if (isDev) {
        return function* main() {
            const sagaTask = yield fork(saga);
            yield take(CANCEL_SAGAS_HMR);
            yield cancel(sagaTask);
        };
    }
    return saga;
};

export default {
    runSagas(sagaMiddleware: any) {
        sagaMiddleware.run(createAbortableSaga(rootSaga));
    },
    cancelSagas(store: any) {
        store.dispatch({type: CANCEL_SAGAS_HMR});
    }
};

import {routerMiddleware} from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import {History} from 'history';
import merge from 'lodash/merge';
import createApiMiddleware from 'library/apisauce/createApiMiddleware';
import sagaManager from './sagaManager';
import rootReducer from 'store/rootReducer';
import rootInitialState from 'store/rootInitialState';


export default function configureAppStore(preloadedState = {}, history: History) {
    const isDev = process.env.NODE_ENV !== 'production';
    const sagaMiddleware = createSagaMiddleware();

    const middlewares = [sagaMiddleware, routerMiddleware(history)];
    const enhancers = undefined;


    const store = configureStore({
        reducer: rootReducer,
        middleware: [...getDefaultMiddleware({serializableCheck: false}), ...middlewares],
        preloadedState: merge(rootInitialState, preloadedState),
        enhancers,
        devTools: isDev,
    });

    // init api middleware
    createApiMiddleware(store.getState, store.dispatch);

    // install Saga
    sagaManager.runSagas(sagaMiddleware);

    if (isDev && module.hot) {
        module.hot.accept('store/rootReducer', () => store.replaceReducer(rootReducer));

        module.hot.accept('store/rootSaga', () => {
            sagaManager.cancelSagas(store);
            const nextSagas = require('./sagaManager');
            nextSagas.runSagas(sagaMiddleware);
        });
    }

    return store;
}


import {MutableRefObject, useCallback, useEffect, useMemo, useRef} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import styled, {css, keyframes} from 'styled-components/macro';
import {Container, Col, Row} from 'library/styled-bs-grid';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import dayjs from 'dayjs';
import {asset} from 'config/app';

// Components
import Card from 'components/atoms/Card';
import Button from 'components/atoms/Button';
import PageHeader from 'components/organisms/PageHeader';
import TextField from 'components/forms/TextField';
import Avatar from 'components/atoms/Avatar';
import Icon from 'components/atoms/Icon';

// Stores
import {actions as messageActions} from 'store/message';
import {actions as uiImageActions} from 'store/uiImage';

import {IFormData} from './types';

/**
 * 商品系統/類別管理/編輯
 */
const Edit = () => {
    const history = useHistory();
    const messageRef = useRef() as MutableRefObject<HTMLDivElement>;
    const params = useParams<{memberId: string, categoryId: string}>();
    const memberId = params.memberId;
    const categoryId = params.categoryId;
    const isCreate = params.memberId === 'create';
    const baseRoutePath = '/message';
    // let currentHeight = 0;

    const dispatch = useDispatch();

    const {handleSubmit, control, reset} = useForm<IFormData>();

    const {categoryOutlineData, currentData, currentMemberUser} = useSelector(state => ({
        categoryOutlineData: state.message.categoryOutlineData,

        // currentMeta: state.message.currentMeta,
        currentData: state.message.currentData,
        currentMemberUser: state.message.currentMemberUser,
    }));

    useEffect(() => {
        dispatch(messageActions.fetchCategoryOutline({memberId: Number(memberId)}));

        if(categoryId){
            handleFetchPaginate(1, 50);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryId, memberId]);


    useEffect(() => {
        goBottom();
        // messageRef.current.scrollTop = messageRef.current.scrollHeight;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageRef, currentData]);


    const memoMessage = useMemo(() => {
        let newDate = dayjs('2000-01-01');

        return currentData.map(row => {
            const createdAtObj = dayjs(row.createdAt);
            let isVisibleDiffDate = false;
            if(createdAtObj.diff(newDate, 'day')){
                isVisibleDiffDate = true;
                newDate = createdAtObj;
            }
            // console.log('newDate', newDate);
            const isToday = createdAtObj.isSame(dayjs(), 'day');
            let image = null;
            if(row.imageUrl){
                image = <MessageImage style={{backgroundImage: `url("${row.imageUrl}")`}} onClick={() => dispatch(uiImageActions.visible({imageUrl: row.imageUrl || ''}))}/>;
            }

            return (<>
                {isVisibleDiffDate && (<DiffDate>{isToday ? '今天' : createdAtObj.format('YYYY/MM/DD')}</DiffDate>)}


                <div className="d-flex flex-row">
                    {!row.isMemberPost && (
                        <div className="d-flex flex-column justify-content-center align-items-end flex-grow-1 mr-2">
                            {row?.isSubmitting && (<Icon code="redo" isRotateAnimation color="rgba(168, 213, 128,.3)" size={20}/>)}
                        </div>
                    )}
                    <MessageMemberGroup key={row.id} isRootPost={!row.isMemberPost}>
                        <Avatar image={asset(row.isMemberPost ? currentMemberUser?.avatarUrl: '/images/logo.png')} size={30}/>
                        <MemberInfo>
                            <MessageMemberInfoGroup>
                                <MessageMemberName>{row.isMemberPost ? currentMemberUser?.realName : 'City Exchange'}</MessageMemberName>
                                <MessageDate>
                                    {createdAtObj.format('hh:ss')}
                                </MessageDate>
                            </MessageMemberInfoGroup>
                            <MessageContent>
                                {image}
                                {row.content}
                            </MessageContent>
                        </MemberInfo>
                    </MessageMemberGroup>
                </div>

            </>);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentData]);


    const goBottom = useCallback(() => {
        const currentScroll = messageRef.current.scrollTop + messageRef.current.clientHeight;
        const currentHeight = messageRef.current.scrollHeight;

        // 初始訊息不帶動畫
        if(messageRef.current.scrollTop === 0){
            messageRef.current.scrollTop = currentHeight;

        }else if (currentScroll < currentHeight) {
            window.requestAnimationFrame(goBottom);
            messageRef.current.scrollTop += 25;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageRef]);

    /**
     * 查詢分頁
     */
    const handleFetchPaginate = useCallback((currentPage?: number, pageLimit?: number) => {

        dispatch(messageActions.fetchCurrent({
            currentPage,
            pageLimit,

            memberId: Number(memberId),
            categoryId: Number(categoryId),
        }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memberId, categoryId]);


    /**
     * 送出表單
     * @param data
     */
    const handleSubmitHandler: SubmitHandler<IFormData> = useCallback(formData => {
        // 新增
        dispatch(messageActions.createData({
            memberId: Number(memberId),
            categoryId: Number(categoryId),
            data: {
                ...formData,
            }}));

        reset({content: ''});

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memberId, categoryId]);

    return (
        <PageRoot className="d-flex flex-column h-100">
            <PageHeader
                className="flex-grow-0"
                title="站內通知"
                breadcrumbData={[
                    {text: '站內通知', href: baseRoutePath},
                    {text: '會員訊息'},
                ]}
                tabsData={[
                    {text: isCreate ? '新增':'編輯', href: `${baseRoutePath}/${memberId}`},
                ]}
            />

            {/* 內容 */}
            <Container fluid className="flex-grow-1 h-100 overflow-y-hidden">
                <Row className="h-100">
                    <Col col="auto" className="h-100 pb-4">

                        {/* 訊息分類 */}
                        <Card direction="column" className="h-100 overflow-y-auto">
                            {categoryOutlineData.map(row => {
                                return (
                                    <CategoryCard
                                        isActive={categoryId===String(row.id)}
                                        onClick={() => history.replace(`/message/${memberId}/${row.id}`)}>

                                        <CategoryInfoGroup>
                                            <CategoryName style={{
                                                color: row.themeColor,
                                            }}>
                                                {row.name}
                                            </CategoryName>
                                            <CategoryLastMessage>
                                                {row.lastContent || 'No Message'}
                                            </CategoryLastMessage>
                                        </CategoryInfoGroup>

                                        {row.unreadCount > 0 && (
                                            <CategoryUnreadCount>
                                                {row.unreadCount}
                                            </CategoryUnreadCount>
                                        )}
                                    </CategoryCard>
                                );
                            })}
                        </Card>
                    </Col>
                    <Col col xl={15} className="h-100 pb-4">

                        {/* 訊息聊天區塊 */}
                        <Card direction="column" className="h-100 overflow-y-hidden" fluid>
                            <MemberGroup>
                                <Avatar image={currentMemberUser?.avatarUrl} size={48}/>
                                <MemberInfo>
                                    <MemberName>V{currentMemberUser?.id} {currentMemberUser?.realName}</MemberName>
                                    <MemberEmail>{currentMemberUser?.email}</MemberEmail>

                                </MemberInfo>
                            </MemberGroup>

                            <Message
                                ref={messageRef}
                                className="overflow-y-auto" style={{
                                    paddingTop: '90px',
                                    paddingBottom: '75px',
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                }}>
                                {memoMessage}

                            </Message>

                            <form onSubmit={handleSubmit(handleSubmitHandler)}>
                                <MessageGroup>
                                    <Controller
                                        control={control}
                                        name="content"
                                        defaultValue=""
                                        render={({onChange, value}) => {
                                            return (<TextField
                                                onChange={onChange}
                                                value={value}
                                                required
                                            />);
                                        }}
                                    />

                                    <SendButton type="submit" color="primary" className="ml-2">
                                        <Icon code="paper-plane" size={22}/>
                                    </SendButton>
                                </MessageGroup>
                            </form>


                        </Card>
                    </Col>
                </Row>


            </Container>

        </PageRoot>
    );
};

export default Edit;



const MessageImage = styled.div`
    width: 80px;
    height: 80px;
    background: #6c757d no-repeat center;
    background-size: 100%;
    display: block;
    cursor: pointer;
`;


const DiffDate = styled.div`
  color: #fff;
  font-size: 14px;
  width: 100%;
  text-align: center;
  position: relative;
  opacity: .1;
  margin-bottom: 10px;

  :before{
    opacity: .4;
    height: 1px;
    width: 100%;
    content: '';

    background-color: #6c757d;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    z-index: 1;
  }
`;

const MessageDate = styled.div`
    color: #6c757d;
    opacity: .8;
    font-size: 14px;
`;

const MessageMemberInfoGroup = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    justify-content: space-between;
    width: 100%;
`;

const MessageContent = styled.div`
    color: #6c757d;
`;

const MessageMemberName = styled.div`
    font-size: 16px;
    font-weight: bold;
`;

const MessageMemberGroup = styled.div<{
    isRootPost?: boolean;
}>`
  display: flex;
  flex-direction: row;
  background-color: #272c31;
  margin-bottom: 20px;
  padding: 10px;
  width: 80%;
  border-radius: 4px;

  ${props => props.isRootPost && css`
      margin-left: auto;
      background-color: rgba(168, 213, 128,.1);
      color: #218235;

      ${MessageContent}{
        color: rgba(255, 255,255, .6);
      }
  `};

`;

const Message = styled.div`

`;

const SendButton = styled(Button)`
  width: 35px;
  height: 35px;
`;


const MessageGroup = styled.div`
    display: flex;
   flex-direction: row;
   align-items: center;
   background-color: #272c31;
   position: absolute;
   width: 100%;
   left: 0;
   bottom: 0;
   z-index: 1;
   padding: 20px;
`;

const MemberName = styled.div`
    font-size: 20px;
    color: #c8cfd6;
    font-weight: bold;
`;


const MemberEmail = styled.div`
    font-size: 15px;
`;

const MemberInfo = styled.div`
   display: flex;
   flex-direction: column;
   margin-left: 8px;
   justify-content: center;
   flex: 1;
`;


const MemberGroup = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
   background-color: #272c31;
   position: absolute;
   width: 100%;
   left: 0;
   top: 0;
   z-index: 1;
   padding: 20px;
`;

const NoticeKeyFrame = keyframes`
    0% {
        box-shadow: 0 0 0 0 rgba(67,74,84,.7);
    }
    70% {
        box-shadow: 0 0 0 10px transparent;
    }
    100% {
        box-shadow: 0 0 0 0 transparent;
    }
`;


const CategoryUnreadCount = styled.div`
    border-radius: 99em;
    font-size: 12px;
    width: 20px;
    height: 20px;
    background-color: #28a745;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;

    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    box-shadow: 0 0 0 rgba(67,74,84,.9);

    animation: ${NoticeKeyFrame} 2s infinite;
`;



const CategoryLastMessage = styled.div`
    overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;

    width: 150px;


`;

const CategoryName = styled.div`
`;


const CategoryInfoGroup= styled.div`
  flex-direction: column;
   flex-wrap: wrap;
   align-items: center;
   justify-content: center;
`;

const CategoryCard = styled.div<{
    onClick?: () => void;
    isActive?: boolean,
}>`
   font-size: 15px;
   background-color: #272c31;
   padding: 0 20px;
   height: 62px;
   border-radius: 4px;
   margin-bottom: 10px;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   align-items: center;
   justify-content: space-between;
   cursor: pointer;
   //justify-content: center;
   transition: background-color .2s;

   ${props => props.isActive && css`
      background-color: rgba(168, 213, 128,.1);
      color: #c8cfd6;
  `}


  :hover{
     background-color: rgba(168, 213, 128,.1);
     color: #c8cfd6;
  }

`;


const PageRoot = styled.div`
    padding: 0 20px;
`;

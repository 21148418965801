import {useMemo} from 'react';
import styled from 'styled-components/macro';
import A from 'components/atoms/A';
import {FCProps} from 'types';


interface IProps extends FCProps{
    data: Array<{
        text: string, href?: string,
    }>,
    currentText?: string,
}

const Breadcrumbs = ({
    style,
    className,
    data = [],
    currentText,
}: IProps) => {

    const renderData = useMemo(() => {
        return data.map(row => {
            if(row.href){
                return (<NavItem href={row.href} key={row.text}>
                    {row.text}
                </NavItem>);
            }

            return (<NavItem as="h3" key={row.text}>
                {row.text}
            </NavItem>);
        });

    }, [data]);

    return (<BreadcrumbRoot className={className} style={style}>
        <PageTitle>{currentText}</PageTitle>
        <div>
            {renderData}

            {/*{currentText && (*/}
            {/*    <NavItem as="h3">*/}
            {/*        {currentText}*/}
            {/*    </NavItem>*/}
            {/*)}*/}
        </div>
    </BreadcrumbRoot>);
};


export default Breadcrumbs;



const NavItem = styled(A)`
    font-size: 12px;
    line-height: 1.42857143;
    display: inline-block;
    color: #818793;
    font-weight: 400;

    :not([href]), :hover {
        color: #6e7687;
    }

    :before{
        content: '/';
        padding: 0 7px;
        color: #6e7687;
    }

    :first-child{
        :before{
            content: none;
        }
    }

    //:last-child{
    //    color: #6e7687;
    //
    //    :before{
    //        color: #6e7687;
    //    }
    //}
`;


const PageTitle = styled.div`
    color: #c8cfd6;

    font-size: 15px;
    padding-top: 12px;
    margin-right: 20px;
    white-space: nowrap;
    font-weight: 700;
`;



const BreadcrumbRoot = styled.div`

`;

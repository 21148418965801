import {Fragment, ReactNode, useMemo} from 'react';
import styled, {css} from 'styled-components/macro';
import {Control, Controller} from 'react-hook-form';

// Components
import Icon from 'components/atoms/Icon';
import Checkbox from 'components/forms/Checkbox';
import {ItemUl, HeaderLi, ItemColumn, FormControl} from './Common';
import {isEmpty} from '../../../utils/equal';
import Button from '../Button';



interface IProps {
    hookFormControl?: Control
    title: Array<{
        field: string,
        text: string|number|ReactNode,
        col?: number|'auto'|true,
        width?: number,
        titleAlign?: 'left'|'center'|'right',
        dataAlign?: 'left'|'center'|'right',
        dataVertical?: 'top'|'center'|'bottom',
        className?: string,
        isSort?: boolean,
        sortBy?: 'DESC'|'ASC',
    }>,
    data: Array<{
        id: number,
        [field: string]: any,
        appendData?: any,
        onClickRow?: () => void,
    }>,
    isEnableChecked?: boolean,
    isVisibleActions: boolean;
    height?: number;
    isNonLine?: boolean;
    trColor?: string;
    onEditRow?: (id: number, isOpen: boolean) => void;
    onDeleteRow?: (id: number) => void;
}


/**
 * Table
 */
const TableBody = ({
    hookFormControl,
    data= [],
    title= [],
    isEnableChecked = true,
    onEditRow,
    onDeleteRow,
    isVisibleActions,
    height = 57,
    isNonLine = false,
    trColor,
}: IProps) => {


    const bodyData = useMemo(() => {

        return data.map(dataRow => {
            return (<Fragment
                key={`tbodyTr_${dataRow.id}`}
            >
                <ItemLi
                    as="li"
                    height={height}
                    isNonLine={isNonLine}
                    trColor={trColor}
                    isAppendData={!isEmpty(dataRow.appendData)}
                    onClick={dataRow.onClickRow}
                >

                    {/* Checkbox 選取功能 */}
                    {(isEnableChecked && hookFormControl) && (
                        <ItemColumn col="auto" style={{
                            width: 48,
                            flex: '0 0 48px'
                        }}>
                            <FormControl>
                                <Controller
                                    control={hookFormControl}
                                    name={`checkedId[id_${dataRow.id}]`}
                                    render={({onChange, value}) => {
                                        return <Checkbox value={String(dataRow.id)} checked={value} onChange={onChange}/>;
                                    }}
                                />
                            </FormControl>
                        </ItemColumn>
                    )}

                    {/* 各欄位值 */}
                    {title.map(titleRow => {

                        return (<ItemColumn
                            key={`tbodyTr_${dataRow.id}_${titleRow.field}`}
                            col={titleRow.col ?? 'auto'}
                            align={titleRow.dataAlign}
                            vertical={titleRow.dataVertical}
                            className={titleRow.className}
                            style={{
                                width: titleRow.width,
                                flex: `0 0 ${titleRow.width}px`
                            }}
                        >
                            {dataRow[titleRow.field] ?? ''}
                        </ItemColumn>);
                    })}

                    {isVisibleActions && (
                        <ItemColumn
                            col
                            align="right"
                            style={{
                                width: 100,
                                flex: '0 0 100px'
                            }}
                        >
                            <ActionGroup>
                                {/*編輯*/}
                                {onEditRow && (
                                    <EditButton onClick={(event: any) => {
                                        // metaKey: mac, ctrlKey: windows
                                        onEditRow(dataRow.id, event.metaKey || event.ctrlKey);
                                    }}>
                                        <Icon code="edit" color="#6435c9"/>
                                    </EditButton>
                                )}

                                {/*刪除*/}
                                {onDeleteRow && (
                                    <DeleteButton onClick={() => onDeleteRow(dataRow.id)}>
                                        <Icon code="trash" color="#dc3545"/>
                                    </DeleteButton>
                                )}
                            </ActionGroup>

                        </ItemColumn>)
                    }
                </ItemLi>

                {dataRow.appendData && (
                    <AppendLine col={24}>
                        {dataRow.appendData}
                    </AppendLine>
                )}

            </Fragment>);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (<InnerContent>
        <SplitView>
            <SplitList>
                <ItemUl>{bodyData}</ItemUl>
            </SplitList>
        </SplitView>
    </InnerContent>);
};

export default TableBody;


const AppendLine = styled(ItemColumn)`
    height: 34px;
    background-color: transparent;
    font-size: 13px;
    margin-bottom: 20px;
    z-index: 0;
`;


const ActionGroup = styled.div`
 display: flex;
 flex-direction: row;
 justify-content: flex-end;
`;

const ActionButton = styled(Button)`
  border-radius: 99em;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color .1s;
  margin-right: 5px;
  margin-left: 5px;

  >i {
      transition: color .1s;
  }
`;

const DeleteButton = styled(ActionButton)`
  > i{
     color: #f35958;
  }

  :hover{
     background: #f35958;

     > i{
        color: #fff;
     }
  }

`;

const EditButton = styled(ActionButton)`
  > i{
     color: #947eff;
  }

  :hover{
     background: #7a43fd;

     > i{
        color: #fff;
     }
  }

`;


const InnerContent = styled.div`
  height: 100%;
  margin-top: 0;
  padding: 0;
  //overflow: auto;
  flex: 1 1 100%;
  width: 100%;
`;




const ItemLi = styled(HeaderLi)<{
    height: number,
    isNonLine: boolean,
    isAppendData: boolean,
    trColor?: string,
    onClick?: () => void,
}>`
    //transition: background-color .1s, opacity .1s;
    border-collapse: collapse;
    border-top: 1px solid #343a40;
    border-bottom: none;
    height: ${props => props.height}px;
    opacity: .8;
    padding: 2px 0;
    z-index: 2;

    // ${props => typeof props.onClick !== 'undefined' && css`
    //     cursor: pointer;
    // `}


    :first-child {
        border-color: transparent;
    }

    :nth-of-type(2n) {
        background-color: rgba(0,0,0,.04);
    }

    ${props => !props.isNonLine && css`
        :hover{
            background-color: rgba(57, 57, 130, .1);
            color: #fff;
            opacity: 1;
        }
    `}


    &:last-child{
        border: none;
    }

    ${props => props.isNonLine && css`
        border-color: transparent;
        //background-color:rgba(57, 57, 130, .1);
        background-color: ${(props: any) => props.trColor ?? '#2b3035'};
        margin-bottom: 2px;

        :nth-of-type(2n), :hover {
            background-color: ${(props: any) => props.trColor ?? '#2b3035'};
        }


        ${props.isAppendData && css`
            margin-bottom: 0;
        `}
        //:hover{
        //  background-color: rgba(57, 57, 130, .1);
        //}
    `}

`;


const SplitList = styled.div`
    position: relative;
    height: 100%;
    //overflow-y: auto;
    transition: all .5s ease;
`;



const SplitView = styled.div`
    position: relative;
    height: 100%;
`;


import {createSlice} from '@reduxjs/toolkit';
import {IState, ISelector, IActionCreators} from './types';
import {TReducers} from 'library/redux';
import {getSiteConfig} from 'utils/config';

const site = getSiteConfig();

/** -----------------------------------------
 Store Key
 /** ---------------------------------------*/
export const name = 'language';


/** -----------------------------------------
 Initial State
 /** ---------------------------------------*/
export const initialState: IState = {
    locale: site.defaultLang,
};


/** -----------------------------------------
 Selectors
 /** --------------------------------------*/
export const selector: ISelector = {
    selectLanguage: (state) => {
        return state[name].locale;
    },
};



/** -----------------------------------------
 Reducers
 /** ---------------------------------------*/
const reducers: TReducers<IActionCreators, IState> = {
    setLocale: (state, {payload}) => {
        state.locale = payload.locale;
    },
};


export const {reducer, actions} = createSlice({name, initialState, reducers});

import {Col, Container, Row} from 'library/styled-bs-grid';
import {useLocation} from 'react-router-dom';

// Components
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import Tabs from 'components/atoms/Tabs';

import {FCProps} from 'types';


interface IProps extends FCProps {
    title?: string,
    breadcrumbData: Array<{ text: string, href?: string }>,
    tabsData: Array<{ text: string, href: string, isVisible?: boolean }>,
}

const PageHeader = ({
    style,
    className,
    title,
    breadcrumbData = [],
    tabsData = [],
}: IProps) => {
    const {pathname} = useLocation();

    const currentData = tabsData.find(row => row.href === pathname);

    return (<Container fluid className={className} style={style}>
        <Row noGutters style={{marginBottom: '15px'}}>
            <Col col>
                <Breadcrumbs data={breadcrumbData} currentText={title ?? currentData?.text}/>
            </Col>

            <Col col className="text-right">
                <Tabs data={tabsData} currentHref={currentData?.href}/>
            </Col>
        </Row>
    </Container>);
};


export default PageHeader;



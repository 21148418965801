import {useState, useCallback, useMemo} from 'react';
import styled, {css} from 'styled-components/macro';
import dayjs,{Dayjs} from 'dayjs';
import {FormattedMessage as I18N} from 'react-intl';
import {useI18n} from 'library/intl/hook';

// Components
import Icon from 'components/atoms/Icon';
import {isDate} from 'utils/equal';

const config = {
    weekDay: [1, 2, 3, 4, 5, 6, 7],
    month: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
};

const getLocaleWeekDay = () => config.weekDay.map((weekDate: number) => <I18N key={`calendarWeekDay-${weekDate}`} id={`com.calendar.weekDay.${weekDate}`} defaultMessage={`${weekDate}`}/>);

const getLocaleMonth = () => config.month.map((month: number) => (
    <I18N
        id={`com.calendar.month.${month}`}
        defaultMessage={`${month}`}
        key={`calendarLocaleMonth-${month}`}
        children={formatedMessage => <option value={month - 1} key={`month-${month}`}>{formatedMessage}</option>}
    />
));

/**
 * 取得 value的 Dayjs 物件
 * @param sourceDate
 * @returns {dayjs.Dayjs}
 */
const getConvertDayjs = (sourceDate: any) => dayjs(sourceDate);

interface IProps {
    value?: string;
    format?: string;
    onChange: (newDate: string) => void;
    isVisibleSetToday?: boolean;
}


/**
 * DatePicker
 * 日期選擇器
 *
 * @param props
 * @returns {*}
 */
const DatePicker = ({
    value,
    format = 'YYYY-MM-DD',
    onChange,
    isVisibleSetToday = false,
}: IProps) => {

    const {i18n} = useI18n();

    const today = useMemo(() => dayjs(), []);
    const [panelYearMonth, setPanelYearMonth] = useState<Dayjs>(value && isDate(value) ? dayjs(value) : today);

    const localeWeekDay = useMemo(() => getLocaleWeekDay(), []);
    const localeMonth = useMemo(() => getLocaleMonth(), []);

    /**
     * 處理選擇日期
     * @param year
     * @param month
     */
    const handleChangePanel = useCallback((year?: number, month?: number) => {
        let newPanelDate = panelYearMonth;
        if (typeof year !== 'undefined') {
            newPanelDate = newPanelDate.set('year', year);
        }
        if (typeof month !== 'undefined') {
            newPanelDate = newPanelDate.set('month', month);
        }

        setPanelYearMonth(newPanelDate);
    }, [panelYearMonth]);


    /**
     * 處理彈出提問年份
     */
    const handleConformYear = useCallback(() => {
        const currentYear = panelYearMonth.get('year');

        const localeText = i18n('com.calendar.pleaseInputYear', '请输入西元年');
        // @ts-ignore
        const newYear = parseInt(prompt(localeText, panelYearMonth.get('year')));
        if (newYear !== currentYear) {
            handleChangePanel(newYear, );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [panelYearMonth]);

    /**
     * 處理選擇日期
     * @param year
     * @param month
     * @param day
     */
    const handleSelectedDate = useCallback((year?: number, month?: number, day?: number) => {
        let newDate = panelYearMonth;
        if (year) {
            newDate = newDate.set('year', year);
        }

        if (month) {
            newDate = newDate.set('month', month);
        }

        if (day) {
            newDate = newDate.set('date', day);
        }

        const formatDate = newDate.format(format);
        onChange(formatDate);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [panelYearMonth]);

    /**
     * 設定為今天日期
     */
    const handleSelectedToday = useCallback(() => {
        const formatDate = today.format(format);

        setPanelYearMonth(today);
        onChange(formatDate);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * 產生年月
     * @returns {*}
     */
    const renderYearMonth = useCallback(() => {
        const panelPreYearMonth = panelYearMonth.subtract(1, 'month');
        const panelNextYearMonth = panelYearMonth.add(1, 'month');

        // 產生年月標題
        return (
            <YearMonthRow>

                <ChangeControl>
                    <MonthButton
                        type="button"
                        onClick={() => handleChangePanel(
                            panelPreYearMonth.year(),
                            panelPreYearMonth.month(),
                        )}
                    >
                        <Icon code="caret-down" color="rgba(0, 0, 0, 0.25)" size={20} rotate={90}/>
                    </MonthButton>

                    <YearMonth>
                        <Year onClick={handleConformYear}>
                            {panelYearMonth.year()}<I18N id="com.calendar.unit.year" defaultMessage="年"/>
                        </Year>
                        <MonthGroup>
                            <Month>
                                {
                                    localeMonth[panelYearMonth.month()]
                                    && <I18N id={localeMonth[panelYearMonth.month()].props.id} defaultMessage={localeMonth[panelYearMonth.month()].props.defaultMessage}/>
                                }
                            </Month>

                            <MonthSelect
                                onChange={e => handleChangePanel(undefined, panelYearMonth.set('month', Number(e.target.value)).month())}
                                value={panelYearMonth.month()}
                            >
                                {localeMonth}
                            </MonthSelect>
                        </MonthGroup>

                    </YearMonth>

                    <MonthButton
                        type="button"
                        onClick={() => handleChangePanel(
                            panelNextYearMonth.year(),
                            panelNextYearMonth.month(),
                        )}
                    >
                        <Icon code="caret-down" color="rgba(0, 0, 0, 0.25)" size={20} rotate={-90}/>
                    </MonthButton>
                </ChangeControl>

            </YearMonthRow>
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [panelYearMonth]);

    /**
     * 產生週標題
     * @returns {*}
     */
    const renderWeek = useCallback(() => (
        <WeekRow>
            {/* eslint-disable-next-line react/no-array-index-key */}
            {localeWeekDay.map((week, index) => <Week key={`localeWeekDay-${index}-${week}`}>{week}</Week>)}
        </WeekRow>

        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), []);

    /**
     * 產生上個月的剩餘日期表
     * @returns {Array}
     */
    const renderPreMonthDay = useCallback(() => {
        const currentDate = getConvertDayjs(value);

        // 取得指定年月的第一天是星期幾 (0, 1-6)
        const currentMonFirstWeek = panelYearMonth.set('date', 1).day();

        // 取 Panel年月 剩餘月份的可放空間 (星期六 ex: 6-1=5格, 星期日則為7天)
        const preMonthFirstContainer = currentMonFirstWeek === 0 ? 6 : currentMonFirstWeek - 1;

        // 取 Panel年月 上個月的最後一天是幾號
        const preMonth = panelYearMonth.subtract(1, 'month');
        const preMonthLastDay = Number(preMonth.endOf('month').get('date'));

        // 取 Panel年月 結束日從幾號開始
        const preMonthFirstDay = preMonthLastDay - preMonthFirstContainer;

        // 產生 Panel年月 上個月的剩餘日期表
        const preMonFirstDayList = new Array(preMonthLastDay);
        for (let d = 0; d < preMonthFirstContainer; d++) {
            const day = preMonthFirstDay + d + 1;
            preMonFirstDayList[d] = (
                <PreDay
                    key={`preMonthDay-${d}`}
                    isSelected={currentDate.isSame(preMonth.set('date', day), 'date')}
                    onClick={() => handleSelectedDate(preMonth.year(), preMonth.month(), day)}
                >
                    <span>
                        {day}
                    </span>
                </PreDay>
            );
        }

        return preMonFirstDayList;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [panelYearMonth, value]);

    /**
     * 產生下個月的剩餘日期表
     * @returns {Array}
     */
    const renderNextMonthDay = useCallback(() => {
        const currentDate = getConvertDayjs(value);

        // 取得指定年月的第一天是星期幾 (0, 1-6)
        const currentMonFirstWeek = panelYearMonth.set('date', 1).day();

        // 取 Panel年月 上個月份的已放空間 (星期六 ex: 6-1=5格, 星期日則為7天)
        const preMonthFirstContainer = currentMonFirstWeek === 0 ? 6 : currentMonFirstWeek - 1;

        // 取 Panel年月 這個月的最後一天是幾號
        const panelMonthLastDay = panelYearMonth.endOf('month').get('date');

        const nextMonth = panelYearMonth.add(1, 'month');

        // 取得指定年月下個月剩餘月份可放空間
        const nextMonthEndContainer = (7 * 6) % (preMonthFirstContainer + panelMonthLastDay);

        // 產生上個月的剩餘日期表
        const nextMonEndDayList = new Array(nextMonthEndContainer);
        for (let d = 0; d < nextMonthEndContainer; d++) {
            const day = d + 1;
            nextMonEndDayList[d] = (
                <PreDay
                    key={`nextMonthDay-${d}`}
                    isSelected={currentDate.isSame(nextMonth.set('date', day))}
                    onClick={() => handleSelectedDate(nextMonth.year(), nextMonth.month(), day)}
                >
                    <span>
                        {day}
                    </span>
                </PreDay>
            );
        }

        return nextMonEndDayList;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[panelYearMonth, value]);

    /**
     * 產生當月日期表
     * @returns {*}
     */
    const renderCurrentMonthDay = useCallback(() => {
        const currentDate = getConvertDayjs(value);

        // 取 Panel年月 的最後一天
        const currentMonthLastDay = panelYearMonth.endOf('month').get('date');

        // 產生 Panel年月 當月日期表
        const currentDayList = new Array(currentMonthLastDay);
        for (let d = 0; d < currentMonthLastDay; d++) {
            const dayNumber = d + 1;
            const eachDate = panelYearMonth.set('date', dayNumber);
            currentDayList[d] = (
                <Day
                    key={`currentDay-${d}`}
                    isToday={today.isSame(eachDate, 'date')}
                    isSelected={currentDate.isSame(eachDate, 'date')}
                    onClick={() => handleSelectedDate(panelYearMonth.year(), panelYearMonth.month(), dayNumber)}
                >
                    <span>
                        {dayNumber}
                    </span>
                </Day>
            );
        }

        return (
            <DayRow>
                {renderPreMonthDay()}
                {currentDayList}
                {renderNextMonthDay()}
            </DayRow>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [panelYearMonth, value]);

    const renderTodayButton = useCallback(() => (
        <LabelCheckCardCreate>
            <TodayButton type="button" onClick={handleSelectedToday}>
                <span><I18N id="com.calendar.setToday" defaultMessage="设定为今天"/></span>
            </TodayButton>
        </LabelCheckCardCreate>

        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), []);

    return (
        <DatePickerRoot>
            <DatePickerHeader>
                {renderYearMonth()}
                {renderWeek()}
            </DatePickerHeader>

            {renderCurrentMonthDay()}

            {isVisibleSetToday && renderTodayButton()}
        </DatePickerRoot>
    );

};


export default DatePicker;

const DatePickerHeader = styled.div`
    display: flex;
    border-bottom: 1px solid #aeaeae;
    background-color: #f0f0f0;
    flex-direction: column;
    flex-wrap: nowrap;
`;

const TodayButton = styled.button`
    color: ${props => props.theme.primaryColor};
    background-color: transparent;
    flex: 1 1 auto;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    padding: 5px;
`;

const LabelCheckCardCreate = styled.div`
    display: flex;
    align-items: center;
    border-top: solid 1px #f0f0f0;
    cursor: pointer;
`;

const Week = styled.div`
    flex: 0 0 28px;
    color: #000;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-size: 13px;
    font-weight: 400;
`;

const WeekRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
`;

const Day = styled(Week)<any>`
    position: relative;
    border-radius: .3rem;
    width: 28px;
    height: 28px;

    span{
        z-index: 1;
    }

    :before{
        content: '';
        border-radius: .3rem;

        position: absolute;
        width: 25px;
        height: 25px;
        z-index: 0;
    }

    :hover{
        //color: #ababab;
        background-color: #f0f0f0;
        cursor: pointer;
    }

    ${props => props.isToday && css`
        color: ${props.theme.primaryColor};
        font-weight: 900;
    `};


    ${props => props.isSelected && css`
        color: #fff;

        :before{
            background-color: ${props.theme.primaryColor};
        }

        :hover{
            color: #fff;
        }
    `};
`;

const PreDay = styled(Day)<any>`
    color: rgba(0, 0, 0, 0.25);
    span{
        z-index: 1;
    }

    ${props => props.isSelected && css`
        color: #fff;

        :before{
            background-color: ${props.theme.primaryColor};
        }

        :hover{
            color: #fff;
        }
    `}
`;

const DayRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 8px 12px;
`;

const MonthButton = styled.button`
    padding: 0;
    border: none;
    background-color: transparent;
    width: 30px;

    :hover{
        .iconfont{
            color: rgba(0, 0, 0, 0.65);
        }
    }

    .iconfont {
        transition: color .3s;
    }
`;

const ChangeControl = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0 12px;
    height: 32px;
`;

const MonthSelect = styled.select`
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
`;

const Month = styled.span`
    color: inherit;
    font-size: 15px;
    font-weight: 900;
    flex: 0 0 auto;
`;

const MonthGroup = styled.div`
    position: relative;
`;

const Year = styled.span`
    color: inherit;
    font-size: 15px;
    font-weight: 900;
    flex: 0 0 auto;
    cursor: pointer;
    margin-right: 5px;
`;

const YearMonth = styled.div`
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
`;

const YearMonthRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: solid 1px #f0f0f0;
    line-height: 38px;
`;

const DatePickerRoot = styled.div`
    background-color: #fff;
    width: calc(28px * 7 + 24px);
    border-radius: 6px;
    margin: 0 auto;
    box-shadow:
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
    overflow: hidden;
`;

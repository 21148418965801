import {useEffect, useCallback} from 'react';
import dayjs from 'dayjs';
import {Container} from 'library/styled-bs-grid';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {SubmitHandler, useForm} from 'react-hook-form';
import {formatCurrency} from 'utils/number';

// Components
import {CardTable as Table} from 'components/atoms/Table';
import Button from 'components/atoms/Button';

// Stores
import {actions as memberBalanceActions} from 'store/memberBalance';
import ListFeatureGroup from 'components/organisms/ListFeatureGroup';
import {objFilterNotTrue2Array} from 'utils/format';
import Avatar from '../../../../../../../components/atoms/Avatar';


/**
 * 會員管理/會員餘額
 */
const List = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams<{memberUserId: string}>();
    const memberUserId = Number(params.memberUserId);

    const baseRoutePath = `/member/user/${memberUserId}/balance`;

    const tableForm = useForm<{checkedId: {
            [key: string]: boolean
        }}>();


    const {paginateData, paginateMeta, isFetching} = useSelector(state => ({
        paginateData: state.memberBalance.paginateData,
        paginateMeta: state.memberBalance.paginateMeta,
        isFetching: state.memberBalance.isFetching,
    }));


    useEffect(() => {
        handleFetchPaginate(1, 50);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     * 刪除多筆資料
     */
    const handleDeleteRows = () => {
        // 取得 Table中的 CheckId
        // const {checkedId} = tableForm.getValues({nest: true});
        const {checkedId} = tableForm.getValues();
        const ids = objFilterNotTrue2Array(checkedId);

        if(ids.length === 0){
            alert('請選擇項目');
        }
        handleDeleteRow(ids);
    };


    /**
     * 刪除一筆資料
     */
    const handleDeleteRow = (ids: number[]) => {
        dispatch(memberBalanceActions.deleteData({ids, callBack: handleFetchPaginate}));
    };


    /**
     * 查詢分頁
     */
    const handleFetchPaginate = useCallback((currentPage?: number, pageLimit?: number) => {
        // 取得查詢項目

        dispatch(memberBalanceActions.fetchPaginate({
            currentPage,
            pageLimit,
            memberUserId: memberUserId,
        }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <Container fluid>


            <ListFeatureGroup data={[
                {text: 'Add', onClick: () => history.push(`${baseRoutePath}/create`), color: 'primary'},
                {text: 'Delete', onClick: handleDeleteRows, color: 'danger'},
            ]}/>

            {/* 表格資料 */}
            <Table
                isFetching={isFetching}
                hookForm={tableForm}
                title={[
                    {text: 'Currency',  field: 'balanceCurrency', titleAlign: 'right', dataAlign: 'right' , width: 100},
                    {text: 'Balance Amount',   field: 'balanceAmount', titleAlign: 'right', dataAlign: 'right', width: 200},
                    {text: 'Bank',  field: 'bankName'    , col: true},
                    {text: 'BSB',    field: 'bsb'   , width: 140},
                    {text: 'Created',      field: 'updatedAt', width: 120, titleAlign: 'left'},
                    {text: 'Updated',      field: 'createdAt', width: 120},
                ]}
                data={paginateData.map(row => {
                    return {
                        id: row.id,
                        balanceCurrency: row.balanceCurrency,
                        balanceAmount: formatCurrency(row.balanceAmount),
                        accountName: row.accountFullName,
                        bankName: row.bankName,
                        bsb: row.bsb,
                        updatedAt: <div style={{fontSize: 12}}>
                            {dayjs(row.updatedAt).format('YYYY-MM-DD')}<br/>
                            {dayjs(row.updatedAt).format('HH:mm:ss')}
                        </div>,
                        createdAt: <div style={{fontSize: 12}}>
                            {dayjs(row.createdAt).format('YYYY-MM-DD')}<br/>
                            {dayjs(row.createdAt).format('HH:mm:ss')}
                        </div>,
                    };
                })}
                onChangePage={(page, limit) => handleFetchPaginate(page, limit)}
                meta={paginateMeta}
                isVisiblePaginate={false}
                onEditRow={id => history.push(`${baseRoutePath}/${id}`)}
                onDeleteRow={id => handleDeleteRow([id])}
            />

        </Container>
    );
};

export default List;


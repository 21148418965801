import {createSlice} from '@reduxjs/toolkit';
import {IState, ISelector, IActionCreators} from './types';
import {TReducers} from 'library/redux';


/** -----------------------------------------
                Store Key
 /** ---------------------------------------*/
export const name = 'dashboard';


/** -----------------------------------------
             Initial State
 /** ---------------------------------------*/
export const initialState: IState = {
    isFetching: false,
    message: '',

    siteInfo: undefined,


};


/** -----------------------------------------
            Selectors
 /** --------------------------------------*/
export const Selector: ISelector = {};



/** -----------------------------------------
                Reducers
 /** ---------------------------------------*/
const reducers: TReducers<IActionCreators, IState> = {
    /**
     * 取站台資訊
     */
    fetchSiteInfo: (state, action) => {},
    fetchSiteInfoBegin: (state) => {
        return {...state, isFetching: true, message: ''};
    },
    fetchSiteInfoSuccess: (state, {payload}) => {
        return {...state, isFetching: false, siteInfo: payload.data};
    },
    fetchSiteInfoFail: (state, {payload}) => {
        return {...state, isFetching: false, message: payload.message};
    },
};


export const {reducer, actions} = createSlice({name, initialState, reducers});

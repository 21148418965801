import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components/macro';
import {Container} from 'library/styled-bs-grid';

// Components
import Card from 'components/atoms/Card';
import FormHorizontalGroup from 'components/forms/FormHorizontalGroup';
import PageHeader from 'components/organisms/PageHeader';

// Stores
import {actions as rootNoticeActions} from 'store/rootNotice';


/**
 * 廣告/最新消息/編輯
 *
 */
const Edit = () => {
    const params = useParams<{id: string}>();
    const currentId = params.id;
    const baseRoutePath = '/root/notice';

    const dispatch = useDispatch();

    const {currentData} = useSelector(state => ({
        currentData: state.rootNotice.currentData,
    }));

    useEffect(() => {
        dispatch(rootNoticeActions.fetchCurrent({id: Number(currentId)}));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    return (
        <PageRoot>
            <PageHeader
                breadcrumbData={[
                    {text: '系統通知', href: baseRoutePath},
                ]}
                tabsData={[
                    {text: '檢視', href: `${baseRoutePath}/${currentId}`},
                ]}
            />

            {/* 內容 */}
            <Container fluid>
                <Card direction="column">

                    <FormHorizontalGroup label="標題">
                        {currentData?.title}
                    </FormHorizontalGroup>

                    <FormHorizontalGroup label="描述">
                        <div dangerouslySetInnerHTML={{__html: currentData?.content || ''}} />

                    </FormHorizontalGroup>

                    <FormHorizontalGroup label="建立時間">
                        {currentData?.createdAt}
                    </FormHorizontalGroup>

                </Card>
            </Container>

        </PageRoot>
    );
};

export default Edit;


const PageRoot = styled.div`
    padding: 0 20px;
`;

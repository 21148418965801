import {useEffect, useCallback, useMemo} from 'react';
import {Row, Col, Container} from 'library/styled-bs-grid';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {getDistrictOptions, getCountyOptions} from 'config/address';
import {isEmpty} from 'utils/equal';
import {currencies} from 'config/common';

// Components
import TextField from 'components/forms/TextField';
import Card from 'components/atoms/Card';
import Button from 'components/atoms/Button';
import Select2 from 'components/forms/Select2';
import FormHorizontalGroup from 'components/forms/FormHorizontalGroup';


// Stores
import {actions as memberBalanceActions} from 'store/memberBalance';

import {IFormData} from './types';
import FormVerticalGroup from '../../../../../../../components/forms/FormVerticalGroup';

/**
 * 會員管理/調整餘額
 * 參考: https://ericssonr.nsdbytes.com/setting
 */
const Edit = () => {
    const history = useHistory();
    const params = useParams<{id: string, memberUserId: string}>();
    const currentId = Number(params.id);
    const memberUserId = Number(params.memberUserId);
    const isCreate = params.id === 'create';
    const baseRoutePath = `/member/user/${memberUserId}/balance`;

    const dispatch = useDispatch();

    const {handleSubmit, control, reset, watch} = useForm<IFormData>();


    const {currentData} = useSelector(state => ({
        currentData: state.memberBalance.currentData,
    }));


    useEffect(() => {
        dispatch(memberBalanceActions.fetchCurrent({id: currentId}));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     * 設定預設值
     */
    useEffect(() => {
        if(isCreate){
            reset();
        }else{
            reset({
                balanceCurrency: currentData?.balanceCurrency ?? '',
                balanceAmount: currentData?.balanceAmount ?? 0,

                accountFullName: currentData?.accountFullName ?? '',
                // accountFirstName: currentData?.accountFirstName ?? '',
                // accountLastName: currentData?.accountLastName ?? '',
                bsb: currentData?.bsb ?? '',
                account: currentData?.account ?? '',
                bankName: currentData?.bankName ?? '',
                bankAddress: currentData?.bankAddress ?? '',
            });
        }

        // eslint-disable-next-line
    }, [currentData]);




    /**
     * MEMO 貨幣
     */
    const memoCurrencies = useMemo(() => {

        return [{text: 'Select Currency', value: ''}, ...currencies];

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     * 送出表單
     * @param data
     */
    const handleSubmitHandler: SubmitHandler<IFormData> = useCallback(formData => {

        if(isCreate){
            dispatch(memberBalanceActions.createData({data: {
                ...formData,
                memberUserId,
            }}));
        }else{

            dispatch(memberBalanceActions.updateData({id: currentId, data: {
                ...formData,
                // isBuyOtherStoreBefore: formData.isBuyOtherStoreBefore === 'true',

            }}));

        }



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container fluid>
            <Card direction="column">
                <form onSubmit={handleSubmit(handleSubmitHandler)}>

                    <FormHorizontalGroup label="Balance Currency">
                        <Controller
                            control={control}
                            name="balanceCurrency"
                            defaultValue=""
                            render={({onChange, value}) => {
                                return (<Select2
                                    onChange={onChange}
                                    value={value}
                                    options={memoCurrencies}
                                    disabled={!isCreate}
                                />);
                            }}
                        />
                    </FormHorizontalGroup>

                    <FormHorizontalGroup label="Balance Amount">
                        <Controller
                            control={control}
                            name="balanceAmount"
                            defaultValue=""
                            render={({onChange, value}) => {
                                return (<TextField
                                    onChange={onChange}
                                    value={value}
                                    maxLength={11}
                                />);
                            }}
                        />
                    </FormHorizontalGroup>


                    <hr/>



                    <FormHorizontalGroup label="ACC Name">
                        <Controller
                            control={control}
                            name="accountFullName"
                            defaultValue=""
                            render={({onChange, value}) => {
                                return (<TextField
                                    onChange={onChange}
                                    value={value}
                                />);
                            }}
                        />
                        {/*<Controller*/}
                        {/*    control={control}*/}
                        {/*    name="accountLastName"*/}
                        {/*    defaultValue=""*/}
                        {/*    render={({onChange, value}) => {*/}
                        {/*        return (<TextField*/}
                        {/*            onChange={onChange}*/}
                        {/*            value={value}*/}
                        {/*            placeholder="Last Name"*/}
                        {/*        />);*/}
                        {/*    }}*/}
                        {/*/>*/}
                    </FormHorizontalGroup>


                    <FormHorizontalGroup label="BSB">
                        <Controller
                            control={control}
                            name="bsb"
                            defaultValue=""
                            render={({onChange, value}) => {
                                return (<TextField
                                    onChange={onChange}
                                    value={value}
                                />);
                            }}
                        />
                    </FormHorizontalGroup>

                    <FormHorizontalGroup label="ACC">
                        <Controller
                            control={control}
                            name="account"
                            defaultValue=""
                            render={({onChange, value}) => {
                                return (<TextField
                                    onChange={onChange}
                                    value={value}
                                />);
                            }}
                        />
                    </FormHorizontalGroup>



                    <FormHorizontalGroup label="Bank Name">
                        <Controller
                            control={control}
                            name="bankName"
                            defaultValue=""
                            render={({onChange, value}) => {
                                return (<TextField
                                    onChange={onChange}
                                    value={value}
                                />);
                            }}
                        />
                    </FormHorizontalGroup>

                    <FormHorizontalGroup label="Bank Address">
                        <Controller
                            control={control}
                            name="bankAddress"
                            defaultValue=""
                            render={({onChange, value}) => {
                                return (<TextField
                                    onChange={onChange}
                                    value={value}
                                />);
                            }}
                        />
                    </FormHorizontalGroup>

                    <FormHorizontalGroup>
                        <Button type="submit" color="primary" className="mr-2">Save</Button>
                        <Button type="button" color="gray" onClick={() => history.push(baseRoutePath)}>Back</Button>
                    </FormHorizontalGroup>

                </form>
            </Card>
        </Container>
    );
};

export default Edit;

import {all, call, delay, put, takeLatest} from 'redux-saga/effects';
import {IPickAction} from 'library/redux';
import {fetchDataDelayMinMs} from 'config/app';

// Stores
import {actions as uiDialogActions} from 'store/uiDialog';
import {actions} from './reducer';
import {IActionCreators} from './types';

// Services
import ApiService, {TGetSiteInfoRes} from 'services/dashboard';




/**
 * 取列表資料
 */
function* fetchSiteInfo(action: IPickAction<IActionCreators, 'fetchSiteInfo'>) {
    yield put(actions.fetchSiteInfoBegin());

    try {
        const [{body}]: [TGetSiteInfoRes] = yield all([
            call(ApiService.getSiteInfo),
            delay(fetchDataDelayMinMs),
        ]);

        yield put(actions.fetchSiteInfoSuccess({data: body.data}));

    } catch (err) {
        yield put(actions.fetchSiteInfoFail({message: err.message}));
        yield put(uiDialogActions.openError({message: err.message, code: err.code}));

    }
}





export default [
    takeLatest(actions.fetchSiteInfo.type, fetchSiteInfo),

];

import {createSlice} from '@reduxjs/toolkit';
import {IState, ISelector, IActionCreators} from './types';
import {TReducers} from 'library/redux';


/** -----------------------------------------
                Store Key
 /** ---------------------------------------*/
export const name = 'uiBlock';


/** -----------------------------------------
             Initial State
 /** ---------------------------------------*/
export const initialState: IState = {
    isVisible: false,
    message: '',
};


/** -----------------------------------------
              Selectors
 /** --------------------------------------*/
export const Selector: ISelector = {};



/** -----------------------------------------
                Reducers
 /** ---------------------------------------*/
const reducers: TReducers<IActionCreators, IState> = {
    visible: (state, {payload}) => {
        // state.isVisible = true;
        // state.message = payload?.message ?? i18n({id: 'com.uiBlock.loading', default: 'Loading...'});
        return {
            ...state,
            isVisible: true,
            message: '',
        };
    },
    hidden: (state) => {
        state.isVisible = false;
    },
};


export const {reducer, actions} = createSlice({name, initialState, reducers});

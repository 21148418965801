import api from 'library/apisauce';

import {TGetPaginateRes, TGetDetailRes, TCreateDataRes, TUpdateDataRes, TDeleteDataRes, TGetSettingsRes, TDownloadRes} from './types';
import {obj2formData} from '../../utils/format';

/**
 * 會員/資料管理
 */
export default {
    /**
     * 查詢 列表資料
     */
    getPaginate: (currentPage: number, pageLimit: number,
        memberUserId?: number,
        startDate?: string,
        endDate?: string,
        storeId?: number,
        soldCurrency?: string,
        isCompleted?: boolean,
        isLodged?: boolean,
    ): Promise<TGetPaginateRes> => {
        return api.get('/job', {
            currentPage, pageLimit,
            memberUserId,
            startDate,
            endDate,
            storeId,
            soldCurrency,
            isCompleted,
            isLodged,
        }, {
            headers: {isAuth: true},
        });
    },
    /**
     * 查詢 設定資料
     */
    getSettings: (): Promise<TGetSettingsRes> => {
        return api.get('/job/settings', {}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 查詢 明細資料
     */
    getDetail: (id: number): Promise<TGetDetailRes> => {
        return api.get(`/job/${id}`, {}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 新增 資料
     */
    createData: (data: {
        date: string,
        buyCurrency: string,
        buyAmount: number,
        rate: number,
        soldCurrency: string,
        soldAmount: number,
        paidToMember?: number,
        isHandleFee: boolean,
        isCompleted?: boolean,
        isLodged?: boolean,

        memberUserId: number,
    }): Promise<TCreateDataRes> => {
        return api.post('/job', data, {
            headers: {isAuth: true},
        });
    },
    /**
     * 更新 資料
     */
    updateData: (id: number, data: {
        date: string,
        buyCurrency: string,
        buyAmount: number,
        rate: number,
        soldCurrency: string,
        soldAmount: number,
        paidToMember?: number,
        isHandleFee: boolean,
        isCompleted?: boolean,
        isLodged?: boolean,

        memberUserId: number,
    }): Promise<TUpdateDataRes> => {

        return api.put(`/job/${id}`, data, {
            headers: {
                isAuth: true,
            },
            timeout: 10 * 60 * 1000
        });
    },
    /**
     * 更新 資料
     */
    updateRemarkData: (id: number, data: {
        paidToMember?: number,
        isCompleted?: boolean,
        isLodged?: boolean,
    }): Promise<TUpdateDataRes> => {

        return api.put(`/job/${id}/remark`, data, {
            headers: {
                isAuth: true,
            },
            timeout: 10 * 60 * 1000
        });
    },

    /**
     * 刪除
     */
    deleteData: (ids: number[]): Promise<TDeleteDataRes> => {
        return api.delete('/job', {ids}, {
            headers: {isAuth: true},
        });
    },


    /**
     * 下載PDF
     */
    download: (id: number): Promise<TDownloadRes> => {
        return api.post(`/job/${id}/download`, {
        }, {
            headers: {
                isAuth: true,
            },
            responseType: 'blob',
            timeout: 60000,
        });
    },

};

import styled, {css} from 'styled-components/macro';
import {GridThemeProvider, media, Row, Col, Container} from 'library/styled-bs-grid';
import {isEmpty} from 'utils/equal';
import uniqueId from 'lodash/uniqueId';

// Components
import Button from 'components/atoms/Button';

interface IProps {
    type: 'success'|'error'|'info'|'confirm';
    title: string;
    message: string;
    code?: string;
    buttons: Array<{
        text: string,
        type: 'primary'|'danger',
        onClick?: Function;
    }>;
}

const Dialog = ({
    type,
    title,
    message,
    code,
    buttons,
}: IProps) => {
    const handleClickDialog = (onClick: Function | undefined) => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <DialogRoot>
            <Title type={type}>{title}</Title>
            <MessageContent dangerouslySetInnerHTML={{__html: message}} />

            {!isEmpty(code) && (
                <StatusCode>{`Code: ${code}`}</StatusCode>
            )}


            <GridThemeProvider gridTheme={{
                gridGutterWidth: 5,
            }}>
                <Container fluid>
                    <Row className="justify-content-center">
                        {buttons.map(row => (
                            <Col
                                key={uniqueId('modalButton_')}
                                className="d-flex justify-content-center"
                                col
                            >
                                <ModalButton
                                    isBlock
                                    color={row.type}
                                    onClick={() => handleClickDialog(row.onClick)}
                                >
                                    {row.text}
                                </ModalButton>
                            </Col>
                        ))}
                    </Row>
                </Container>

            </GridThemeProvider>

        </DialogRoot>
    );
};

export default Dialog;



const ModalButton = styled(Button)`
    max-width: 180px;

    ${media.lg`
        max-width: initial;
        font-size: 16px;
    `}
`;

const StatusCode = styled.div`
    font-size: 12px;
    color: #9d9a9a;
    margin-bottom: 10px;
`;

const MessageContent = styled.div`
    font-size: 14px;
    text-align: left;
    color: #282731;
    font-weight: 400;
    padding-bottom: 15px;
    white-space:pre-wrap;

    ${media.lg`
        font-size: 14px;
        padding-bottom: 25px;
    `}
`;

const Title = styled.div<any>`
    font-size: 16px;
    font-weight: 900;
    margin-bottom: 10px;
    color: ${props => props.type === 'success' ? '#7ed321' :
        props.type === 'info'? '#8f85bd' :'#ff4b4b'};

    ${props => props.type === 'confirm' && css`
        color: #FABD32;
    `};

    ${media.lg`
        font-size: 20px;
    `}
`;

const DialogRoot = styled.div`
    width: 400px;
    border-radius: 5px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    padding: 16px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${media.lg`
        width: 370px;
        padding: 20px 20px;
    `}
`;

/*
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `src/resources/lang`)
 */

import {FC, Children} from 'react';
import {IntlProvider} from 'react-intl';
import {MessageFormatElement} from 'intl-messageformat-parser';
import {useSelector} from 'react-redux';
import TranslationWrapper from './TranslationWrapper';
import IntlGlobalProvider from '../global';
import {ILocaleCode} from '../types';
import {selector} from 'store/language';

interface IProps {
    messages: {
        [code in ILocaleCode]?: Record<string, string> | Record<string, MessageFormatElement[]>;
    }
}

const LanguageProvider: FC<IProps> = ({
    messages,
    children
}) => {
    const locale = useSelector(selector.selectLanguage);
    const activeMessage = messages[locale];

    return (
        <IntlProvider
            locale={locale}
            key={locale}
            defaultLocale="en-US"
            messages={activeMessage}
            // @ts-ignore
            textComponent={TranslationWrapper}
        >
            <IntlGlobalProvider>
                {Children.only(children)}
            </IntlGlobalProvider>
        </IntlProvider>
    );
};

export default LanguageProvider;

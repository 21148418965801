import {useRef, useEffect} from 'react';
import styled from 'styled-components/macro';
import {disableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock';
import {FCChildrenProps} from 'types';

interface IProps extends FCChildrenProps{
}

/**
 * ScrollView
 */
const ScrollView = ({
    className,
    style,
    children,
}: IProps) => {

    const listRef = useRef<HTMLDivElement>();

    /**
     * ComponentDidMount
     */
    useEffect(() => {
        // 鎖背景
        // clearAllBodyScrollLocks();
        if(listRef.current){
            disableBodyScroll(listRef.current);

            return () => {
                clearAllBodyScrollLocks();
            };
        }
    }, []);

    return (
        <ScrollViewRoot ref={listRef} className={className} style={style}>
            {/* 內容 */}
            {children}
        </ScrollViewRoot>
    );
};

export default ScrollView;

const ScrollViewRoot = styled.div<any>`
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    flex: 1 1 0;
    height: 100%;
`;

import {useCallback, useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components/macro';
import {Container} from 'library/styled-bs-grid';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';

// Components
import Card from 'components/atoms/Card';
import Button from 'components/atoms/Button';
import FormHorizontalGroup from 'components/forms/FormHorizontalGroup';
import PageHeader from 'components/organisms/PageHeader';
import TextAreaField from 'components/forms/TextAreaField';

// Stores
import {actions as settingSiteActions} from 'store/settingSite';
import {IFormData} from './types';

/**
 * 設定/網站設定/編輯
 */
const Edit = () => {
    const history = useHistory();
    const params = useParams<{id: string}>();
    const currentId = params.id;
    const baseRoutePath = '/setting/site';

    const dispatch = useDispatch();

    const {handleSubmit, control, reset} = useForm<IFormData>();

    const {currentData} = useSelector(state => ({
        currentData: state.settingSite.currentData,
    }));

    useEffect(() => {
        dispatch(settingSiteActions.fetchCurrent({id: Number(currentId)}));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * 設定預設值
     */
    useEffect(() => {
        reset({
            value: currentData?.value || '',
        });
        // eslint-disable-next-line
    }, [currentData]);


    /**
     * 送出表單
     * @param data
     */
    const handleSubmitHandler: SubmitHandler<IFormData> = useCallback(formData => {
        // 編輯
        dispatch(settingSiteActions.updateData({id: Number(currentId), data: {
            ...formData,
        }}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageRoot>
            <PageHeader
                breadcrumbData={[
                    {text: 'Site Setting'},
                    {text: 'Site Params', href: baseRoutePath},
                ]}
                tabsData={[
                    {text: 'Edit', href: `${baseRoutePath}/${currentId}`},
                ]}
            />

            {/* 內容 */}
            <Container fluid>
                <Card direction="column">
                    <form onSubmit={handleSubmit(handleSubmitHandler)}>

                        <FormHorizontalGroup label="Group">
                            {currentData?.group}
                        </FormHorizontalGroup>

                        <FormHorizontalGroup label="Code">
                            {currentData?.code}
                        </FormHorizontalGroup>

                        <FormHorizontalGroup label="Name">
                            {currentData?.name}
                        </FormHorizontalGroup>

                        <FormHorizontalGroup label="Value">
                            <Controller
                                control={control}
                                name="value"
                                defaultValue=""
                                render={({onChange, value}) => {
                                    return (<TextAreaField
                                        onChange={onChange}
                                        value={value}
                                    />);
                                }}
                            />
                        </FormHorizontalGroup>


                        <FormHorizontalGroup>
                            <Button type="submit" color="primary" className="mr-2">Save</Button>
                            <Button type="button" color="gray" onClick={() => history.push(baseRoutePath)}>Back</Button>
                        </FormHorizontalGroup>

                    </form>
                </Card>
            </Container>

        </PageRoot>
    );
};

export default Edit;


const PageRoot = styled.div`
    padding: 0 20px;
`;

import {useCallback, useState} from 'react';
import styled, {css} from 'styled-components/macro';
import {media} from 'library/styled-bs-grid';
import {useDispatch, useSelector} from 'react-redux';
import {isEmpty, isNotEmpty} from 'utils/equal';

// Components
import PageSlider from 'components/organisms/PageSlider';
import {featureData, adminData, extraData} from 'components/organisms/PageSlider/tests/fakeData';
import LayoutHeader from 'components/organisms/LayoutHeader';
import {IItem} from 'components/organisms/PageSlider/types';
import Route from './Route';

// Stores
import {actions as authActions, selector as authSelector} from 'store/auth';

/**
 * 首頁 布局區塊
 */
const HomeRoot = () => {
    const dispatch = useDispatch();

    // const [isHoldPanel, setHoldPanel] = useState(false);
    const isHoldPanel = false;
    const [isVisiblePanel, setVisiblePanel] = useState(true);
    const {permissions, isMessageNotRead, isNoticeNotRead} = useSelector(state => ({
        permissions: authSelector.permissions(state),
        isMessageNotRead: !!state.message.notReadCount,
        isNoticeNotRead: !!state.rootNotice.notReadCount,
    }));


    const handleLogout = useCallback(() => {
        dispatch(authActions.logout());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     * 過濾無權限清單
     */
    const filterAuth = useCallback((data: IItem[]): IItem[] => {

        return data.reduce((prevRow: IItem[], row) => {
            if(row.path === '/dashboard'){
                return [...prevRow, row];
            }else if(isEmpty(row.children)){
                if(isNotEmpty(permissions.find(permission => row.path.startsWith(permission)))){
                    return [...prevRow, row];
                }
            }

            if(row.children){
                row.children = filterAuth(row.children);
                if(isNotEmpty(row.children)){
                    return [...prevRow, row];
                }
            }

            return prevRow;
        }, []);

    }, [permissions]);


    return (
        <>
            <HomeLayoutRoot className="d-flex flex-column" style={{height: 'inherit'}}>

                {/* 左側目錄 */}
                <PageSlider
                    featureData={filterAuth(featureData)}
                    adminData={filterAuth(adminData)}
                    extraData={filterAuth(extraData)}
                    isHold={isHoldPanel}
                    onLogout={handleLogout}
                    onVisiblePanel={setVisiblePanel}
                    isVisiblePanel={isVisiblePanel}
                    isAuthNotice={permissions.includes('/root/notice')}
                    isNoticeNotRead={isNoticeNotRead}
                    isAuthMessage={permissions.includes('/message')}
                    isMessageNotRead={isMessageNotRead}
                />

                {/* 內容 */}
                <PageContainer
                    isVisiblePanel={isVisiblePanel}
                    // onClick={isVisiblePanel ?
                    //     () => setVisiblePanel(false):
                    //     undefined
                    // }
                >
                    {/* 表頭 */}
                    <LayoutHeader
                        isVisiblePanel={isVisiblePanel}
                        onClickMenu={() => setVisiblePanel(isVisible => !isVisible)}
                    />

                    <PageContentWrapper className="h-100">
                        <Content className="h-100">
                            <Route/>
                        </Content>
                    </PageContentWrapper>

                </PageContainer>





            </HomeLayoutRoot>

        </>
    );
};

export default HomeRoot;

const Content = styled.div`
    padding-top: 56px;
    padding-bottom: 0;
    z-index: 10;
    min-height: 100%;
    transition: all .3s ease;
    height: 100%;

    ${media.md`
        padding-top: 76px;
    `}

    @media print{
        padding: 0;
    }

`;

const PageContentWrapper = styled.div`
    min-height: 100%;
    //height: 100%;
    position: relative;
    background-color: #272c31;
`;


const PageContainer = styled.div<{
    isVisiblePanel: boolean;
}>`

    //background-color: #fff;
    width: 100%;
    height: 100%;

    padding-left: 0;
    position: relative;
    transition: transform .25s ease;



    ${media.md`
        padding-left: 60px;
    `}

    ${media.xl`
        padding-left: 60px;

        ${(props: any) => props.isVisiblePanel && css`
            padding-left: 280px;
            //transform: translate3d(280px,0,0);
        `}
    `}

    @media print{
        padding: 0;
    }
`;

const HomeLayoutRoot = styled.div`

`;

import api from 'library/apisauce';

import {TGetPaginateRes, TGetDetailRes, TCreateDataRes, TUpdateDataRes, TDeleteDataRes} from './types';
import {obj2formData} from 'utils/format';

/**
 * 會員/資料管理
 */
export default {
    /**
     * 查詢 列表資料
     */
    getPaginate: (currentPage: number, pageLimit: number,
        memberUserId: number,
    ): Promise<TGetPaginateRes> => {
        return api.get('/member/balance', {
            currentPage,
            pageLimit,
            memberUserId,
        }, {
            headers: {isAuth: true},
        });
    },
    /**
     * 查詢 明細資料
     */
    getDetail: (id: number): Promise<TGetDetailRes> => {
        return api.get(`/member/balance/${id}`, {}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 新增 資料
     */
    createData: (data: {
        balanceCurrency: string,
        balanceAmount: number,

        accountFullName: string,
        // accountFirstName: string,
        // accountLastName: string,
        bsb: string,
        account: string,
        bankName: string,
        bankAddress: string,

        memberUserId: number,
    }): Promise<TCreateDataRes> => {
        return api.post('/member/balance', data, {
            headers: {isAuth: true},
        });
    },
    /**
     * 更新 資料
     */
    updateData: (id: number, data: {
        balanceAmount: number,

        accountFullName: string,
        // accountFirstName: string,
        // accountLastName: string,
        bsb: string,
        account: string,
        bankName: string,
        bankAddress: string,
    }): Promise<TUpdateDataRes> => {

        return api.put(`/member/balance/${id}`, data, {
            headers: {
                isAuth: true,
            },
        });
    },

    /**
     * 刪除
     */
    deleteData: (ids: number[]): Promise<TDeleteDataRes> => {
        return api.delete('/member/balance', {ids}, {
            headers: {isAuth: true},
        });
    },

};

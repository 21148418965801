import {Provider} from 'react-redux';
import {ThemeProvider} from 'styled-components/macro';
import {ConnectedRouter} from 'connected-react-router';
import {AutoScrollTop} from 'library/react-router';
import {GridThemeProvider} from 'library/styled-bs-grid';
import {LanguageProvider, translationMessages} from 'library/intl';
import getSetupParam from './setup';

// Configs & Data
import RootRoute from './RootRoute';
import GlobalBlock from './GlobalBlock';
import GlobalDialog from './GlobalDialog';




const setup = getSetupParam();

const App = () => {
    return (
        <Provider store={setup.store}>
            <LanguageProvider messages={translationMessages}>
                <ConnectedRouter history={setup.history}>
                    <GridThemeProvider gridTheme={setup.gridConfig}>
                        <ThemeProvider theme={setup.theme}>
                            <RootRoute/>


                            <GlobalBlock/>
                            <GlobalDialog/>

                            <AutoScrollTop/>

                        </ThemeProvider>
                    </GridThemeProvider>
                </ConnectedRouter>
            </LanguageProvider>
        </Provider>
    );
};

export default App;


import {ISiteConfig} from './types';

export const siteConfigs: ISiteConfig = {
    siteName: 'City Exchange',
    meta: {
        title: 'City Exchange',
        description: 'MANAGER',
    },
    googleBot: {
        noIndex: true,
    },
    isPWAEnable: true,
    defaultLang: 'en-US',
    blackLang: ['en-US'],
    theme: {
        pwaStartUpBackground: '#0e0f13',
        primaryColor: '#6435c9',
        primaryContrastColor: 'rgba(0, 163, 224, 0.8)',
        primaryShadowColor: 'rgba(0, 163, 224, 0.4)',
        primaryGradientColor: '#004e6b',
        loaderMaskColor: '#0d3c6f',
        secondColor: '#a8d580',
        thirdColor: '#9c92ff',
    },
};

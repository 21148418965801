import styled,{css} from 'styled-components';
import {media} from 'library/styled-bs-grid';
import {HalfCircleSpinner} from 'react-epic-spinners';
import {FCProps} from 'types';


interface IProps extends FCProps{
    isVisible: boolean;
    message: string;
    transitionTiming?: number;
}

const Block = ({
    isVisible = false,
    message,
}: IProps) => {

    if(isVisible){
        return (
            <BlockContainer isVisible={isVisible}>
                {message} <HalfCircleSpinner style={{marginLeft: 5}} size={16}/>
            </BlockContainer>
        );
    }

    return null;
};

export default Block;

const BlockContainer = styled.div<{
    isVisible: boolean;
}>`
    z-index: 997;
    color: #fff;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    pointer-events: none;
    opacity: 0;

    ${props => props.isVisible && css`
        opacity: 1;
        pointer-events: auto;
    `};


    ${media.lg`
        font-size: 16px;
    `}
`;

import {TSelectorFunc} from 'library/redux';


/** -----------------------------------------
 Variable
 /** ---------------------------------------*/
export type TAccessToken = string;
export type TPayload = {
    id: number
    account: string
    name: string
    exp: number
    permissions: string[]
    storeId?: number
    roleCode: ECode
} | undefined

export enum ECode {
    'engineer' = 'engineer',
    'siteManager' = 'siteManager',
    'storeAdmin' = 'storeAdmin',
    'storeStaff' = 'storeStaff',
}


/** -----------------------------------------
 Initial State
 /** ---------------------------------------*/
export interface IState {
    isSubmitting: boolean;
    message: string;
    accessToken: TAccessToken;

    name: string;
    avatarUrl: string;
}


/** -----------------------------------------
 Selector
 /** ---------------------------------------*/
export interface ISelector {
    accessToken: TSelectorFunc<string>;
    permissions: TSelectorFunc<string[]>;
    payload: TSelectorFunc<TPayload>;
    isAuth: TSelectorFunc<boolean>;
    storeId: TSelectorFunc<number|undefined>;
    roleCode: TSelectorFunc<ECode>;
}


/** -----------------------------------------
 Action
 /** ---------------------------------------*/
export interface IActionCreators {
    // 系統登入
    login: {account: string, password: string};
    loginBegin: undefined;
    loginSuccess: {accessToken: TAccessToken, avatarUrl: string};
    loginFail: {message: string};

    // 系統登出
    logout: undefined,
    logoutBegin: undefined,
    logoutSuccess: undefined,
    logoutFail: {message: string}

    // 踢出系統
    kickOut: undefined,
}


import {all, call, delay, put, takeLatest} from 'redux-saga/effects';
import {IPickAction} from 'library/redux';
import trim from 'lodash/trim';
import {fetchDataDelayMinMs} from 'config/app';

// Reducers & Types
import {actions as uiBlockActions} from 'store/uiBlock';
import {actions as uiDialogActions} from 'store/uiDialog';
import {actions} from './reducer';
import {IActionCreators} from './types';

// Services
import ApiService, {TLoginRes} from 'services/auth';


/**
 * 系統登入
 * @param action
 */
function* login(action: IPickAction<IActionCreators, 'login'>)  {
    yield put(actions.loginBegin());
    yield put(uiBlockActions.visible());

    try {
        const {account, password} = action.payload;

        const [{body}]: [TLoginRes] = yield all([
            call(ApiService.login, trim(account), trim(password)),
            delay(fetchDataDelayMinMs),
        ]);

        const {accessToken, avatarUrl} = body.data;

        yield put(actions.loginSuccess({accessToken, avatarUrl}));

    } catch (err) {
        yield put(actions.loginFail({message: err.message}));
        yield put(uiDialogActions.openError({message: err.message, code: err.code}));

    } finally {
        yield put(uiBlockActions.hidden());
    }
}

/**
 * 系統登出
 */
function* logout() {
    yield put(actions.logoutBegin());
    yield put(uiBlockActions.visible());

    try {
        yield all([
            call(ApiService.logout),
            delay(fetchDataDelayMinMs),
        ]);


        yield put(actions.logoutSuccess());

    } catch (err) {
        yield put(actions.logoutFail({message: err.message}));

        // 登出錯誤, 強制踢出
        yield put(actions.kickOut());

    } finally {
        yield put(uiBlockActions.hidden());
    }
}

export default [
    takeLatest(actions.login.type, login),
    takeLatest(actions.logout.type, logout),
];

import {ReactNode, useState} from 'react';
import styled, {css, keyframes} from 'styled-components/macro';
import Icon from 'components/atoms/Icon';
import uniqueId from 'lodash/uniqueId';
import Button from '../Button';
import {FCProps} from 'types';

type onClick = () => void;

interface IProps extends FCProps {
    menuTitle?: string | ReactNode;
    isBlock?: boolean;
    contentList?: Array<{
        iconCode?: string;
        text: string | ReactNode;
        onClick: onClick;
        isVisible?: boolean;
    }>;
    width?: string;
    direction?: 'left'|'right',
    isVisibleArrow?: boolean,
}

/**
 * Block Title
 */
const DropDownList: React.FC<IProps> = ({
    style,
    className,
    menuTitle,
    contentList = [],
    isBlock = false,
    width,
    direction = 'left',
    isVisibleArrow= true,
}: IProps) => {
    const [isVisible, setVisible] = useState(false);

    const handleClick = (onClick: onClick) => {
        onClick();
        setVisible(false);
    };

    return (
        <Menu isVisible={isVisible} className={className} style={style}>
            <MenuIconGroup onClick={() => setVisible(!isVisible)}>
                {menuTitle && <Title width={width}>{menuTitle}</Title>}

                {isVisibleArrow && <Icon code="caret-down" color="#7d8490" size={15}/>}
            </MenuIconGroup>

            <MenuList isBlock={isBlock} direction={direction}>
                {contentList.map(row => (
                    <MenuItem key={uniqueId('dropDownList_')} onClick={() => handleClick(row.onClick)}>
                        {row.iconCode && <MenuItemIcon code={row.iconCode} color="#333357" size={19}/>}
                        <ContentText>{row.text}</ContentText>
                    </MenuItem>
                ))}
                <CleanArea onClick={() => setVisible(false)}/>
            </MenuList>
        </Menu>
    );
};

export default DropDownList;

const ContentText = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    -webkit-user-select: none;
    -moz-user-select: none;
`;

const MenuIcon = styled.span`

`;

const Title = styled.span<any>`
    margin-right: 5px;
    margin-left: 5px;
    text-align: left;
    color: #80868e;

    ${props => props.width && css`
        width: ${props.width};
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    `};

`;

const MenuItemIcon = styled(Icon)`
    margin-right: 8px;
`;

const MenuItem = styled.div<any>`
    cursor: pointer;
    min-width: 140px;
    height: 30px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333537;
    font-weight: normal;
    z-index: 2;
    position: relative;
    padding: 8px 10px;
    margin: 0 10px;

    &:hover{
        background: #e8e9e9;

    }

`;

const MenuList = styled.div<any>`
    box-shadow: 0 2px 20px 0 rgba(0,0,0,.25);
    border: 0;
    min-width: 12rem;
    font-size: 14px;

    padding: .5rem 0;
    background-clip: padding-box;
    display: none;
    position: absolute;
    top: calc(100% + 2px);
    background: #fff;
    z-index: 99;
    border-radius: 4px;

    ${props =>  props.isBlock && css`
        width: 100%;
    `}

    ${props => props.direction === 'left' && css`
        left: 0;
    `}

    ${props => props.direction === 'right' && css`
        right: 0;
    `}
`;


const NoticeKeyFrame = keyframes`
    0% {
        box-shadow: 0 0 0 0 rgba(67,74,84,.7);
    }
    70% {
        box-shadow: 0 0 0 10px transparent;
    }
    100% {
        box-shadow: 0 0 0 0 transparent;
    }
`;


const MenuIconGroup = styled(Button)<{
    isActive?: boolean;
    activeColor?: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 28px;
  color: #333537;
  font-size: 14px;
  padding: 0;

  &:hover{

      &:before{
          background-color: rgba(33,33,33,.1);
          opacity: 1;
          transform: scale(1);
      }
  }

  :after {
       content: "";
       border-radius: 100%;
       height: 10px;
       width: 10px;
       background-color: ${props => props.activeColor ?? '#28a745'};
       position: absolute;
       bottom: 13px;
       right: 2px;
       top: 2px;
       opacity: .98;
       display: none;
       z-index: 2;

       transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

      box-shadow: 0 0 0 rgba(67,74,84,.9);
      animation: ${NoticeKeyFrame} 2s infinite;

  }

  &:focus{
      box-shadow: none;
  }

  ${props => props.isActive && css`
      :after{
          display: block;
      }
  `}
`;

//
// const MenuIconGroup = styled.button`
//     font-size: 14px;
//     font-weight: bold;
//     text-transform: uppercase;
//     color: #000;
//     cursor: pointer;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     padding-right: 5px;
//     padding-left: 5px;
//
//     -webkit-user-select: none;
//     -moz-user-select: none;
//
//     ${MenuIcon} > * :hover{
//         color: ${props => props.theme.primaryColor};
//     }
//
//     ${media.lg`
//         font-size: 14px;
//         padding-right: 24px;
//         padding-left: 24px;
//     `}
// `;

const Menu = styled.div<any>`
    display: flex;
    position: relative;
    height: inherit;



    ${props =>  props.isVisible && css`
        background-color: rgba(0, 0, 0, 0.04);

        ${MenuIcon} > * {
            color: ${props.theme.primaryColor};
        }
        ${MenuList}{
            display: block;
        }
    `}
`;

const CleanArea = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
`;


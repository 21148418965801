import styled from 'styled-components/macro';
import {FCChildrenProps} from 'types';


interface IProps extends FCChildrenProps{
    onSubmit: any;
}

const SearchForm = ({
    children,
    onSubmit,
}: IProps) => {
    return (<SearchFormRoot onSubmit={onSubmit}>
        {children}
    </SearchFormRoot>);
};


export default SearchForm;


const SearchFormRoot = styled.form`
  width: 100%;
`;

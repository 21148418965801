import {createSlice} from '@reduxjs/toolkit';
import {IState, ISelector, IActionCreators} from './types';
import {TReducers} from 'library/redux';


/** -----------------------------------------
 Store Key
 /** ---------------------------------------*/
export const name = 'message';


/** -----------------------------------------
 Initial State
 /** ---------------------------------------*/
export const initialState: IState = {
    isFetching: false,
    message: '',

    paginateMeta: {
        totalItems: 0,
        totalPages: 1,
        currentPage: 1,
        pageLimit: 50,
    },
    paginateData: [],

    currentData: [],
    currentMeta: {
        totalItems: 0,
        totalPages: 1,
        currentPage: 1,
        pageLimit: 50,
    },
    currentMemberUser: undefined,

    categoryOutlineData: [],

    notReadCount: 0,
};


/** -----------------------------------------
 Selectors
 /** --------------------------------------*/
export const selector: ISelector = {};



/** -----------------------------------------
 Reducers
 /** ---------------------------------------*/
const reducers: TReducers<IActionCreators, IState> = {
    /**
     * 取得列表清單
     */
    fetchPaginate: (state, action) => {},
    fetchPaginateBegin: (state) => {
        return {...state, isFetching: true, message: ''};
    },
    fetchPaginateSuccess: (state, {payload}) => {
        return {...state, isFetching: false, paginateData: payload.rows, paginateMeta: payload.meta};
    },
    fetchPaginateFail: (state, {payload}) => {
        return {...state, isFetching: false, message: payload.message};
    },

    /**
     * 取得明細資料
     */
    fetchCurrent: (state, action) => {},
    fetchCurrentBegin: (state) => {
        return {...state, isFetching: true, message: ''};
    },
    fetchCurrentSuccess: (state, {payload}) => {
        return {...state, isFetching: false, currentData: payload.rows, currentMeta: payload.meta, currentMemberUser: payload.memberUser};
    },
    fetchCurrentFail: (state, {payload}) => {
        return {...state, isFetching: false, message: payload.message};
    },



    /**
     * 取得明細資料
     */
    fetchCategoryOutline: (state, action) => {},
    fetchCategoryOutlineBegin: (state) => {
        return {...state, isFetching: true, message: ''};
    },
    fetchCategoryOutlineSuccess: (state, {payload}) => {
        return {...state, isFetching: false, categoryOutlineData: payload.data};
    },
    fetchCategoryOutlineFail: (state, {payload}) => {
        return {...state, isFetching: false, message: payload.message};
    },

    /**
     * 新增資料
     */
    createData: (state, action) => {},
    createDataBegin: (state, {payload}) => {
        return {...state, isFetching: false, currentData: [...state.currentData, {
            ...payload.data,
            isSubmitting: true,
        }], message: ''};
    },
    createDataSuccess: (state, {payload}) => {
        state.currentData[payload.currentIndex].isSubmitting = false;
        state.isFetching = false;
    },
    createDataFail: (state, {payload}) => {
        return {...state, isFetching: false, message: payload.message};
    },

    /**
     * 更改未讀訊息
     */
    setNotReadCount: (state, {payload}) => {
        return {...state, notReadCount: payload.count};
    },
};


export const {reducer, actions} = createSlice({name, initialState, reducers});

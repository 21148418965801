import {Switch, Route} from 'react-router-dom';
import SignRoute from 'library/react-router/SignRoute';
import {PrivateRoute} from 'library/react-router';

// Views
import NotFound from 'views/NotFound';
import HomeRoot from 'views/HomeRoot';
import Login from 'views/Sign/Login';




const Router = () => {

    return (
        <Switch>
            <SignRoute path="/sign/login" exact children={<Login/>}/>
            <PrivateRoute path="/" children={<HomeRoot/>}/>

            {/* NotFound */}
            <Route path="*" children={<NotFound/>}/>

        </Switch>
    );
};

export default Router;

import {createSlice} from '@reduxjs/toolkit';
import {IState, ISelector, IActionCreators} from './types';
import {TReducers} from 'library/redux';


/** -----------------------------------------
                Store Key
 /** ---------------------------------------*/
export const name = 'system';


/** -----------------------------------------
             Initial State
 /** ---------------------------------------*/
export const initialState: IState = {
    isFetching: false,
    isDone: false,
    message: '',

    countryCodeList: [],
    iMoneyName: '',
    maintainSetting: {
        isMaintain: false,
        maintainEndTime: '',
        maintainMessage: '',
    },
    outSiteUrl: {
        faq: '',
        instruction: '',
        promotion: '',
    },
    appDownloadUrl: {
        android: '',
        ios: '',
    },
    visibleColumn: {
        isDisplayDepositDocUpload: false,
        isHasBlockAdvertisement: false,
        isRequiredDepositDocUpload: false,
        isShowIPointRecord: false,
        isShowReferral: false,
    },
    profileSetting: {
        isVerifyBankBranch: false,
        isVerifyEmail: false,
        isVerifyLine: false,
        isVerifyPhone: false,
        isVerifyQQ: false,
        isVerifySkype: false,
        isVerifyWeChat: false,
        isVerifyWhatsApp: false,
        isVerifyICCard: false,
    },
    signUpSetting: {
        isVisibleMobile: false,
        isVerifyMobile:  false,
        isVisibleRealName: false,
        isVerifyRealName: false,
    }
};


/** -----------------------------------------
            Selectors
 /** --------------------------------------*/
export const Selector: ISelector = {};



/** -----------------------------------------
                Reducers
 /** ---------------------------------------*/
const reducers: TReducers<IActionCreators, IState> = {
    /**
     * 取得系統設定
     */
    fetchSetting: (state, action) => {},
    fetchSettingBegin: (state) => {
        return {...state, isFetching: true, isDone: false, message: ''};
    },
    fetchSettingSuccess: (state, {payload}) => {
        return {...state, isFetching: false, isDone: true, ...payload};
    },
    fetchSettingFail: (state, {payload}) => {
        return {...state, isFetching: false, message: payload.message};
    },
};


export const {reducer, actions} = createSlice({name, initialState, reducers});

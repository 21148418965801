import {useMemo} from 'react';
import {Col, Container, Row} from 'library/styled-bs-grid';

// Components
import Button from 'components/atoms/Button';


interface IProps {
    data?: Array<{
        text: string,
        color?: 'primary'| 'success'| 'danger' | 'gray' | 'info';
        onClick: () => void
        isVisible?: boolean
    }>,
}

const ListFeatureGroup = ({
    data = [],
}: IProps) => {

    const renderButton = useMemo(() => {

        return data
            .filter(row => row.isVisible !== false)
            .map((row,index) => {
                return (<Col col="auto" className="mr-2" key={`listFeatureGroup_${index}`}>
                    <Button size="small" color={row.color} onClick={row.onClick}>{row.text}</Button>
                </Col>);
            });

    }, [data]);

    return (<Container fluid className="mb-3">
        <Row noGutters>
            {renderButton}
        </Row>
    </Container>);
};


export default ListFeatureGroup;



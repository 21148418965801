import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {actions as UiDialogAction} from 'store/uiDialog';

// Components
import {DialogModal} from 'components/atoms/Dialog';


const GlobalDialog: React.FC = () => {
    const dispatch = useDispatch();

    const {uiDialog} = useSelector(state => ({
        uiDialog: state.uiDialog,
    }));

    return (
        <DialogModal
            isVisible={uiDialog.isVisible}
            type={uiDialog.type}
            title={uiDialog.title}
            message={uiDialog.message}
            code={uiDialog.code}
            buttons={uiDialog.buttons}
            onClose={() => dispatch(UiDialogAction.close())}
        />
    );
};

export default GlobalDialog;


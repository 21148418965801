import styled, {css} from 'styled-components/macro';
import {isEmpty} from 'utils/equal';
import {FCProps} from 'types';

interface IProps extends FCProps {
    size?: number;
    image?: string;
    onEdit?: () => void;
}

/**
 * Block Title
 */
const Avatar = ({
    style,
    className,
    size = 35,
    image,
    onEdit,
}: IProps) => {

    return (<AvatarRoot
        style={style}
        className={className}
        size={size}
        image={image}
        // onClick={onEdit}
        isEdit={!isEmpty(onEdit)}
    />);
};

export default Avatar;




const AvatarRoot = styled.div<{
    size?: number;
    image?: string;
    isEdit?: boolean;
}>`
    position: relative;
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    border-radius: 100%;
    overflow: hidden;
    //margin-right: 15px;
    flex: 0 0 auto;
    border: 2px solid #343a40;
    background: #dde2e7 url(${props => props.image}) center center;
    background-size: cover;
    cursor: ${props => props.isEdit ? 'pointer' : 'auto'};


    :before{
        content: 'Edit';
        position: absolute;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 5px;
        font-size: 12px;
        color: #fff;

        height: 25px;
        left: 0;
        bottom: -25px;
        width: 100%;
        background-color: rgba(50, 50, 50, .8);
        opacity: 0;
        transition: opacity .3s, bottom .3s;
    }

      ${props => props.isEdit && css`
        :hover{
           :before{
              opacity: 1;
              bottom: 0;
           }
        }
    `}
`;

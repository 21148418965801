import {createSlice} from '@reduxjs/toolkit';
import {IState, ISelector, IActionCreators} from './types';
import {TReducers} from 'library/redux';


/** -----------------------------------------
                Store Key
 /** ---------------------------------------*/
export const name = 'report';


/** -----------------------------------------
             Initial State
 /** ---------------------------------------*/
export const initialState: IState = {
    isFetching: false,
    message: '',


    paginateData: undefined,

    settingData: {
        stores: [],
    },

};


/** -----------------------------------------
            Selectors
 /** --------------------------------------*/
export const Selector: ISelector = {};



/** -----------------------------------------
                Reducers
 /** ---------------------------------------*/
const reducers: TReducers<IActionCreators, IState> = {
    /**
     * 取得列表清單
     */
    fetchPaginate: (state, action) => {},
    fetchPaginateBegin: (state) => {
        return {...state, isFetching: true, message: ''};
    },
    fetchPaginateSuccess: (state, {payload}) => {
        return {...state, isFetching: false, paginateData: payload.data};
    },
    fetchPaginateFail: (state, {payload}) => {
        return {...state, isFetching: false, message: payload.message};
    },
    /**
     * 取得設定資料
     */
    fetchSetting: (state, action) => {},
    fetchSettingBegin: (state) => {
        return {...state, isFetching: true, message: ''};
    },
    fetchSettingSuccess: (state, {payload}) => {
        return {...state, isFetching: false, settingData: payload.data};
    },
    fetchSettingFail: (state, {payload}) => {
        return {...state, isFetching: false, message: payload.message};
    },

};


export const {reducer, actions} = createSlice({name, initialState, reducers});

import {ReactNode, useCallback} from 'react';
import styled from 'styled-components/macro';

// Components
import Card from 'components/atoms/Card';
import TableFooter from './TableFooter';
import Table from './Table';

import {UseFormMethods} from 'react-hook-form';
import CSS from 'csstype';
import {FCProps} from 'types';


interface IProps extends FCProps {
    cardStyle?: CSS.Properties;
    hookForm?: UseFormMethods<{checkedId: {
        [key: string]: boolean
    }}>,
    title: Array<{
        field: string,
        text: string|number|ReactNode,
        col?: number|'auto'|true,
        width?: number,
        titleAlign?: 'left'|'center'|'right',
        dataAlign?: 'left'|'center'|'right',
        dataVertical?: 'top'|'center'|'bottom',
        className?: string,
        isSort?: boolean,
        sortBy?: 'DESC'|'ASC',
    }>,
    data: Array<{
        id: number,
        [field: string]: any,
        appendData?: any,
        onClickRow?: () => void,
    }>,
    meta?: {
        currentPage: number,
        pageLimit: number,
        totalItems: number,
        totalPages: number,
        sortField?: string,
        sortBy?: 'DESC'|'ASC',
    }
    bodyHeight?: number,
    mode?: 'default'|'nonLine',
    trColor?: string,
    isEnableChecked?: boolean,
    isVisibleHeader?: boolean,
    isVisibleActions?: boolean,
    isVisiblePaginate?: boolean,
    isFetching?: boolean,
    onChangePage?: (currentPage: number, limitPage?: number, sortField?: string, sortBy?: 'DESC'|'ASC') => void;
    onEditRow?: (id: number, isOpen: boolean) => void;
    onDeleteRow?: (id: number) => void;
}


/**
 * CardTable
 */
const CardTable = ({
    style,
    className,
    cardStyle,
    hookForm,
    data= [],
    title= [],
    meta= {
        currentPage: 1,
        pageLimit: 50,
        totalItems: 0,
        totalPages: 1,
    },
    bodyHeight,
    mode = 'default',
    trColor,
    isEnableChecked = true,
    isVisibleHeader = true,
    isVisibleActions = true,
    isVisiblePaginate = true,
    isFetching = false,
    onEditRow,
    onDeleteRow,
    onChangePage,
}: IProps) => {

    const handleOnChangePage = useCallback((currentPage: number, limitPage?: number, sortField?: string, sortBy?: 'DESC'|'ASC') => {

        window.scrollTo(0, 70);

        if(onChangePage){
            onChangePage(currentPage, limitPage, sortField, sortBy);
        }

    }, [onChangePage]);

    /**
     * 全選控制
     */
    const handleCheckedAll = useCallback((isChecked: boolean) => {
        if(hookForm){
            const keys = hookForm.getValues();
            for(const key of Object.keys(keys.checkedId)){
                hookForm.setValue(`checkedId[${key}]`, isChecked);
            }
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hookForm]);


    return (
        <TableRoot style={style} className={className}>

            <Card
                style={cardStyle}
                direction="column" fluid isNonLine={mode === 'nonLine'} isFetching={isFetching}>
                <Table
                    onCheckedAll={handleCheckedAll}
                    isVisibleHeader={isVisibleHeader}
                    isVisibleActions={isVisibleActions}
                    hookForm={hookForm}
                    data={data}
                    title={title}
                    bodyHeight={bodyHeight}
                    mode={mode}
                    isEnableChecked={isEnableChecked}
                    onEditRow={onEditRow}
                    onDeleteRow={onDeleteRow}
                    trColor={trColor}
                    sortField={meta?.sortField}
                    sortBy={meta?.sortBy}
                    onChangePage={handleOnChangePage}
                />
            </Card>

            {/* 頁尾 */}
            {(isVisiblePaginate && onChangePage) && (
                <TableFooter meta={meta} onChangePage={handleOnChangePage}/>
            )}
        </TableRoot>
    );
};

export default CardTable;


const TableRoot = styled.div`
    margin-bottom: 20px;
`;

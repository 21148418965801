import {useMemo} from 'react';
import styled, {css} from 'styled-components/macro';
import A from 'components/atoms/A';


interface IProps {
    data: Array<{
        text: string, href: string, isVisible?: boolean
    }>,
    currentHref?: string,
}

const Tabs = ({
    data = [],
    currentHref
}: IProps) => {

    const renderData = useMemo(() => {
        return data
            .filter(row => row.isVisible !== false)
            .map(row => {
                return (<TabMenu isActive={row.href === currentHref} key={row.text}>
                    <TabLink href={row.href}>
                        {row.text}
                    </TabLink>
                </TabMenu>);
            });

    }, [currentHref, data]);

    return (<TabsRoot>{renderData}</TabsRoot>);
};


export default Tabs;


const TabLink = styled(A)`
    padding: 14px 0;
    display: block;
    color: #9aa0ac;
    background: 0 0;
    border-top: 1px solid;
    border-color: transparent;
    border-radius: 0;
    font-size:15px;

    &:hover{
        color: #6e7687;
        border-color: #6e7687;
    }
`;

const TabMenu = styled.li<{
    isActive?: boolean;
}>`
    padding: 0 12px;
    border-top: 1px solid;

        ${props => props.isActive && css`
            ${TabLink}, ${TabLink}:hover{
                border-color: #2185d0;
                color: #2185d0;
                background: 0 0;
            }
        `}
`;

const TabsRoot = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    border-color: #343a40;
    margin-top: -1px;
    margin-left: -10px;
    margin-right: -10px;

    z-index: 4;
    position: relative;

    transition: all .3s ease-in-out;
    color: #9aa0ac;
    user-select: none;

`;

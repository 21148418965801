import {ReactNode} from 'react';
import styled from 'styled-components/macro';
import {UseFormMethods} from 'react-hook-form';
import {asset} from 'config/app';

// Components
import TableBody from './TableBody';
import TableHeader from './TableHeader';


interface IProps {
    hookForm?: UseFormMethods<{checkedId: {
            [key: string]: boolean
    }}>,
    title: Array<{
        field: string,
        text: string|number|ReactNode,
        col?: number|'auto'|true,
        width?: number,
        titleAlign?: 'left'|'center'|'right',
        dataAlign?: 'left'|'center'|'right',
        dataVertical?: 'top'|'center'|'bottom',
        className?: string,
        isSort?: boolean,
        sortBy?: 'DESC'|'ASC',
    }>,
    data?: Array<{
        id: number,
        [field: string]: any,
        appendData?: any,
        onClickRow?: () => void,
    }>,
    bodyHeight?: number,
    mode?: 'default'|'nonLine',
    trColor?: string,
    isEnableChecked?: boolean,
    isVisibleHeader?: boolean,
    isVisibleActions?: boolean,
    onEditRow?: (id: number, isOpen: boolean) => void;
    onDeleteRow?: (id: number) => void;
    onCheckedAll?: (isChecked: boolean) => void;
    sortField?: string,
    sortBy?: 'DESC'|'ASC',
    onChangePage?: (targetPage: number, limitPage?: number, sortField?: string, sortBy?: 'DESC'|'ASC') => void;
}


/**
 * Table
 */
const Table = ({
    hookForm,
    title,
    data= [],
    bodyHeight,
    mode = 'default',
    trColor,
    isEnableChecked = true,
    isVisibleHeader = true,
    isVisibleActions = false,
    onEditRow,
    onDeleteRow,
    onCheckedAll= () => {},
    sortField,
    sortBy,
    onChangePage
}: IProps) => {


    return (
        <>
            {isVisibleHeader && (<TableHeader
                title={title}
                isVisibleActions={isVisibleActions}
                isEnableChecked={isEnableChecked}
                onCheckedAll={onCheckedAll}
                isNonLine={mode === 'nonLine'}

                sortField={sortField}
                sortBy={sortBy}
                onChangePage={onChangePage}
            />)}

            {/* 表格內容 */}
            {data.length > 0 ? (
                <TableBody
                    hookFormControl={hookForm?.control}
                    isVisibleActions={isVisibleActions}
                    title={title}
                    data={data}
                    height={bodyHeight}
                    trColor={trColor}
                    isEnableChecked={isEnableChecked}
                    onDeleteRow={onDeleteRow}
                    onEditRow={onEditRow}
                    isNonLine={mode === 'nonLine'}
                />
            ): (<NotData>
                <NotDataImage src={asset('/images/no-email.svg')}/>
                <NotDataText>Not Found</NotDataText>
                <NotDataDesc>Choose a different filter to view test results to you</NotDataDesc>
            </NotData>)}
        </>
    );
};

export default Table;



const NotDataDesc = styled.span`
  font-size: 14px;
  color: #7d8490;
`;

const NotDataText = styled.div`
  font-size: 24px;
  color: #7d8490;
`;


const NotDataImage = styled.img`
  height: 200px;
  width: auto;
  margin-bottom: 10px;
`;


const NotData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 30px;
`;

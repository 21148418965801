import {useEffect, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components/macro';
import {Col, Container, Row} from 'library/styled-bs-grid';
import {formatCurrency} from 'utils/number';

// Components
import Card from 'components/atoms/Card';
import PageHeader from 'components/organisms/PageHeader';
import Icon from 'components/atoms/Icon';

// Stores
import {actions as DashboardAction} from 'store/dashboard';


/**
 * 商品系統/品牌管理/編輯
 */
const Dashboard = () => {
    const history = useHistory();
    const baseRoutePath = '/dashboard';

    const dispatch = useDispatch();

    const {siteInfo, isFetching} = useSelector(state => ({
        siteInfo: state.dashboard.siteInfo,
        isFetching: state.dashboard.isFetching,
    }));

    const siteInfoData = [
        {
            icon: 'box-filter',
            color: '#28a745',
            title: '本日累計商品數',
            value: isFetching ? '讀取中...' : `${formatCurrency(siteInfo?.productGoodsCount || 0)}項`,
            path: '/product/goods',
        },
        {
            icon: 'deposit',
            color: '#ffc107',
            title: '本日累計匯款單數',
            value: isFetching ? '讀取中...' : `${formatCurrency(siteInfo?.depositCreateCount || 0)}筆`,
            path: '/member/depositHistory',
        },
        {
            icon: 'cart',
            color: '#dc3545',
            title: '本月累計訂單量',
            value: isFetching ? '讀取中...' : `${formatCurrency(siteInfo?.orderCreateCount || 0)}筆`,
            path: '/order/head',
        },
        {
            icon: 'user-astronaut',
            color: '#007bff',
            title: '本月累計會員數',
            value: isFetching ? '讀取中...' : `${formatCurrency(siteInfo?.memberCreateCount || 0)}人`,
            path: '/member/user',
        },
    ];

    const memoSiteInfoData = useMemo(() => {
        return siteInfoData.map(row => {
            return (<Col col={24} lg={12} xl={6} key={row.icon}>
                <Card direction="row"
                    onClick={() => history.push(row.path)}
                    style={{cursor: 'pointer'}}
                >
                    <Icon code={row.icon} color={row.color} size={45}/>
                    <SiteInfo>
                        <SiteInfoTitle>{row.title}</SiteInfoTitle>
                        <SiteInfoValue>{row.value}</SiteInfoValue>
                    </SiteInfo>
                </Card>
            </Col>);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteInfo, isFetching]);

    useEffect(() => {
        dispatch(DashboardAction.fetchSiteInfo());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <PageRoot>
            <PageHeader
                breadcrumbData={[
                    {text: 'Welcome to Dashboard'},
                ]}
                tabsData={[
                    {text: 'Dashboard', href: baseRoutePath},
                ]}
            />

            {/* 內容 */}
            <Container fluid>
                <Row>
                    {/*{memoSiteInfoData}*/}
                </Row>



            </Container>

        </PageRoot>
    );
};

export default Dashboard;


const SiteInfoValue = styled.h6`
    font-size: 13px;
`;

const SiteInfoTitle = styled.span`
    font-size: 15px;
`;

const SiteInfo = styled.div`
      color: #7d8490;
      margin-left: 20px;
`;

const PageRoot = styled.div`
    padding: 0 20px;
`;

import {sec2ms} from 'utils/number';

const staticPrefixUrl = '/static';
const uploadPrefixUrl = '/uploads';
const routePrefixPath = '';
const api = {
    baseUrl: '/api',
    timeout: sec2ms(120)
};
const persistKey = 'persist:kr8851-ec-admin';
const persistWhileList = [
    'language.locale',
    'auth.accessToken',
    'auth.avatarUrl',
    'auth.name',
    'rootNotice.notReadCount',
    'message.notReadCount',
];
const persistVersion = '1.0.0';
const fetchDataDelayMinMs = 120;

const asset = (path: string = '') => `${staticPrefixUrl}${path}`;
const uploadUrl = (path: string = '') => `${uploadPrefixUrl}/${path}`;


export {
    staticPrefixUrl,
    api,
    persistKey,
    persistWhileList,
    persistVersion,
    fetchDataDelayMinMs,
    routePrefixPath,
    asset,
    uploadUrl,

};

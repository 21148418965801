import {all, delay, call, put, select, takeLatest} from 'redux-saga/effects';
import {IPickAction} from 'library/redux';
import {fetchDataDelayMinMs} from 'config/app';

// Reducers & Types
import {actions} from './reducer';
import {IActionCreators} from './types';
import {actions as uiBlockActions} from 'store/uiBlock';

// Services
import ApiService, {TGetPaginateRes, TGetSettingsRes} from 'services/report';
import {replace} from 'connected-react-router';
import {actions as uiDialogActions} from 'store/uiDialog';




/**
 * 取列表資料
 */
function* fetchPaginate(action: IPickAction<IActionCreators, 'fetchPaginate'>) {
    yield put(actions.fetchPaginateBegin());

    const {payload} = action;

    try {
        const [{body}]: [TGetPaginateRes] = yield all([
            call(ApiService.getPaginate,
                payload.type,
                payload?.startDate,
                payload?.endDate,
                payload?.storeId,
            ),
            delay(fetchDataDelayMinMs),
        ]);


        yield put(actions.fetchPaginateSuccess({data: body.data}));

    } catch (err) {
        yield put(actions.fetchPaginateFail({message: err.message}));
        yield put(uiDialogActions.openError({message: err.message, code: err.code}));

    }
}


/**
 * 取設定資料
 */
function* fetchSetting(action: IPickAction<IActionCreators, 'fetchSetting'>) {
    yield put(actions.fetchSettingBegin());

    try {
        const [{body}]: [TGetSettingsRes] = yield all([
            call(ApiService.getSettings),
            delay(fetchDataDelayMinMs),
        ]);

        yield put(actions.fetchSettingSuccess({data: body.data}));

    } catch (err) {
        yield put(actions.fetchSettingFail({message: err.message}));
        yield put(uiDialogActions.openError({message: err.message, code: err.code}));

    }
}




export default [
    takeLatest(actions.fetchPaginate.type, fetchPaginate),
    takeLatest(actions.fetchSetting.type, fetchSetting),


];

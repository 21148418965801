import {RefObject, useCallback, useMemo} from 'react';
import styled, {css} from 'styled-components/macro';
import {isEmpty} from 'utils/equal';

// Components
import Icon from 'components/atoms/Icon';
import {FCProps} from 'types';


interface IProps extends FCProps{
    type?: 'text' | 'number' | 'password' | 'tel';
    placeholder?: string;
    value?: string;
    readOnly?: boolean;
    disabled?: boolean;
    beforeIconCode?: string;
    beforeIconOnClick?: Function;
    maxLength?: number;
    onChange?: (value: string) => void;
    onClick?: Function;
    autoComplete?: string;
    required?: boolean;
    forwardRef?: RefObject<HTMLInputElement>;
}

/**
 * Input Component
 *
 * @param props
 * @returns {*}
 */
const TextField = ({
    className,
    style,

    type = 'text',
    value='',
    placeholder = '',
    readOnly = false,
    disabled = false,
    autoComplete,
    beforeIconCode,
    beforeIconOnClick,
    maxLength,
    onChange = () => {},
    required,
    forwardRef,
}: IProps) => {


    /**
     * 處理值改變
     */
    const handleChange = useCallback((value: string) => {
        onChange(value);
    }, [onChange]);

    const BaseInput = useMemo(() => {
        return <Input
            ref={forwardRef}
            type={type}
            className={className}
            style={style}
            onChange={handleChange}
            placeholder={placeholder}
            readOnly={readOnly}
            disabled={disabled}
            autoComplete={autoComplete}
            maxLength={maxLength}
            required={required}
            value={value}
        />;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoComplete, className, disabled, handleChange, maxLength, placeholder, readOnly, style, type, value]);

    return (
        <TextFieldRoot>
            {beforeIconCode &&
                <InputGroupPrepend onClick={beforeIconOnClick}>
                    <InputGroupText>
                        <Icon code={beforeIconCode} color="#495057"/>
                    </InputGroupText>
                </InputGroupPrepend>
            }

            {BaseInput}
        </TextFieldRoot>

    );
};

export default TextField;

const InputGroupText = styled.span`
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #343a40;
    border-right: 0;
    background: 0 0;

    display: flex;
    width: 41px;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    color: #495057;

`;

const Input = styled.input<{
    onChange: any,
}>`
    position: relative;
    display: block;
    width: 100%;
    height: 35px;
    font-size: 14px;
    padding: 6px 12px;
    font-weight: 400;
    line-height: 21px;
    color: #c8cfd6;

    background: 0 0;
    background-clip: padding-box;

    border: 1px solid #343a40;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    flex: 1;
    margin-bottom: 0;

    :focus{
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
    }

    ::-webkit-input-placeholder, :read-only, :disabled {
      color: #495057;
    }
`;



const InputGroupPrepend = styled.div<any>`
    display: flex;
    margin-right: -1px;
    flex: 0;

    ${props => !isEmpty(props.onClick) && css`
         cursor: pointer;
    `}

    + ${Input}{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
`;

const TextFieldRoot = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
`;

import {Switch, Route} from 'react-router-dom';

// Views
import NotFound from 'views/NotFound';
import RootUserView from 'views/HomeRoot/Root/User/Detail/View';
import RootUserAccount from 'views/HomeRoot/Root/User/Detail/Account';
import RootUserPassword from 'views/HomeRoot/Root/User/Detail/Password';


const Router = () => {
    return (
        <Switch>
            <Route path="/root/user/:id/password" exact component={RootUserPassword}/>
            <Route path="/root/user/:id/account" exact component={RootUserAccount}/>
            <Route path="/root/user/:id" exact component={RootUserView}/>

            <Route path="*" component={NotFound}/>
        </Switch>
    );
};

export default Router;

import {useCallback} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {Container} from 'library/styled-bs-grid';

// Components
import PasswordField from 'components/forms/PasswordField';
import Card from 'components/atoms/Card';
import Button from 'components/atoms/Button';
import FormHorizontalGroup from 'components/forms/FormHorizontalGroup';

// Stores
import {actions as rootUserActions} from 'store/rootUser';

import {IFormData} from './types';
import {selector as authSelector} from '../../../../../../store/auth';

/**
 * 管理者管理/更改密碼
 */
const Password = () => {
    const params = useParams<{id: string}>();
    const currentId = Number(params.id);

    const dispatch = useDispatch();
    const profileId = useSelector(authSelector.payload)?.id;

    const {handleSubmit, control, reset} = useForm<IFormData>();

    /**
     * 送出表單
     * @param data
     */
    const handleSubmitHandler: SubmitHandler<IFormData> = useCallback(formData => {

        const id = params.id === 'me' ? profileId: currentId;

        if(id){
            dispatch(rootUserActions.updatePassword({
                id,
                data: formData
            }));

            reset({
                password: '',
                confirmPassword: '',
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container fluid>
            <Card direction="column">
                <form onSubmit={handleSubmit(handleSubmitHandler)}>

                    <FormHorizontalGroup label="New Password">
                        <Controller
                            control={control}
                            name="password"
                            defaultValue=""
                            render={({onChange, value}) => {
                                return (<PasswordField
                                    onChange={onChange}
                                    value={value}
                                />);
                            }}
                        />
                    </FormHorizontalGroup>

                    <FormHorizontalGroup label="Confirm Password">
                        <Controller
                            control={control}
                            name="confirmPassword"
                            defaultValue=""
                            render={({onChange, value}) => {
                                return (<PasswordField
                                    onChange={onChange}
                                    value={value}
                                />);
                            }}
                        />
                    </FormHorizontalGroup>

                    <FormHorizontalGroup>
                        <Button type="submit" color="primary">Change Password</Button>
                    </FormHorizontalGroup>

                </form>
            </Card>
        </Container>
    );
};

export default Password;

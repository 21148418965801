import {all} from 'redux-saga/effects';

import {saga as startUp} from './startUp';
// import {saga as language} from './language';
// import {saga as system} from './system';
import {saga as auth} from './auth';


import {saga as dashboard} from './dashboard';

import {saga as rootUser} from './rootUser';
import {saga as rootRole} from './rootRole';
import {saga as rootNotice} from './rootNotice';


import {saga as message} from './message';


import {saga as memberUser} from './memberUser';
import {saga as memberBalance} from './memberBalance';

import {saga as store} from './store';
import {saga as job} from './job';
import {saga as report} from './report';

import {saga as settingSite} from './settingSite';


/** -----------------------------------------
 Connect sagas
 /** --------------------------------------*/
export default function* sagas() {
    yield all([
        ...startUp,
        // ...language,
        // ...system,
        ...auth,


        ...dashboard,

        ...rootUser,
        ...rootRole,
        ...rootNotice,



        ...message,


        ...settingSite,

        ...memberUser,
        ...memberBalance,

        ...store,
        ...job,
        ...report,

    ]);
}

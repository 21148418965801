import styled, {css} from 'styled-components/macro';
import {FCChildrenProps} from 'types';


interface IProps extends FCChildrenProps{
    status: 'success'|'gray'|'danger'|'warning',
}

/**
 * Label
 *
 * @param children
 * @param status
 */
const Label = ({
    children,
    status,
}: IProps) => {
    return (<LabelRoot status={status}>{children}</LabelRoot>);
};


export default Label;

const LabelRoot = styled.label<{
    status?: 'success'|'gray'|'danger'|'warning',
}>`

    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;


    border-radius: 3px;
    font-size: 12px;
    color: #6e7687;
    font-weight: 400;
    background-color: #d1d3d4;
    padding: 0 8px;
    cursor: default;
    user-select: none;

    ${props => props.status === 'success' && css`
        background-color: #21ba45;
        color: #fff;
    `}

    ${props => props.status === 'warning' && css`
        background-color: #a2981d;
        color: #fff;
    `}

    ${props => props.status === 'danger' && css`
        background-color: #bd2c00;
        color: #fff;
    `}
`;

/* eslint-disable no-template-curly-in-string */


export default {
    /** -----------------------------------------
                    系統錯誤訊息
    /** ---------------------------------------*/
    'errorHttp.400': 'The requested parameter is wrong',
    'errorHttp.401': 'Please login before continuing',
    'errorHttp.404': 'Request Not Found/Route',
    'errorHttp.413': 'The request/file sent exceeds the server limit size',
    'errorHttp.500': 'Internal Server Error',
    'errorHttp.503': 'System under maintenance',
    'errorHttp.504': 'Please check your network and try again',
    'errorHttp.511': 'Area not available',
    'errorHttp.CLIENT_ERROR': 'Any non-specific 400 series error',
    'errorHttp.SERVER_ERROR': 'Any 500 series error',
    'errorHttp.TIMEOUT_ERROR': 'The server has not responded for more than {sec} seconds. Please confirm your network connection status or contact customer service',
    'errorHttp.CONNECTION_ERROR': 'Server not available, bad dns',
    'errorHttp.NETWORK_ERROR': 'Your mobile network connection is unstable. Please check your network connection status and try again.',
    'errorHttp.CANCEL_ERROR': 'Request has been cancelled. Only possible if `cancelToken` is provided in config, see axios `Cancellation`',




    /** -----------------------------------------
                    表單錯誤訊息
    /** ---------------------------------------*/
    'errorForm.require': 'The {name} not be between required',
    'errorForm.tooShortString': 'Not less than {length}',
    'errorForm.tooLongString': 'Not greater than {length}',
    'errorForm.tooShortNumber': 'Not less than {length}',
    'errorForm.tooLongNumber': 'Not greater than {length}',
    'errorForm.tooRange': 'The {name} must be between {min}-{max}',
    'errorForm.invalid': 'Please match the correct format of the {name}',




    /** -----------------------------------------
                        元件
     /** ---------------------------------------*/
    // Calendar
    'com.calendar.month.1': 'January',
    'com.calendar.month.2': 'February',
    'com.calendar.month.3': 'March',
    'com.calendar.month.4': 'April',
    'com.calendar.month.5': 'May',
    'com.calendar.month.6': 'June',
    'com.calendar.month.7': 'July',
    'com.calendar.month.8': 'August',
    'com.calendar.month.9': 'September',
    'com.calendar.month.10': 'October',
    'com.calendar.month.11': 'November',
    'com.calendar.month.12': 'December',
    'com.calendar.weekDay.1': 'Mo',
    'com.calendar.weekDay.2': 'Tu',
    'com.calendar.weekDay.3': 'We',
    'com.calendar.weekDay.4': 'Th',
    'com.calendar.weekDay.5': 'Fr',
    'com.calendar.weekDay.6': 'Sa',
    'com.calendar.weekDay.7': 'Su',
    'com.calendar.unit.year': ' ',
    'com.calendar.pleaseInputYear': 'Please enter the first year',
    'com.calendar.setToday': 'Set to today',

    // uiBlock
    'com.uiBlock.loading': 'loading...',

    // uiDialog
    'com.uiDialog.success': 'SUCCESS',
    'com.uiDialog.failed': 'FAILED',
    'com.uiDialog.confirm': 'CONFIRM',
    'com.uiDialog.ok': 'OK',
    'com.uiDialog.cancel': 'Cancel',

    // TimePicker
    'com.timePicker.now': 'Now',
    'com.timePicker.ok': 'OK',
};

import {Route, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {isEmpty} from 'utils/equal';

// Stores
import {selector as authSelector} from 'store/auth';

import {FCChildrenProps} from 'types';


interface IProps extends FCChildrenProps {
    exact?: boolean,
    path: string;
}

/**
 *
 * @param Component
 * @param path 路由路徑
 * @param exact 路由路徑是否需完整比對
 *              ex: sign => sign/login (false)
 *                  sign => sign       (true)
 */
const PermissionRoute = ({
    children,
    path,
    exact = false,
}: IProps) => {

    const {permissions} = useSelector(state => ({
        permissions: authSelector.permissions(state),
    }));

    // 如果擁有Token則返回指定頁面, 若過期或無效則觸發API時由後端通知
    const notAuthGoPath = '/dashboard';

    const isNotAuth = isEmpty(permissions.find(row => path.startsWith(row)));


    return (
        <Route
            path={path}
            exact={exact}
            children={isNotAuth ? (<Redirect to={notAuthGoPath}/>): children}
        />
    );
};

export default PermissionRoute;

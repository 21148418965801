import api from 'library/apisauce';

import {TGetPaginateRes, TDeleteDataRes, TGetDetailRes} from './types';


export default {
    /**
     * 查詢 列表資料
     */
    getPaginate: (currentPage: number, pageLimit: number): Promise<TGetPaginateRes> => {
        return api.get('/root/notice/detail', {
            currentPage,
            pageLimit,
        }, {
            headers: {isAuth: true},
        });
    },

    /**
     * 查詢 明細資料
     */
    getDetail: (id: number): Promise<TGetDetailRes> => {
        return api.get(`/root/notice/detail/${id}`, {}, {
            headers: {isAuth: true},
        });
    },

    /**
     * 刪除
     */
    deleteData: (ids: number[]): Promise<TDeleteDataRes> => {
        return api.delete('/root/notice/detail', {ids}, {
            headers: {isAuth: true},
        });
    },
};

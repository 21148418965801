import {ISiteConfig} from 'config/types';
import {siteConfigs} from 'config/site';


/**
 * 取得站台設定
 */
export function getSiteConfig(): ISiteConfig {
    return siteConfigs;

}


/**
 * 取得後台上傳檔案的路徑資源網址
 */
export function uploadUrl(path?: string): string{
    if(path){
        // 去除參數第一個斜線
        return `/uploads/${path.replace(/^\//, '')}`;
    }
    return '';
}


/**
 * 取得前端資源網址
 */
export function asset(path?: string): string{
    if(path){
        // 去除參數第一個斜線
        return `/static/${path.replace(/^\//, '')}`;
    }
    return '';
}

import {useEffect, useCallback} from 'react';
import styled from 'styled-components/macro';
import dayjs from 'dayjs';
import {Container, Row, Col} from 'library/styled-bs-grid';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {objFilterNotTrue2Array} from 'utils/format';

// Components
import {CardTable as Table} from 'components/atoms/Table';
import Card from 'components/atoms/Card';
import TextField from 'components/forms/TextField';
import PageHeader from 'components/organisms/PageHeader';
import Button from 'components/atoms/Button';
import Label from 'components/atoms/Label';
import Avatar from 'components/atoms/Avatar';
import ListFeatureGroup from 'components/organisms/ListFeatureGroup';

// Stores
import {actions as rootUserActions} from 'store/rootUser';
import {IFormData} from './types';




/**
 * 管理者管理/列表
 */
const List = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const tableForm = useForm<{checkedId: {
            [key: string]: boolean
        }}>();
    const searchForm = useForm<IFormData>();

    const baseRoutePath = '/root/user';

    const {paginateData, paginateMeta, isFetching} = useSelector(state => ({
        paginateData: state.rootUser.paginateData,
        paginateMeta: state.rootUser.paginateMeta,
        isFetching: state.rootUser.isFetching,
    }));


    useEffect(() => {
        handleFetchPaginate(1, 50);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     * 查詢分頁
     */
    const handleFetchPaginate = useCallback((currentPage?: number, pageLimit?: number) => {
        // 取得查詢項目
        const {account} = searchForm.getValues();

        dispatch(rootUserActions.fetchPaginate({
            currentPage,
            pageLimit,
            account,
        }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     * 刪除多筆資料
     */
    const handleDeleteRows = () => {
        // 取得 Table中的 CheckId
        // const {checkedId} = tableForm.getValues({nest: true});
        const {checkedId} = tableForm.getValues();
        const ids = objFilterNotTrue2Array(checkedId);

        if(ids.length === 0){
            alert('Please Select');
        }
        dispatch(rootUserActions.deleteData({ids}));
    };


    /**
     * 刪除一筆資料
     */
    const handleDeleteRow = (id: number) => {
        dispatch(rootUserActions.deleteData({ids: [id]}));
    };


    /**
     * 送出8查詢表單
     */
    const handleSubmitHandler: SubmitHandler<IFormData> = useCallback(formData => {
        handleFetchPaginate(1);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageRoot>

            <PageHeader
                breadcrumbData={[
                    {text: 'Root'},
                    {text: 'Root Manage'},
                ]}
                tabsData={[
                    {text: 'List', href: '/root/user'},
                ]}
            />

            <ListFeatureGroup data={[
                {text: 'Add', onClick: () => history.push(`${baseRoutePath}/create/account`), color: 'primary'},
                {text: 'Delete', onClick: handleDeleteRows, color: 'danger'},
            ]}/>

            {/* 內容 */}
            <Container fluid>

                {/* 搜尋列 */}
                <Card>
                    <form onSubmit={searchForm.handleSubmit(handleSubmitHandler)} className="w-100">
                        <Row>
                            <Col col>
                                <Controller
                                    control={searchForm.control}
                                    name="account"
                                    defaultValue=""
                                    render={({onChange, value}) => {
                                        return (<TextField
                                            onChange={onChange}
                                            value={value}
                                            placeholder="Search Account"
                                        />);
                                    }}
                                />
                            </Col>

                            <Col col>
                                <Button type="submit" color="primary">Search</Button>
                            </Col>
                        </Row>
                    </form>

                </Card>


                {/* 表格資料 */}
                <Table
                    isFetching={isFetching}
                    hookForm={tableForm}
                    title={[
                        {text: '#',        field: 'avatar'         , width: 50},
                        {text: 'Name',     field: 'name'       , width: 250},
                        {text: 'Account',  field: 'account'    , col: true},
                        {text: 'Role',  field: 'role'    , col: true},
                        {text: 'Store',  field: 'store'    , col: true},
                        // {text: 'Level',    field: 'levelName' ,dataAlign: 'center'  , width: 90},
                        {text: 'Create',   field: 'createdAt',width: 110},
                        {text: 'Status',      field: 'isEnable', dataAlign: 'center',width: 80},
                    ]}
                    data={paginateData.map(row => {
                        return {
                            id: row.id,
                            account: row.account,
                            avatar: <Avatar image={row.avatarUrl}/>,
                            name: <>
                                <div>{row.name}</div>
                                <div>{row.email}</div>
                            </>,
                            role: row.role?.name,
                            store: row.store?.name,
                            isEnable: <Label status={row.isEnable ? 'success':'gray'}>{row.isEnable ? 'Enable':'Disable'}</Label>,
                            createdAt: <div style={{fontSize: 12}}>
                                {dayjs(row.createdAt).format('YYYY-MM-DD')}<br/>
                                {dayjs(row.createdAt).format('HH:mm:ss')}
                            </div>
                        };
                    })}
                    onEditRow={id => history.push(`/root/user/${id}`)}
                    onDeleteRow={id => handleDeleteRow(id)}
                    onChangePage={(page, limit) => handleFetchPaginate(page, limit)}
                    meta={paginateMeta}
                />
            </Container>



            {/*<Col col className="d-flex justify-content-end">*/}
            {/*    <ActionButton type="button" onClick={event => {*/}
            {/*    }}>*/}
            {/*        <Icon code="file-plus"/>*/}
            {/*    </ActionButton>*/}
            {/*    <ActionButton type="button" onClick={handleDeleteRows}>*/}
            {/*        <Icon code="trash"/>*/}
            {/*    </ActionButton>*/}
            {/*</Col>*/}

        </PageRoot>
    );
};

export default List;


const PageRoot = styled.div`
    padding: 0 20px;
`;

import {useEffect, useMemo} from 'react';
import {Row, Col} from 'library/styled-bs-grid';
import {Controller, SubmitHandler, UseFormMethods} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

// Components
import SearchForm from 'components/forms/SearchForm';
import Card from 'components/atoms/Card';
import Button from 'components/atoms/Button';
import Select2 from 'components/forms/Select2';
import TextField from 'components/forms/TextField';
import Select from 'components/forms/Select';

// Stores
import {actions as memberUserActions} from 'store/memberUser';
import {IFormData} from './types';


interface IProps {
    hookForm: UseFormMethods<IFormData>,
    handleSubmitHandler: SubmitHandler<IFormData>;
}

const SearchCard = ({
    hookForm,
    handleSubmitHandler,
}: IProps) => {
    const dispatch = useDispatch();


    const {optionsData} = useSelector(state => ({
        optionsData: state.memberUser.settingData,
    }));



    const handleReset = () => {
        hookForm.reset({
            memberUserId: '',
        });
    };


    /**
     * MEMO 會員
     */
    const memoMembers = useMemo(() => {
        const data = optionsData?.members?.map(row => {
            return {text: `#${row.id} ${row.name}`, value: String(row.id)};
        }) || [];

        return [{text: 'Customer', value: ''}, ...data];

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionsData?.members]);





    return (<Card>
        <SearchForm onSubmit={hookForm.handleSubmit(handleSubmitHandler)}>
            <Row className="align-items-center">

                <Col col="auto" className="mb-2">
                    <Controller
                        control={hookForm.control}
                        name="memberUserId"
                        render={({onChange, value}) => {
                            return (<Select2
                                onChange={onChange}
                                value={value}
                                placeholder="Customer"
                                options={memoMembers}/>);
                        }}
                    />
                </Col>




                <Col col="auto" className="mb-2">
                    <Button type="submit" color="primary">Search</Button>
                </Col>

                <Col col="auto" className="mb-2">
                    <Button color="gray" onClick={handleReset}>Reset</Button>
                </Col>
            </Row>



        </SearchForm>

    </Card>);
};

export default SearchCard;


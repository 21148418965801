import api from 'library/apisauce';

import {TGetSiteInfoRes} from './types';


export default {
    /**
     * 查詢 站務資料
     */
    getSiteInfo: (): Promise<TGetSiteInfoRes> => {
        return api.get('/dashboard/siteInfo', {}, {
            headers: {isAuth: true},
        });
    },

};

import {Switch, Route} from 'react-router-dom';

// Views
import NotFound from 'views/NotFound';
import MemberUserProfile from 'views/HomeRoot/Member/User/Detail/Profile';
import MemberUserBalanceList from 'views/HomeRoot/Member/User/Detail/Balance/List';
import MemberUserBalanceEdit from 'views/HomeRoot/Member/User/Detail/Balance/Edit';


const Router = () => {
    return (
        <Switch>
            <Route path="/member/user/:id" exact component={MemberUserProfile}/>
            <Route path="/member/user/:memberUserId/balance" exact component={MemberUserBalanceList}/>
            <Route path="/member/user/:memberUserId/balance/:id" exact component={MemberUserBalanceEdit}/>

            <Route path="*" component={NotFound}/>
        </Switch>
    );
};

export default Router;

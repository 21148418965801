import {useEffect, useCallback, useMemo} from 'react';
import styled from 'styled-components/macro';
import {useDispatch, useSelector} from 'react-redux';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {motion, AnimatePresence} from 'framer-motion';

// Components
import Card from 'components/atoms/Card';
import Button from 'components/atoms/Button';
import FormHorizontalGroup from 'components/forms/FormHorizontalGroup';
import SwitchControl from 'components/forms/SwitchControl';
import TextField from 'components/forms/TextField';

// Stores
import {actions as jobActions} from 'store/job';

export interface IFormData {
    paidToMember: number,
    isLodged: boolean,
    isCompleted: boolean,
}


/**
 * 商品明細備註 編輯光箱
 */
const RemarkModal = () => {
    const dispatch = useDispatch();

    const {handleSubmit, control, reset} = useForm<IFormData>();


    const {isVisibleRemarkModal, remarkModalData} = useSelector(state => ({
        isVisibleRemarkModal: state.job.isVisibleRemarkModal,
        remarkModalData: state.job.remarkModalData,
    }));



    useEffect(() => {
        reset({
            paidToMember: remarkModalData?.paidToMember ?? 0,
            isLodged: remarkModalData?.isLodged ?? false,
            isCompleted: remarkModalData?.isCompleted ?? false,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remarkModalData]);

    const handleCloseModal = useCallback(() => {
        dispatch(jobActions.setVisibleRemarkModal({isVisible: false}));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);





    /**
     * 送出表單
     * @param data
     */
    const handleSubmitHandler: SubmitHandler<IFormData> = useCallback(formData => {

        if(remarkModalData){

            dispatch(jobActions.updateRemarkData({
                id: remarkModalData.id,
                data: formData,
            }));
        }
        handleCloseModal();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remarkModalData]);


    return (
        <AnimatePresence>
            {isVisibleRemarkModal && (
                <ModalRoot>
                    <ModalBody
                        variants={{
                            initial: {transform: 'scale(0)', opacity: 0.5},
                            isOpen: {transform: 'scale(1)', opacity: 1},
                            exit: {transform: 'scale(0.2)', opacity: 0},
                        }}
                        transition={{type: 'spring', duration: 0.3}}
                        initial={'initial'}
                        animate={'isOpen'}
                        exit={'exit'}
                    >
                        <Card direction="column">
                            <form onSubmit={handleSubmit(handleSubmitHandler)}>



                                <FormHorizontalGroup label="Paid To Customer" labelCol={8} formCol={16}>
                                    <Controller
                                        control={control}
                                        name="paidToMember"
                                        defaultValue=""
                                        render={({onChange, value}) => {
                                            return (<TextField
                                                onChange={onChange}
                                                value={value}
                                            />);
                                        }}
                                    />
                                </FormHorizontalGroup>


                                <FormHorizontalGroup label="Lodged" labelCol={8} formCol={16}>
                                    <Controller
                                        control={control}
                                        name="isLodged"
                                        render={({onChange, value}) => {
                                            return <SwitchControl checked={value} onChange={onChange}/>;
                                        }}
                                    />
                                </FormHorizontalGroup>
                                <FormHorizontalGroup label="Completed" labelCol={8} formCol={16}>
                                    <Controller
                                        control={control}
                                        name="isCompleted"
                                        render={({onChange, value}) => {
                                            return <SwitchControl checked={value} onChange={onChange}/>;
                                        }}
                                    />
                                </FormHorizontalGroup>




                                <FormHorizontalGroup label="">
                                    <Button type="submit" color="primary" className="mr-2">Save</Button>
                                    <Button color="gray" className="mr-2" onClick={handleCloseModal}>Cancel</Button>
                                </FormHorizontalGroup>

                            </form>
                        </Card>
                    </ModalBody>

                    <ModalBg
                        variants={{
                            initial: {opacity: 0.5},
                            isOpen: {opacity: 1},
                            exit: {opacity: 0},
                        }}
                        transition={{duration: 0.3}}
                        initial={'initial'}
                        animate={'isOpen'}
                        exit={'exit'}
                    />
                </ModalRoot>
            )}
        </AnimatePresence>
    );
};

export default RemarkModal;

const ModalBody = styled(motion.div)`
    width: 480px;
    z-index: 1;
    position: relative;
`;

const ModalBg = styled(motion.div)`
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0, .6);
    //backdrop-filter: blur(2px);
`;

const ModalRoot = styled.div`
    position: fixed;
    z-index: 900;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;


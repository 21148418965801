import {Route, Redirect, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';

// Stores
import {selector as authSelector} from 'store/auth';

import {FCChildrenProps} from 'types';


interface IProps extends FCChildrenProps{
    exact: boolean,
    path: string;
}

/**
 *
 * @param Component
 * @param path 路由路徑
 * @param exact 路由路徑是否需完整比對
 *              ex: sign => sign/login (false)
 *                  sign => sign       (true)
 */
const SignRoute = ({
    children,
    path,
    exact = false,
}: IProps) => {
    const location = useLocation();

    const isAuth = useSelector(authSelector.isAuth);

    return (
        <Route
            path={path}
            exact={exact}
            children={isAuth ? (<Redirect to={{pathname: '/', state: {from: location}}}/>): children}
        />);
};

export default SignRoute;

import {all, call, delay, put, select, takeLatest} from 'redux-saga/effects';
import {IPickAction} from 'library/redux';
import {fetchDataDelayMinMs} from 'config/app';

// Reducers & Types
import {actions} from './reducer';
import {IActionCreators} from './types';
import {actions as uiBlockActions} from 'store/uiBlock';
import {actions as uiDialogActions} from 'store/uiDialog';

// Services
import ApiService, {TGetDetailRes, TGetPaginateRes, TGetSettingsRes} from 'services/rootRole';




/**
 * 取列表資料
 */
function* fetchPaginate(action: IPickAction<IActionCreators, 'fetchPaginate'>) {
    yield put(actions.fetchPaginateBegin());

    const {payload} = action;

    const currentMeta = yield select(state => state.rootRole.paginateMeta);
    const currentPage = payload?.currentPage ?? currentMeta.currentPage;
    const pageLimit = payload?.pageLimit ?? currentMeta.pageLimit;

    try {
        const [{body}]: [TGetPaginateRes] = yield all([
            call(ApiService.getPaginate, currentPage, pageLimit, payload?.keyword),
            delay(fetchDataDelayMinMs),
        ]);
        const {data: {rows, meta}} = body;

        yield put(actions.fetchPaginateSuccess({rows, meta}));

    } catch (err) {
        yield put(actions.fetchPaginateFail({message: err.message}));
        yield put(uiDialogActions.openError({message: err.message, code: err.code}));

    }
}


/**
 * 取設定資料
 */
function* fetchSetting(action: IPickAction<IActionCreators, 'fetchSetting'>) {
    yield put(actions.fetchSettingBegin());

    try {
        const [{body}]: [TGetSettingsRes] = yield all([
            call(ApiService.getSettings),
            delay(fetchDataDelayMinMs),
        ]);

        yield put(actions.fetchSettingSuccess({data: body.data}));

    } catch (err) {
        yield put(actions.fetchSettingFail({message: err.message}));
        yield put(uiDialogActions.openError({message: err.message, code: err.code}));

    }
}



/**
 * 取明細資料
 */
function* fetchCurrent(action: IPickAction<IActionCreators, 'fetchCurrent'>) {
    yield put(actions.fetchCurrentBegin());

    const {id} = action.payload;

    try {
        const [{body}]: [TGetDetailRes] = yield all([
            call(ApiService.getDetail, id),
            delay(fetchDataDelayMinMs),
        ]);

        yield put(actions.fetchCurrentSuccess({data: body.data}));

    } catch (err) {
        yield put(actions.fetchCurrentFail({message: err.message}));
        yield put(uiDialogActions.openError({message: err.message, code: err.code}));

    }
}


/**
 * 新增資料
 */
function* createData(action: IPickAction<IActionCreators, 'createData'>) {
    yield put(actions.createDataBegin());
    yield put(uiBlockActions.visible());

    const {data} = action.payload;

    try {
        yield all([
            call(ApiService.createData, data),
            delay(fetchDataDelayMinMs),
        ]);

        // yield put(replace('/root/role'));

        yield put(actions.createDataSuccess());

    } catch (err) {
        yield put(actions.createDataFail({message: err.message}));
        yield put(uiDialogActions.openError({message: err.message, code: err.code}));

    } finally {
        yield put(uiBlockActions.hidden());
    }
}


/**
 * 更新資料
 */
function* updateData(action: IPickAction<IActionCreators, 'updateData'>) {
    yield put(actions.updateDataBegin());
    yield put(uiBlockActions.visible());

    const {id, data} = action.payload;

    try {
        yield all([
            call(ApiService.updateData, id, data),
            delay(fetchDataDelayMinMs),
        ]);

        yield put(actions.updateDataSuccess());

    } catch (err) {
        yield put(actions.updateDataFail({message: err.message}));
        yield put(uiDialogActions.openError({message: err.message, code: err.code}));

    } finally {
        yield put(uiBlockActions.hidden());
    }
}






/**
 * 刪除資料
 */
function* deleteData(action: IPickAction<IActionCreators, 'deleteData'>) {
    yield put(actions.deleteDataBegin());
    yield put(uiBlockActions.visible());

    const {ids} = action.payload;

    try {
        yield all([
            call(ApiService.deleteData, ids),
            delay(fetchDataDelayMinMs),
        ]);

        // 再次取得資料
        yield put(actions.fetchPaginate());

        yield put(actions.deleteDataSuccess());

    } catch (err) {
        yield put(actions.deleteDataFail({message: err.message}));
        yield put(uiDialogActions.openError({message: err.message, code: err.code}));

    } finally {
        yield put(uiBlockActions.hidden());
    }
}





export default [
    takeLatest(actions.fetchPaginate.type, fetchPaginate),
    takeLatest(actions.fetchSetting.type, fetchSetting),
    takeLatest(actions.fetchCurrent.type, fetchCurrent),
    takeLatest(actions.createData.type, createData),
    takeLatest(actions.updateData.type, updateData),
    takeLatest(actions.deleteData.type, deleteData),

];

/* eslint-disable no-template-curly-in-string */

export default {
    /** -----------------------------------------
     系統錯誤訊息
     /** ---------------------------------------*/
    'errorHttp.400': '請求的參數錯誤',
    'errorHttp.401': '請先登錄,然後繼續',
    'errorHttp.404': '找不到請求/路由',
    'errorHttp.413': '發送的請求/文件超出服務器限制大小',
    'errorHttp.500': '內部服務器錯誤',
    'errorHttp.503': '系統維護中',
    'errorHttp.504': '請檢查您的網絡,然後重試',
    'errorHttp.511': '地區無法提供服務',
    'errorHttp.CLIENT_ERROR': '任何非特定的400系列錯誤',
    'errorHttp.SERVER_ERROR': '任何500系列錯誤',
    'errorHttp.TIMEOUT_ERROR': '服務器的響應時間超過{sec}秒。請確認您的網絡連接狀態或聯繫客戶服務',
    'errorHttp.CONNECTION_ERROR': '服務器不可用,錯誤的dns',
    'errorHttp.NETWORK_ERROR': '您的移動網絡連接不穩定。請檢查您的網絡連接狀態,然後重試。 ',
    'errorHttp.CANCEL_ERROR': '請求已被取消。只有在配置中提供`cancelToken`時才有可能,請參見axios`Cancellation`',




    /** -----------------------------------------
     表單錯誤訊息
     /** ---------------------------------------*/
    'errorForm.require': '{name}不在必填項之間',
    'errorForm.tooShortString': '不小於{length}',
    'errorForm.tooLongString': '不大於{length}',
    'errorForm.tooShortNumber': '不小於{length}',
    'errorForm.tooLongNumber': '不大於{length}',
    'errorForm.tooRange': '{name}必須在{min}-{max}之間',
    'errorForm.invalid': '請匹配{name}的正確格式',




    /** -----------------------------------------
     元件
     /** ---------------------------------------*/
    // Calendar
    'com.calendar.month.1': '1月',
    'com.calendar.month.2': '2月',
    'com.calendar.month.3': '3月',
    'com.calendar.month.4': '4月',
    'com.calendar.month.5': '5月',
    'com.calendar.month.6': '6月',
    'com.calendar.month.7': '7月',
    'com.calendar.month.8': '8月',
    'com.calendar.month.9': '9月',
    'com.calendar.month.10': '10月',
    'com.calendar.month.11': '11月',
    'com.calendar.month.12': '12月',
    'com.calendar.weekDay.1': '一',
    'com.calendar.weekDay.2': '二',
    'com.calendar.weekDay.3': '三',
    'com.calendar.weekDay.4': '四',
    'com.calendar.weekDay.5': '五',
    'com.calendar.weekDay.6': '六',
    'com.calendar.weekDay.7': '日',
    'com.calendar.unit.year': '年',
    'com.calendar.pleaseInputYear': '請輸入西元年',
    'com.calendar.setToday': '設定為今天',


    // uiBlock
    'com.uiBlock.loading': '正在加載...',

    // uiDialog
    'com.uiDialog.success': '成功',
    'com.uiDialog.failed': '失敗',
    'com.uiDialog.confirm': '確認', 
    'com.uiDialog.ok': '確定',
    'com.uiDialog.cancel': '取消',

    // TimePicker
    'com.timePicker.now': '此刻',
    'com.timePicker.ok': '確認',
};

import api from 'library/apisauce';

import {TGetPaginateRes, TGetDetailRes, TUpdateDataRes} from './types';


export default {
    /**
     * 查詢 列表資料
     */
    getPaginate: (currentPage: number, pageLimit: number): Promise<TGetPaginateRes> => {
        return api.get('/setting/site', {
            currentPage,
            pageLimit,
        }, {
            headers: {isAuth: true},
        });
    },

    /**
     * 查詢 明細資料
     */
    getDetail: (id: number): Promise<TGetDetailRes> => {
        return api.get(`/setting/site/${id}`, {}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 更新 資料
     */
    updateData: (id: number, data: {
        value: string,
    }): Promise<TUpdateDataRes> => {

        return api.put(`/setting/site/${id}`, data, {
            headers: {isAuth: true},
        });
    },

};

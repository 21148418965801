import {createSlice} from '@reduxjs/toolkit';
import {IState, ISelector, IActionCreators} from './types';
import {i18n} from 'library/intl';
import {TReducers} from 'library/redux';


/** -----------------------------------------
                Store Key
 /** ---------------------------------------*/
export const name = 'uiDialog';


/** -----------------------------------------
             Initial State
 /** ---------------------------------------*/
export const initialState: IState = {
    isVisible: false,
    type: undefined,
    title: '',
    message: '',
    code: '',
    buttons: [],
};


/** -----------------------------------------
            Selectors
 /** --------------------------------------*/
export const Selector: ISelector = {};



/** -----------------------------------------
                Reducers
 /** ---------------------------------------*/
const reducers: TReducers<IActionCreators, IState> = {
    openSuccess: (state, {payload}) => {
        return {
            ...state,
            isVisible: true,
            type: 'success',
            title: i18n({id: 'com.uiDialog.success'}),
            code: '',
            message: payload.message,
            buttons: [{
                text: i18n({id: 'com.uiDialog.ok'}), type: 'primary', onClick: payload?.onClick
            }]
        };
    },
    openError: (state, {payload}) => {
        return {
            ...state,
            isVisible: true,
            type: 'error',
            title: i18n({id: 'com.uiDialog.failed'}),
            message: payload.message,
            code: payload?.code,
            buttons: [{
                text: i18n({id: 'com.uiDialog.ok'}), type: 'danger', onClick: payload?.onClick
            }]
        };
    },
    openInfo: (state, {payload}) => {
        return {
            ...state,
            isVisible: true,
            type: 'info',
            title: '訊息',
            message: payload.message,
            code: 'INFO',
            buttons: [{
                text: i18n({id: 'com.uiDialog.ok'}), type: 'primary', onClick: payload?.onClick
            }]
        };
    },
    openConfirm: (state, {payload}) => {
        return {
            ...state,
            isVisible: true,
            type: 'confirm',
            title: i18n({id: 'com.uiDialog.confirm'}),
            message: payload?.message,
            buttons: [
                {text: i18n({id: 'com.uiDialog.cancel'}), type: 'danger'},
                {text: i18n({id: 'com.uiDialog.ok'}), type: 'primary', onClick: payload?.onClick}
            ]
        };
    },
    openCustom: (state, {payload}) => {
        return {
            ...state,
            isVisible: true,
            type: payload.type,
            title: payload?.title,
            message: payload.message,
            code: payload?.code,
            buttons: payload?.button ?? []
        };
    },
    close: (state) => {
        return {
            ...state,
            isVisible: false,
            type: undefined,
            title: '',
            message: '',
            code: '',
            buttons: []
        };
    },
};


export const {reducer, actions} = createSlice({name, initialState, reducers});

import {call, put, takeLatest} from 'redux-saga/effects';
import {IPickAction} from 'library/redux';

// Reducers & Types
import {actions} from './reducer';
import {IActionCreators} from './types';

// Services
import SystemApi, {TGetSetting} from 'services/system';


/**
 * 取得系統設定
 */
function* fetchSetting(action: IPickAction<IActionCreators, 'fetchSetting'>) {
    yield put(actions.fetchSettingBegin());

    try{
        const {body}: TGetSetting = yield call(SystemApi.getSetting);
        const responseData  = body.data;

        const settings = {
            countryCodeList: responseData.countryCodeList,
            iMoneyName: responseData.iMoneyName,
            maintainSetting: {
                isMaintain: responseData.isMaintain,
                maintainEndTime: responseData.maintainEndTime,
                maintainMessage: responseData.maintainMessage,
            },
            outSiteUrl: {
                faq: responseData.faqUrl,
                instruction: responseData.instructionUrl,
                promotion: responseData.promotionUrl,
            },
            appDownloadUrl: {
                auto: responseData.appDownloadUrl,
                android: responseData.appDownloadAndroidUrl,
                ios: responseData.appDownloadIosUrl,
            },
            visibleColumn: {
                isDisplayDepositDocUpload: responseData.isDisplayDepositDocUpload,
                isHasBlockAdvertisement: responseData.isHasBlockAdvertisement,
                isRequiredDepositDocUpload: responseData.isRequiredDepositDocUpload,
                isShowIPointRecord: responseData.isShowIPointRecord,
                isShowReferral: responseData.isShowReferral,
            },
            profileSetting: {
                isVerifyBankBranch: responseData.needBankBranch,
                isVerifyEmail: responseData.needCertificationEmail,
                isVerifyLine: responseData.needCertificationLine,
                isVerifyPhone: responseData.needCertificationPhone,
                isVerifyQQ: responseData.needCertificationQQ,
                isVerifySkype: responseData.needCertificationQQ,
                isVerifyWeChat: responseData.needCertificationWeChat,
                isVerifyWhatsApp: responseData.needCertificationWhatsApp,
                isVerifyICCard: responseData.needUploadIcCard,
            },
            signUpSetting: {
                isVisibleMobile: responseData.signUpSetting.isDisplayMobile,
                isVerifyMobile:  responseData.signUpSetting.isValidateRealName,
                isVisibleRealName: responseData.signUpSetting.isDisplayRealName,
                isVerifyRealName: responseData.signUpSetting.isValidateRealName,
            }
        };

        yield put(actions.fetchSettingSuccess(settings));
    } catch(err){


        yield put(actions.fetchSettingFail({message: err.message}));
    }


}

export default [
    takeLatest(actions.fetchSetting.type, fetchSetting),
];

import {useParams} from 'react-router-dom';
import styled from 'styled-components/macro';
import Route from './Route';

// Components
import PageHeader from 'components/organisms/PageHeader';


/**
 * 管理者管理/明細
 *
 */
const Detail = () => {
    const params = useParams<{id: string}>();
    const currentId = params.id;
    const baseRoutePath = '/root/user';

    const isCreate = currentId === 'create';

    return (
        <PageRoot>
            <PageHeader
                breadcrumbData={[
                    {text: 'Root'},
                    {text: 'Root Manage', href: baseRoutePath},
                ]}
                tabsData={[
                    {text: 'View', href: `${baseRoutePath}/${currentId}`, isVisible: !isCreate},
                    {text: 'Account', href: `${baseRoutePath}/${currentId}/account`},
                    {text: 'Password', href: `${baseRoutePath}/${currentId}/password`, isVisible: !isCreate},
                ]}
            />

            {/* 內容 */}
            <Route/>

        </PageRoot>

    );
};

export default Detail;


const PageRoot = styled.div`
    padding: 0 20px;
`;


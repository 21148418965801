import api from 'library/apisauce';

import {TGetPaginateRes, TGetSettingsRes} from './types';

/**
 * 會員/資料管理
 */
export default {
    /**
     * 查詢 列表資料
     */
    getPaginate: (
        type:  'buy'|'sold',
        startDate?: string,
        endDate?: string,
        storeId?: number,
    ): Promise<TGetPaginateRes> => {
        return api.get('/report', {
            type,
            startDate,
            endDate,
            storeId,
        }, {
            headers: {isAuth: true},
        });
    },
    /**
     * 查詢 設定資料
     */
    getSettings: (): Promise<TGetSettingsRes> => {
        return api.get('/report/settings', {}, {
            headers: {isAuth: true},
        });
    },


};

import {combineReducers} from '@reduxjs/toolkit';
import {connectRouter} from 'connected-react-router';
import history from 'library/react-router/history';


import {reducer as startUp, IState as IStartUpState} from './startUp';
import {reducer as language, IState as ILanguage} from'./language';
import {reducer as system, IState as ISystem} from'./system';
import {reducer as auth, IState as IAuth} from'./auth';
import {reducer as uiBlock, IState as IUiBlock} from'./uiBlock';
import {reducer as uiDialog, IState as IUiDialog} from'./uiDialog';
import {reducer as uiImage, IState as IUiImage} from'./uiImage';

import {reducer as dashboard, IState as IDashboard} from'./dashboard';

import {reducer as rootUser, IState as IRootUser} from'./rootUser';
import {reducer as rootRole, IState as IRootRole} from'./rootRole';
import {reducer as rootNotice, IState as IRootNotice} from'./rootNotice';



import {reducer as message, IState as IMessage} from'./message';



import {reducer as memberUser, IState as IMemberUser} from'./memberUser';
import {reducer as memberBalance, IState as IMemberBalance} from'./memberBalance';

import {reducer as store, IState as IStore} from'./store';
import {reducer as job, IState as IJob} from'./job';
import {reducer as report, IState as IReport} from'./report';

import {reducer as settingSite, IState as ISettingSite} from'./settingSite';




export interface IRootState {
    startUp: IStartUpState,
    language: ILanguage,
    system: ISystem,
    auth: IAuth,
    uiBlock: IUiBlock,
    uiDialog: IUiDialog,
    uiImage: IUiImage,


    dashboard: IDashboard,



    rootUser: IRootUser,
    rootRole: IRootRole,
    rootNotice: IRootNotice,




    message: IMessage,



    memberUser: IMemberUser,
    memberBalance: IMemberBalance,

    store: IStore,
    job: IJob,
    report: IReport,


    settingSite: ISettingSite,




}

export default combineReducers({
    router: connectRouter(history),
    startUp,
    language,
    system,
    auth,
    uiBlock,
    uiDialog,
    uiImage,


    dashboard,



    rootUser,
    rootRole,
    rootNotice,




    message,



    memberUser,
    memberBalance,

    store,
    job,
    report,


    settingSite,




});




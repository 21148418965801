import {useCallback, useMemo} from 'react';
import styled from 'styled-components/macro';
import {motion, AnimatePresence} from 'framer-motion';

// Components
import ScrollView from 'components/atoms/ScrollView';
import Dialog from './Dialog';

interface IProps {
    isVisible: boolean;
    type?: 'success'|'error'|'info'|'confirm';
    title: string;
    message: string;
    code?: string;
    buttons: Array<{
        text: string,
        type: 'primary'|'danger',
        onClick?: () => void;
    }>;
    onClose: () => void;
}

const DialogModal = ({
    isVisible,
    type = 'success',
    title,
    message,
    code,
    buttons,
    onClose,
}: IProps) => {
    /**
     * 處理關閉光箱
     */
    const handleClickDialog = useCallback(() => {
        onClose();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onClose]);

    const renderDialog = useMemo(() => {
        return <Dialog
            type={type}
            title={title}
            message={message}
            code={code}
            buttons={buttons.map(row => {
                return {
                    ...row, ...{
                        onClick: () => {
                            if(row.onClick){
                                row.onClick();
                            }
                            handleClickDialog();
                        }
                    }
                };
            })}
        />;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, title, message, code, buttons]);

    return (
        <AnimatePresence>
            {isVisible && (
                <GlobalModalRoot
                    variants={{
                        initial: {opacity: 0, transition: {type:'spring'}},
                        isOpen: {opacity: 1},
                        exit: {opacity: 0}
                    }}
                    initial={'initial'}
                    animate={'isOpen'}
                    exit={'exit'}
                >
                    <ScrollView className="d-flex align-items-center justify-content-center">
                        <Transform
                            variants={{
                                initial: {transform: 'scale(0)'},
                                isOpen: {transform: 'scale(1)'},
                                exit: {transform: 'scale(0)'},
                            }}
                            transition={{type: 'spring', duration: 0.5}}
                        >
                            {renderDialog}
                        </Transform>
                    </ScrollView>
                </GlobalModalRoot>
            )}
        </AnimatePresence>
    );
};

export default DialogModal;

const Transform = styled(motion.div)<any>`

`;

const GlobalModalRoot = styled(motion.div)<any>`
    z-index: 998;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
`;
